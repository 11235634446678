import * as actionsTypes from "./actionTypes";

export const updateOav = (name, data) => {
  return {
    type: actionsTypes.UPDATE_OAV_DATA,
    payload: {
      name,
      data,
    },
  };
};

export const cleanOavData = () => {
  return {
    type: actionsTypes.CLEAN_OAV_DATA,
  };
};
