import text from "./text";
import SSN from "french-ssn";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

// ----------------- Hook Form Validation ---------------

export const formatErrorLetters = (value) => {
  return (
    /^[A-Za-ëíìîïóñòôøõöúùûüßÿçÇÀÁÂÃÄÅÉÈÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÆÑØ][a-zA-Z'áàâåãäæéèêëíìîïóñòôøõöúùûüßÿçÇÀÁÂÃÄÅÉÈÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÆÑØ ]*$/.test(
      value
    ) || text.General.formatError
  );
};

export const formatErrorPhoneNumber = (value) => {
  if (value) {
    return (
      /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(value) ||
      text.General.formatError
    );
  } else {
    return null;
  }
};

export const formatErrorSSN = (value) => {
  //console.log(SSN.validate(value));
  return SSN.validate(value) || "Numéro non valide";
};

export const formatErrorSiret = (value) => {
  return /^\d{14}$/.test(value) || text.General.formatError;
};

export const formatErrorZip = (value) => {
  return (
    /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/.test(value) || "Code postal invalide."
  );
};

export const formatErrorBeforeDate = (value) => {
  return new Date(value) < new Date() || "Date erronée.";
};

export const formatErrorAfterDate = (value) => {
  return (
    moment(value).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD") ||
    "Date erronée."
  );
};

export const errorDateJPlus1 = (value) => {
  const currentDate = moment().add(1, 'd');

  return (
      moment(value).format("YYYY-MM-DD") >= currentDate.format("YYYY-MM-DD") ||
      "La date de souscription doit être au minimum à J+1"
  );
};

export const errorEffectif50 = (value) => {

  return (
     value <=50 ||
      "Aucune offre disponible pour cet effectif. Merci de contacter votre support MBA Mutuelle "
  );
};



export const formatErrorEmail = (value) => {
  if (!value) {
    return true;
  }

  return (
    /^[a-zA-Z0-9]([a-zA-Z0-9._-]+)@(([a-zA-Z0-9-]+\.)+)([a-zA-Z]{2,4}|[0-9]{1,3})$/.test(
      value
    ) || text.General.formatError
  );
};

export const emailNotSame = (emailConfirmation, value) => {
  if (value.trim().toLowerCase() !== emailConfirmation.trim().toLowerCase()) {
    return text.General.emailNotMatching;
  }

  return null;
};

export const passwordChecker = (PasswordParameter) => {
  const strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );
  const mediumPassword = new RegExp(
    "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  );
  if (strongPassword.test(PasswordParameter)) {
    return { color: "green", text: "Fort" };
  } else if (mediumPassword.test(PasswordParameter)) {
    return { color: "blue", text: "Moyen" };
  } else {
    return { color: "red", text: "Faible" };
  }
};

export const errorPassword = (value) => {
  return value.length === 5 || text.General.formatError;
};

export const errorPasswordNotSame = (value, otherPassword) => {
  if (value && otherPassword && value === otherPassword) {
    return null;
  } else {
    return "Les mots de passe ne sont pas identiques";
  }
};
