import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Typography, Grid, TextField, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/fr";
import fr from "date-fns/locale/fr";
import InputAdornment from "@material-ui/core/InputAdornment";
import { MdClear } from "react-icons/all";
moment.locale("fr");

const useStyles = makeStyles({
  label: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontWeight: 700,
    color: "#2A3254",
  },
  input: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontSize: 14,
  },
  datePicker: {
    "& .MuiToolbar-root": {
      backgroundColor: "#C31D23 !important",
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: "#C31D23 !important",
    },
    "& .MuiButton-label": {
      color: "#C31D23 !important",
    },
  },
});

const CommonDateTimePicker = ({
  field,
  name,
  error,
  label,
  labelCustom,
  required,
  disabled,
  disablePast,
  minDate,
  errorText,
  InputProps,
  helperText,
  renderInput,
  readOnly,
  maxDate,
}) => {
  const classes = useStyles();

  function handleClear(e) {
    e.stopPropagation();
    field.onChange(null);
  }

  return (
    <>
      <Grid container style={{ maxWidth: "300px" }}>
        {labelCustom && (
          <Grid item xs={12} align="left">
            <Typography component="span" className={classes.label}>
              {labelCustom}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
            <KeyboardDatePicker
              margin="normal"
              name={name}
              DialogProps={{ className: classes.datePicker }}
              id="date-picker-dialog"
              label={label}
              inputVariant="outlined"
              format="dd-MM-yyyy"
              value={field.value}
              disablePast={disablePast}
              minDate={minDate}
              maxDate={maxDate}
              //renderInput={renderInput}

              InputProps={readOnly && { readOnly: readOnly }}
              onChange={(date) =>
                field.onChange(moment(date).format("YYYY-MM-DD"))
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              error={!!error}
              helperText={helperText}
              invalidDateMessage={
                <Typography component="span" style={{ color: "red" }}>
                  {"Date erronée"}
                </Typography>
              }
              style={{ margin: 0, width: "100%", marginTop: 10 }}
              required={required}
              disabled={disabled}

              // FUNCTION TO CLEAR DATE
              /*
              InputProps={field.value && {
                startAdornment:
                    <InputAdornment sizeSmall onClick={e => handleClear(e)}>
                        <MdClear size={18} fill={"#757575"} />
                    </InputAdornment>
            
              }}
            */
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      <Typography component="span" style={{ color: "red" }}>
        {error?.message}
        {error && errorText}
      </Typography>
    </>
  );
};

export default CommonDateTimePicker;
