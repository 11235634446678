import { useState, useEffect, useCallback } from "react"
import { Grid } from "@mui/material"
import Note from "../../../Components/Dashboard/Note"
import { getAllNotes, upsertNote } from "../../../Axios/Call/ClientCredential"

const COURTIER_SLUG = "note-aux-courtiers"
const INFO_SLUG = "informations-utiles"
const INTERN_NOTES = "note-interne"

const notesList = [COURTIER_SLUG, INFO_SLUG, INTERN_NOTES]

const notesTitles = {
  [COURTIER_SLUG]: "Note aux courtiers",
  [INFO_SLUG]: "Informations utiles",
  [INTERN_NOTES]: "Note interne",
}

const Actu = () => {
  const [notes, setNotes] = useState({})

  const fetchNotes = useCallback(async () => {
    const { data } = await getAllNotes()
    setNotes(data)
  }, [])

  useEffect(() => {
    fetchNotes()
  }, [fetchNotes])

  const getOnSaveNote = useCallback(
    (slug) => async (data) => {
      await upsertNote(slug, data)
      fetchNotes()
    },
    [fetchNotes]
  )

  return (
    <Grid container>
      {notesList.map(note => (
                
                <Grid item md={6} xs={12} xl={6} key={note}>
                {note!==INTERN_NOTES && <div dangerouslySetInnerHTML={{ __html: notes[note]?.text }} />}
              </Grid>
      ))}


    </Grid>
  )
}

export default Actu
