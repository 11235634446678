import React, { Component } from 'react';
import {
    Box,
    Grid,
    Checkbox,
    FormControlLabel,
    Button,
    Typography,
  } from "@material-ui/core";

class Links extends Component {


    render() {
        return (
          <div style={{ position: "fixed", bottom: 20, width:"99%", left:0}}>
            <Grid container spacing={2} >
            <Grid  xs={12} md={1}>
                <Typography style={{ color: "#AEB4BE" }}>
                © {(new Date().getFullYear())}  <a className={"linkBottomGrey"} href="https://www.mbamutuelle.com/" target="_blank">MBA Mutuelle</a>
                </Typography>
              </Grid>
              <Grid  xs={12} md={11} style={{textAlign:"right", right:0}}>
                <Typography style={{ color: "white",textAlign:"right"}}>
                  <a className={"linkBottom"} href="https://www.mbamutuelle.com/mentions-legales.php" target="_blank"> Mentions légales | CGU   </a>
                  <a className={"linkBottom"} href="https://www.hostinger.fr/informations-registraire" target="_blank"> Mentions légales hébergeur    </a>
                  <a className={"linkBottom"} href="https://www.mbamutuelle.com/reglement-general-de-protection-des-donnees.php" target="_blank"> Données personnelles </a>
                  <a className={"linkBottom"} href="https://www.vigee.fr" target="_blank"> Developed by <strong style={{color:'white'}}>Vigee</strong>  </a>
                </Typography>
              </Grid>
              </Grid>
              </div>
        );
    }
}

export default Links;




