import React, { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { FaRegTrashAlt } from "react-icons/fa"
import { BsPencilSquare, BsSearch } from "react-icons/bs"
import { useHistory } from "react-router-dom"
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import CustomModal from "../../Components/Commons/Modal/CustomModal"

import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular"
import "moment/locale/fr"
import UserComponentLayout from "../../Hoc/UserComponentLayout"
import ContactBox from "../../Components/Commons/ContactBox/ContactBox"
import { authApiAxios as axios } from "../../Axios/AxiosInstance"

//ICONS
import ContactPhoneIcon from "@mui/icons-material/ContactPhone"
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded"
import SendIcon from "@mui/icons-material/Send"

const Contacts = () => {
  let history = useHistory()

  const [loading, setloading] = useState(false)
  const [search, setsearch] = useState("")
  const [toDelete, settoDelete] = useState(0)
  const [filteredData, setFilteredData] = useState([])

  const [contacts, setContacts] = useState([])

  useEffect(() => {
    setFilteredData(
      contacts?.filter(
        (row) =>
          row?.first_name?.toLowerCase().match(search.toLowerCase().trim()) ||
          row?.last_name?.toLowerCase().match(search.toLowerCase().trim()) ||
          row?.email?.toLowerCase().match(search.toLocaleLowerCase().trim()) ||
          row?.role?.toLowerCase().match(search.toLocaleLowerCase().trim())
      )
    )
  }, [contacts, search])

  useEffect(() => {
    fetchContacts()
  }, [])

  const fetchContacts = async () => {
    setloading(true)
    try {
      const res = await axios.get("contacts")
      setContacts(res.data)
    } catch (err) {
      console.log(err)
    } finally {
      setloading(false)
    }
  }

  // DELETE PROCESS
  const handleToDelete = (id) => {
    settoDelete(id)
  }

  const handleDelete = async () => {
    setloading(true)

    try {
      await axios.delete(`contacts/${toDelete}`)
      await fetchContacts()
    } catch (err) {
      console.log(err)
    } finally {
      settoDelete(0)
      setloading(false)
    }
  }

  return (
    <>
      <CustomHeader
        icon={
          <ContactPhoneIcon style={{ fill: "rgb(195, 29, 35)", marginRight: ".5rem", width: 50, height: "30px" }} />
        }
        pageName={`Contacts`}
      />

      <Grid container spacing={1} style={{ margin: "1.5rem 0 1rem" }}>
        <Grid item xs={12} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Rechercher un contact"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        
      </Grid>

      {/* tableau */}
      {loading === true ? (
        <Box className="spacer2">
          <LoaderCircular />
        </Box>
      ) : (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography component="p" variant="h4">
                      Nom
                    </Typography>
                  </TableCell>

                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography variant="h4">Rôle</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography variant="h4">Téléphone</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography variant="h4">Email</Typography>
                  </TableCell>
            
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  ?.sort((a, b) => (a.code <= b.code ? -1 : 1))
                  .map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">
                        <Typography variant="h6">
                          {row.first_name} {row.last_name.toUpperCase()}
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography variant="body2">{row.role}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <ContactBox
                          text={row.phone}
                          icon={<PhoneForwardedIcon style={{ fill: "rgb(195, 29, 35)" }} />}
                          tooltip={"Appeler"}
                          href={`tel:${row.phone}`}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <ContactBox
                          text={row.email}
                          icon={<SendIcon style={{ fill: "rgb(195, 29, 35)" }} />}
                          tooltip={"Envoyer un mail"}
                          href={`mailto:${row.email}`}
                        />
                      </TableCell>

                      
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          
        </>
      )}
    </>
  )
}

export default Contacts
