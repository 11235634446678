import React, { useEffect, useState } from "react";
import {
  getAllGuaranties,
  getAllPackages,
  getAllRates,
  getPackageDocument,
  getZones,
} from "../../../Axios/Call/ClientCredential";
import OAVLayout from "../OAVComponents/OAVLayout";
import OAVSynthese from "../OAVComponents/OAVSynthese";
import {
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import LoaderCircular from "../../Commons/Loading/LoaderCircular";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import WarrantyCard from "../../FormComponents/WarrantyCard";
import _, { set } from "lodash";
import CommonSelect from "../../../Containers/Components/FormComponents/CommonSelect";
import { useHistory } from "react-router-dom";
import CustomModal from "../../Commons/Modal/CustomModal";
import PDFViewer from "../../Commons/FileHandler/PDFViewer";
import { updateOav } from "../../../Store/action";
import moment from "moment";
import { getQuote } from "../../../Axios/Call/ClientCredential";
import { SquaresPlusIcon } from "@heroicons/react/24/solid";

moment.locale("fr");

const OAVWarranties = () => {
  const [errorAPI, setErrorAPI] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isQuote, setIsQuote] = useState(false);
  const oavData = useSelector((state) => state.oav.data);
  const [limitYear, setLimitYear] = useState(false);

  const [knowComp, setKnowComp] = useState("");

  useEffect(() => {
    if (oavData.hasOwnProperty("reprise")) {
      getQuote(oavData.reprise.quote).then((res) => {
        if (res.data.type === "quote") {
          if (
            res.data.product.produit_independant == "1" ||
            res.data.product.produit_independant == true
          ) {
            setKnowComp(res.data.product.code);
          }
          console.log("je passe la", res.data);
          setIsQuote(true);
        } else {
          const startingDate = moment(res.data.starting_date).format(
            "YYYY-MM-DD"
          );
          const oneYearAgo = moment()
            .subtract(12, "months")
            .format("YYYY-MM-DD");

          if (moment(startingDate).isBefore(oneYearAgo)) {
            setLimitYear(true);
          }
        }
      });
    }
  }, [oavData]);

  const isCompany = useSelector(
    (state) => state.oav.data.contrat === "collectif"
  );
  const isMadelin = useSelector(
    (state) => state.oav.data.madelin === "madelin"
  );
  const structures = useSelector((state) => state.user.structures_codes);
  const fullStructures = useSelector((state) => state.user.structures);
  const commissions = useSelector((state) =>
    isCompany
      ? state.user.commissions_company.map((commission) => commission.id)
      : state.user.commissions_indiv.map((commission) => commission.id)
  );
  const priceTypes = useSelector((state) => state.user.priceTypes);
  const notAccessibleProducts = useSelector((state) =>
    state.user.userData.not_accessible_product?.split(",").map(Number)
  );

  const subscriber = useSelector((state) => {
    return {
      label: "Adhérent",
      regime: parseInt(state.oav.data.regime),
      age:
        moment(state.oav.data.starting_date).year() -
        moment(state.oav.data.birth_date).year(),
    };
  });
  const spouse = useSelector((state) => {
    if (state.oav.data.joint_birth_date) {
      return {
        label: "Conjoint",
        age:
          moment(state.oav.data.starting_date).year() -
          moment(state.oav.data.joint_birth_date).year(),
        regime: parseInt(state.oav.data.joint_regime),
      };
    } else {
      return null;
    }
  });
  const children = useSelector((state) => {
    if (state.oav.data.enfant) {
      return state.oav.data.enfant
        .map((child, index) => {
          return {
            label: "Enfant " + (index + 1),
            age:
              moment(state.oav.data.starting_date).year() -
              moment(child.birth_date).year(),
            regime: parseInt(child.regime),
          };
        })
        .sort((childA, childB) => childB.age - childA.age);
    } else {
      return null;
    }
  });

  const [warranties, setWarranties] = useState([]);
  const [filteredWarranties, setFilteredWarranties] = useState([]);
  const [packs, setPacks] = useState([]);
  const [file, setFile] = useState();

  const [commissionsList, setCommissionsList] = useState([]);
  const [structuresList, setStructuresList] = useState([]);
  const [selectedStructure, setSelectedStructure] = useState("Adulte/Enfant");
  const [selectedCommission, setSelectedCommision] = useState("7%");
  const [selectedBase, setSelectedBase] = useState(
    oavData.selectedBase ? oavData.selectedBase : null
  );
  const [selectedRenfortsOptions, setSelectedRenfortsOptions] = useState([]);
  const [selectedPacks, setSelectedPacks] = useState();
  const [selectedRates, setSelectedRates] = useState({
    base: {},
    renfort: {},
    option: {},
    pc: {},
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [displayedRenfortsOptions, setDisplayedRenfortsOptions] = useState([]);
  const [displayedBases, setDisplayedBases] = useState([]);
  const [isOldDataLoaded, setIsOldDataLoaded] = useState(false);
  const [zones, setZones] = useState([]);

  const steps = ["Besoin", "Profil", "Choix de la garantie"];

  const compareWarrantyRates = (warrantyA, warrantyB) => {
    let totalPriceA = 0;
    warrantyA.rates.forEach(
      (rate) => (totalPriceA = totalPrice + rate.price_with_tva)
    );
    let totalPriceB = 0;
    warrantyB.rates.forEach(
      (rate) => (totalPriceB = totalPrice + rate.price_with_tva)
    );
    return totalPriceA - totalPriceB;
  };

  const compareRates = (rateA, rateB) =>
    rateA.price_with_tva - rateB.price_with_tva;

  const handleBaseClick = (warranty) => {
    setSelectedBase(warranty.id);
    setSelectedRenfortsOptions([]);
  };

  const handleRenfortOptionClick = (id) => {
    selectedRenfortsOptions.includes(id)
      ? setSelectedRenfortsOptions((prevState) =>
          prevState.filter((item) => item !== id)
        )
      : setSelectedRenfortsOptions((prevState) => [...prevState, id]);
  };

  const handleDocumentClick = (id) => {
    getPackageDocument(id)
      .then((res) => {
        setFile(res.data);
      })
      .catch((error) => console.warn(error));
  };

  const handleSubmitClick = () => {
    dispatch(updateOav("price", JSON.stringify(selectedRates)));
    dispatch(updateOav("product", selectedPacks.product_id));
    dispatch(updateOav("pack", selectedPacks.id));
    dispatch(updateOav("base", selectedBase));
    // dispatch(updateOav(isCompany ? "renfort" : "option", selectedRenfortsOptions));
    dispatch(updateOav("selectedStructure", selectedStructure));
    dispatch(updateOav("selectedCommission", selectedCommission));
    dispatch(updateOav("selectedBase", selectedBase));
    dispatch(updateOav("selectedRenfortsOptions", selectedRenfortsOptions));
    dispatch(updateOav("total_price", totalPrice));

    if (isCompany) {
      const renfort = displayedRenfortsOptions
        ?.filter((warranty) => warranty.nature === "renfort")
        .filter((w) => selectedRenfortsOptions.includes(w.id))
        .map((w) => w.id);

      const options = displayedRenfortsOptions
        ?.filter((warranty) => warranty.nature === "option")
        .filter((w) => selectedRenfortsOptions.includes(w.id))
        .map((w) => w.id);

      const PCs = displayedRenfortsOptions
        ?.filter((warranty) => warranty.nature === "supp")
        .filter((w) => selectedRenfortsOptions.includes(w.id))
        .map((w) => w.id);

      dispatch(updateOav("renfort", renfort));
      dispatch(updateOav("option", options));
      dispatch(updateOav("supp", PCs));
    } else {
      const options = displayedRenfortsOptions
        ?.filter((warranty) => warranty.nature === "option")
        .filter((w) => selectedRenfortsOptions.includes(w.id))
        .map((w) => w.id);

      const PCs = displayedRenfortsOptions
        ?.filter((warranty) => warranty.nature === "supp")
        .filter((w) => selectedRenfortsOptions.includes(w.id))
        .map((w) => w.id);

      dispatch(updateOav("option", options));
      dispatch(updateOav("supp", PCs));
    }
  };

  const findRateByAgeRegime = (rates, age, regime, typerate = 0) => {
    return rates.find((rate) => {
      if (age < 16) {
        return true;
      } else if (typerate == 1) {
        return (
          age >= rate.age_min && age <= rate.age_max && rate.regime == regime
        );
      } else {
        return rate.age == age && rate.regime == regime;
      }
    });
  };

  const isWarrantyValidForIndividual = (warranty) => {
    let isValid = true;

    !findRateByAgeRegime(
      warranty.rates,
      subscriber.age,
      subscriber.regime,
      warranty.typerate
    ) && (isValid = false);

    if (spouse && (warranty?.structure_cotisation !== "U" && warranty?.structure_cotisation !== "U - Unique"))
      !findRateByAgeRegime(
        warranty.rates,
        spouse.age,
        spouse.regime,
        warranty.typerate
      ) && (isValid = false);

    if (children && (warranty?.structure_cotisation !== "U" && warranty?.structure_cotisation !== "U - Unique")) {
      children.forEach((child) => {
        !findRateByAgeRegime(
          warranty.rates,
          child.age,
          child.regime,
          warranty.typerate
        ) && (isValid = false);
      });
    }

    return isValid;
  };

  useEffect(() => {
    getZones().then((res) => setZones(res.data));
  }, []);

  //Initialize all the data used in this form
  useEffect(() => {
    console.log(oavData, "oavData");
    setLoading(true);
    if (
      !!Object.keys(oavData).length &&
      (!oavData.reprise || oavData.reprise.isDataLoaded) &&
      zones.length > 0
    ) {
      getAllGuaranties()
        .then((res) => {
          //Filtering warranties attached to delete products & outaded products
          let warranties = res.data.filter((warranty) =>
            warranty.product !== null &&
            !notAccessibleProducts?.includes(warranty.product.id) &&
            warranty.product?.status !== "inactive" &&
            warranty.product.starting_date <= oavData.starting_date &&
            (warranty.product?.ending_date
              ? warranty.product?.ending_date >= oavData.starting_date
              : true) &&
            oavData?.produits_independants != null &&
            oavData?.produits_independants !== "Aucun"
              ? oavData?.produits_independants === warranty.product?.description
              : true && knowComp !== ""
              ? knowComp == warranty.product?.code
              : true &&
                (oavData?.produits_independants == "Aucun" ||
                  oavData?.produits_independants == null)
              ? warranty.product?.produit_independant == "non" ||
                warranty.product?.produit_independant == 0
              : false
          );

          if (knowComp == "") {
            console.log("je passe donc pas de know comp");
            warranties = res.data.filter((warranty) =>
              warranty.product !== null &&
              !notAccessibleProducts?.includes(warranty.product.id) &&
              warranty.product?.status !== "inactive" &&
              warranty.product.starting_date <= oavData.starting_date &&
              (warranty.product?.ending_date
                ? warranty.product?.ending_date >= oavData.starting_date
                : true) &&
              oavData?.produits_independants != null &&
              oavData?.produits_independants !== "Aucun"
                ? oavData?.produits_independants ===
                  warranty.product?.description
                : true &&
                  (oavData?.produits_independants == "Aucun" ||
                    oavData?.produits_independants == null)
                ? warranty.product?.produit_independant == "non" ||
                  warranty.product?.produit_independant == 0
                : false
            );
          }

          console.log(warranties, "warranties avant etape 1.1");

          //Filtering depending on company or individual market.
          if (isCompany) {
            console.log("je passe la 1.2");
            warranties = warranties.filter(
              (warranty) =>
                warranty.product.market === "company" &&
                (oavData.commercial_id
                  ? warranty.product.commercial_id === oavData.commercial_id
                  : warranty.product.commercial_id === null)
            );
          } else {
            console.log("je passe la 1.1");
            warranties = warranties
              .filter((warranty) => warranty.product.market === "individual")
              .filter((warranty) => !!!warranty.product.madelin === isMadelin) /// In db, madelin is set to 0 if the product is madelin and 1 if not madelin ...
              .filter((warranty) =>
                warranty.product.profession_ids
                  .split(",")
                  .find((id) => id === oavData.profession_id.toString())
              );

            console.log(warranties, "warranties après etape 1.1");
          }

          //Inserting empty rates array to fill it after.
          warranties = warranties.map((warranty) => {
            warranty.rates = [];
            return warranty;
          });

          getAllRates()
            .then((res) => {
              console.log(warranties.length, "Etape 1");
              //Filter zones based on selected zipcode
              const selectedZones = zones
                .filter((zone) => {
                  const zipcode =
                    typeof oavData?.zip_code === "string"
                      ? oavData.zip_code.substring(0, 2)
                      : oavData.zip_code?.place?.zipcode?.substring(0, 2);
                  return zone.caps.split(",").includes(zipcode);
                })
                ?.map((zone) => zone.id);

              let allRates = res.data.filter((rate) =>
                selectedZones.includes(rate.zone_id)
              );
              allRates = allRates.filter(
                (rate) =>
                  rate.starting_date <= oavData.starting_date &&
                  rate.ending_date >= oavData.starting_date
              );

              //Remove all rates that aren't "AE" structure code
              if (!isCompany) {
                allRates = allRates.filter(
                  (rate) =>
                    rate.structure_cotisation?.split(" - ")[0].trim() ===
                      "AE" ||
                    rate.structure_cotisation?.split(" - ")[0].trim() === "U"
                );
              } else {
                allRates = allRates.filter(
                  (rate) =>
                    rate.regime === parseInt(oavData?.regime) &&
                    rate.age_min <= oavData.age_moyen &&
                    rate.age_max >= oavData.age_moyen
                );
              }

              allRates.forEach((rate) => {
                let matchedWarranties = warranties.filter(
                  (warranty) =>
                    warranty.id == rate.guarantee_id ||
                    warranty.linked_guarantee_id == rate.guarantee_id
                );

                matchedWarranties.forEach((warranty) => {
                  let warrantyIndex = warranties.findIndex(
                    (w) => w.id == warranty.id
                  );

                  if (warrantyIndex !== -1) {
                    warranties[warrantyIndex].rates = [
                      ...warranties[warrantyIndex].rates,
                      rate,
                    ];
                  }
                });
              });

              console.log(warranties, "Etape 2");

              warranties = warranties.filter(
                (warranty) => warranty.rates.length > 0
              );

              console.log(warranties, "Etape 10");
              //If we are in individual case, we filter warranties that have all rates corresponding to childrens and spouse if they are selected
              !isCompany &&
                (warranties = warranties.filter((warranty) =>
                  isWarrantyValidForIndividual(warranty)
                ));

              console.log(warranties, "warranties avant");
              if (warranties.length === 0) {
                setIsDataEmpty(true);
                setLoading(false);
              } else {
                setWarranties(warranties);
              }
            })
            .catch((error) => {
              setErrorAPI(true);
              console.warn(error);
            });
        })
        .catch((error) => {
          setErrorAPI(true);
          console.warn(error);
        });

      getAllPackages()
        .then((res) => setPacks(res.data))
        .catch((error) => {
          setErrorAPI(true);
          console.warn(error);
        });

      // Set filters to old data if it's a reprise or the user went back
      if (oavData.selectedStructure && oavData.selectedCommission) {
        setSelectedStructure(oavData.selectedStructure);
      }
    }
  }, [isCompany, oavData, zones, knowComp]);

  useEffect(() => {
    if (warranties.length > 0) {
      // Preparing commission drop list
      const commissionList = _.uniq(
        warranties.flatMap((warranty) =>
          warranty.rates.map((rate) => rate.commission)
        )
      )
        .filter((commission) => commissions.includes(commission))
        .map((commission) => {
          return {
            id: commission,
            value: `${Math.floor(commission * 100)}%`,
          };
        });

      const sortedCommissions = [...commissionList].sort((a, b) => a.id - b.id);


      setSelectedCommision(sortedCommissions[0].value);
      setCommissionsList(sortedCommissions);

      // Preparing structure drop list
      const ratesStructuresList = _.uniq(
        warranties.flatMap((warranty) =>
          warranty.rates.map(
            (rate) => rate.structure_cotisation.split(" - ")[0]
          )
        )
      );
      const structureList = structures.filter((structure) =>
        ratesStructuresList.includes(structure.id)
      );

      setSelectedStructure(structureList[0].value);
      setStructuresList(
        structures.filter((structure) =>
          ratesStructuresList.includes(structure.id)
        )
      );

      // Set filters to old data if it's a reprise or the user went back
      if (oavData.selectedStructure && oavData.selectedCommission) {
        setSelectedCommision(oavData.selectedCommission);
        setSelectedStructure(oavData.selectedStructure);
      }

      setLoading(false);
    }

  }, [warranties]);

  // Set Displayed Renforts & Options
  useEffect(() => {
    if (packs.length > 0 && selectedBase) {
      let selectedPack;

      //Filtering packs based on the selected base and on starting and ending date of packs.
      selectedPack = packs.filter(
        (pack) =>
          pack.package_assignation.find(
            (assignation) => assignation.base_id === selectedBase
          ) &&
          pack.starting_date <= oavData.starting_date &&
          (pack.ending_date ? pack.ending_date >= oavData.starting_date : true)
      );

      if (selectedRenfortsOptions.length > 0) {
        selectedPack = selectedPack
          .filter(
            (pack) =>
              pack.package_assignation.length === selectedRenfortsOptions.length
          )
          .filter((pack) => {
            let isValid = true;
            pack.package_assignation.forEach((assignation) => {
              !selectedRenfortsOptions.includes(
                assignation.renfort_option_id
              ) && (isValid = false);
            });
            return isValid;
          });
      } else {
        selectedPack = selectedPack.filter((pack) =>
          pack.package_assignation.find(
            (assignation) => assignation.renfort_option_id === null
          )
        );
      }

      setSelectedPacks(selectedPack[0]);

      //Prepare renforts / options warranties for display
      const displayedRenfortsOptionsIds = _.uniq(
        packs
          .filter((pack) =>
            pack.package_assignation.find(
              (assignation) => assignation.base_id === selectedBase
            )
          )
          .flatMap((pack) =>
            pack.package_assignation.map(
              (assignation) => assignation.renfort_option_id
            )
          )
      );

      let displayedRenfortsOptions = filteredWarranties
        .filter((warranty) => displayedRenfortsOptionsIds.includes(warranty.id))
        .map((warranty) => {
          let displayedRates = [];
          if (isCompany) {
            displayedRates = warranty.rates
              .sort(compareRates)
              .map(
                (rate) =>
                  `${rate.structure_cotisation}: ${
                    rate.percent_salary > 0
                      ? rate.percent_salary + "% Salaire + "
                      : ""
                  } ${rate.price_with_tva.toFixed(
                    rate.is_price === 2 ? 3 : 2
                  )} ${
                    priceTypes.find((type) => type.id === rate.is_price)?.value
                  }`
              );
          } else {
            const subscriberRate = findRateByAgeRegime(
              warranty.rates,
              subscriber.age,
              subscriber.regime,
              warranty.typerate
            );
            const spouseRate = spouse
              ? findRateByAgeRegime(
                  warranty.rates,
                  spouse.age,
                  spouse.regime,
                  warranty.typerate
                )
              : null;

            displayedRates.push(
              `${subscriber.label}: ${
                subscriberRate.percent_salary > 0
                  ? subscriberRate.percent_salary + "% Salaire + "
                  : ""
              } ${subscriberRate?.price_with_tva.toFixed(
                subscriberRate.is_price === 2 ? 3 : 2
              )} ${
                priceTypes.find((type) => type.id === subscriberRate.is_price)
                  ?.value
              }`
            );
            if (spouseRate) {
              let rateDescription = `${spouse.label}: `;

              if (warranty?.structure_cotisation !== "U" && warranty?.structure_cotisation !== "U - Unique") {
                let percentSalary = spouseRate.percent_salary > 0 ? `${spouseRate.percent_salary}% Salaire + ` : "";
                let priceWithTVA = spouseRate?.price_with_tva.toFixed(spouseRate.is_price === 2 ? 3 : 2);
                let priceType = priceTypes.find((type) => type.id === spouseRate.is_price)?.value;

                rateDescription += `${percentSalary}${priceWithTVA} ${priceType}`;
              } else {
                rateDescription += "0 €";
              }

              displayedRates.push(rateDescription);
            }
            children &&
              children.forEach((child, index) => {
                if ((index < 2 || child.age >= 16) && (warranty?.structure_cotisation !== "U" && warranty?.structure_cotisation !== "U - Unique")) {
                  const childRate = findRateByAgeRegime(
                    warranty.rates,
                    child.age,
                    child.regime,
                    warranty.typerate
                  );
                  displayedRates.push(
                    `${child.label}: ${
                      childRate.percent_salary > 0
                        ? childRate.percent_salary + "% Salaire + "
                        : ""
                    } ${childRate?.price_with_tva.toFixed(
                      childRate.is_price === 2 ? 3 : 2
                    )} ${
                      priceTypes.find((type) => type.id === childRate.is_price)
                        ?.value
                    }`
                  );
                } else {
                  displayedRates.push(`${child.label}: 0 €`);
                }
              });
          }
          warranty.displayedRates = displayedRates;
          return warranty;
        });

      //Special case when no rates are founds for "renforts", we display rates for a fallback structure than the one selected, the fallback structure is "AE".
      if (isCompany && displayedRenfortsOptions.length === 0) {
        let filteredWarranties = warranties.map((warranty) => {
          return {
            ...warranty,
            rates: warranty.rates
              .filter(
                (rate) =>
                  rate.structure_cotisation?.split(" - ")[0].trim() === "AE"
              ) //Fallback structure
              ?.filter(
                (rate) =>
                  rate.commission ===
                  commissionsList.find(
                    (commission) => commission.value === selectedCommission
                  )?.id
              ),
          };
        });

        //Removing warranties with no rates
        filteredWarranties = filteredWarranties.filter(
          (warranty) => warranty.rates.length > 0
        );

        displayedRenfortsOptions = filteredWarranties
          .filter((warranty) =>
            displayedRenfortsOptionsIds.includes(warranty.id)
          )
          .map((warranty) => {
            warranty.displayedRates = warranty.rates
              .sort(compareRates)
              .map(
                (rate) =>
                  `${rate.structure_cotisation}: ${
                    rate.percent_salary > 0
                      ? rate.percent_salary + "% Salaire + "
                      : ""
                  } ${rate.price_with_tva.toFixed(
                    rate.is_price === 2 ? 3 : 2
                  )} ${
                    priceTypes.find((type) => type.id === rate.is_price)?.value
                  }`
              );
            return warranty;
          });
      }

      setDisplayedRenfortsOptions(
        displayedRenfortsOptions.sort(compareWarrantyRates)
      );
    }
  }, [packs, selectedBase, selectedRenfortsOptions]);

  // Filter warranties base on active filters.
  useEffect(() => {
    if (warranties.length > 0 && commissionsList.length > 0) {
      let filteredWarranties = warranties.map((warranty) => {
        return {
          ...warranty,
          rates: warranty.rates
            .filter(
              (rate) =>
                rate.structure_cotisation?.split(" - ")[0].trim() ===
                structures.find(
                  (structure) => structure.value === selectedStructure
                ).id
            )
            ?.filter(
              (rate) =>
                rate.commission ===
                commissionsList.find(
                  (commission) => commission.value === selectedCommission
                )?.id
            ),
        };
      });

      //If we are in individual case, we filter warranties that have all rates corresponding to childrens and spouse if they are selected
      !isCompany &&
        (filteredWarranties = filteredWarranties.filter((warranty) =>
          isWarrantyValidForIndividual(warranty)
        ));

      //Removing warranties with no rates
      setFilteredWarranties(
        filteredWarranties.filter((warranty) => warranty.rates.length > 0)
      );
      setSelectedBase(null);
      setSelectedPacks(null);
      setSelectedRates({ base: {}, renfort: {}, option: {}, pct: {} });
      setTotalPrice(0);
      setDisplayedRenfortsOptions([]);
      setDisplayedBases([]);
    }
  }, [selectedStructure, selectedCommission, warranties, commissionsList]);

  // Set Displayed Bases
  useEffect(() => {
    if (filteredWarranties.length > 0) {
      setDisplayedBases(
        filteredWarranties
          .filter((warranty) => warranty.nature === "base")
          .map((warranty) => {
            let displayedRates = [];
            if (isCompany) {
              displayedRates = warranty.rates
                .sort(compareRates)
                .map(
                  (rate) =>
                    `${rate.structure_cotisation}: ${
                      rate.percent_salary > 0
                        ? rate.percent_salary + "% Salaire + "
                        : ""
                    } ${rate.price_with_tva.toFixed(
                      rate.is_price === 2 ? 3 : 2
                    )} ${
                      priceTypes.find((type) => type.id === rate.is_price)
                        ?.value
                    }`
                );
            } else {
              const subscriberRate = findRateByAgeRegime(
                warranty.rates,
                subscriber.age,
                subscriber.regime,
                warranty.typerate
              );
              const spouseRate = spouse
                ? findRateByAgeRegime(
                    warranty.rates,
                    spouse.age,
                    spouse.regime,
                    warranty.typerate
                  )
                : null;

              displayedRates.push(
                `${subscriber.label}: ${
                  subscriberRate.percent_salary > 0
                    ? subscriberRate.percent_salary + "% Salaire + "
                    : ""
                } ${subscriberRate?.price_with_tva.toFixed(
                  subscriberRate.is_price === 2 ? 3 : 2
                )} ${
                  priceTypes.find((type) => type.id === subscriberRate.is_price)
                    ?.value
                }`
              );
              if(spouse) {
                (spouseRate && warranty?.structure_cotisation !== "U" && warranty?.structure_cotisation !== "U - Unique") ?
                    displayedRates.push(
                        `${spouse.label}: ${
                            spouseRate.percent_salary > 0
                                ? spouseRate.percent_salary + "% Salaire + "
                                : ""
                        } ${spouseRate?.price_with_tva.toFixed(
                            spouseRate.is_price === 2 ? 3 : 2
                        )} ${
                            priceTypes.find((type) => type.id === spouseRate.is_price)
                                ?.value
                        }`
                    ) : displayedRates.push(
                        `${spouse.label}: 0 €`
                    );
              }
              children &&
                children.forEach((child, index) => {
                  if ((index < 2 || child.age >= 16) && (warranty?.structure_cotisation !== "U" && warranty?.structure_cotisation !== "U - Unique")) {
                    const childRate = findRateByAgeRegime(
                      warranty.rates,
                      child.age,
                      child.regime,
                      warranty.typerate
                    );
                    displayedRates.push(
                      `${child.label}: ${
                        childRate.percent_salary > 0
                          ? childRate.percent_salary + "% Salaire + "
                          : ""
                      } ${childRate?.price_with_tva.toFixed(
                        childRate.is_price === 2 ? 3 : 2
                      )} ${
                        priceTypes.find(
                          (type) => type.id === childRate.is_price
                        )?.value
                      }`
                    );
                  } else {
                    displayedRates.push(`${child.label}: 0 €`);
                  }
                });
            }
            warranty.displayedRates = displayedRates;
            return warranty;
          })
          .sort(compareWarrantyRates)
      );
    }
  }, [filteredWarranties]);

  //In case we want to load old data
  useEffect(() => {
    if (
      !isOldDataLoaded &&
      oavData.selectedBase &&
      filteredWarranties.length > 0
    ) {
      const baseWarranty = filteredWarranties.find(
        (warranty) => warranty.id === oavData.selectedBase
      );
      baseWarranty && handleBaseClick(baseWarranty);

      oavData.selectedRenfortsOptions &&
        oavData.selectedRenfortsOptions.forEach((id) => {
          const renfortOptionId = warranties.find(
            (warranty) => warranty.id === id
          ).id;
          renfortOptionId && handleRenfortOptionClick(renfortOptionId);
        });

      setIsOldDataLoaded(true);
    }
  }, [filteredWarranties, isOldDataLoaded, oavData]);

  //Registering rates based on selected base / renforts / options
  useEffect(() => {
    if (filteredWarranties.length > 0 && selectedBase) {
      const fixedPrice = (label, price, priceType, percentSalary) => {
        return {
          label: label,
          price: price,
          priceType: priceType,
          percentSalary: percentSalary,
        };
      };
      const getRatesFromSelectedWarranties = (selectedWarranties) => {
        let rates = {};

        selectedWarranties.forEach((selectedWarrantyId) => {
          const renfortOptionWarranty = displayedRenfortsOptions.find(
            (warranty) => warranty.id === selectedWarrantyId
          );
          rates = { ...rates, [renfortOptionWarranty.id]: [] };

          if (isCompany) {
            rates[renfortOptionWarranty.id] = renfortOptionWarranty.rates.map(
              (rate) =>
                fixedPrice(
                  rate.structure_cotisation,
                  parseFloat(
                    rate.price_with_tva.toFixed(rate.is_price === 2 ? 3 : 2)
                  ),
                  rate.is_price,
                  rate?.percent_salary
                )
            );
          } else {
            const subscriberRate = findRateByAgeRegime(
              renfortOptionWarranty.rates,
              subscriber.age,
              subscriber.regime,
              renfortOptionWarranty.typerate
            );
            const subscriberPrice = parseFloat(
              subscriberRate?.price_with_tva.toFixed(
                subscriberRate?.is_price === 2 ? 3 : 2
              )
            );

            totalPrice += subscriberPrice;
            rates[renfortOptionWarranty.id].push(
              fixedPrice(
                subscriber.label,
                subscriberPrice,
                subscriberRate?.is_price,
                subscriberRate?.percent_salary
              )
            );

            console.log('calcul spouse',renfortOptionWarranty)

            if (spouse && (renfortOptionWarranty?.structure_cotisation !== "U" && renfortOptionWarranty?.structure_cotisation !== "U - Unique")) {
              const spouseRate = findRateByAgeRegime(
                  renfortOptionWarranty.rates,
                  spouse.age,
                  spouse.regime,
                  renfortOptionWarranty.typerate
              );
              const spousePrice = parseFloat(
                  spouseRate?.price_with_tva.toFixed(
                      spouseRate?.is_price === 2 ? 3 : 2
                  )
              );

              totalPrice = totalPrice + spousePrice;
              rates[renfortOptionWarranty.id].push(
                  fixedPrice(
                      spouse.label,
                      spousePrice,
                      spouseRate?.is_price,
                      spouseRate?.percent_salary
                  )
              );
            } else if (spouse && (renfortOptionWarranty?.structure_cotisation === "U" || renfortOptionWarranty?.structure_cotisation === "U - Unique")) {
              rates[renfortOptionWarranty.id].push(
                  fixedPrice(
                      spouse.label,
                      0,
                      1,
                      0
                  )
              );
            }

            children &&
            children.forEach((child, index) => {
              if (renfortOptionWarranty?.structure_cotisation !== "U" && renfortOptionWarranty?.structure_cotisation !== "U - Unique") {
                const childRate = findRateByAgeRegime(
                    renfortOptionWarranty.rates,
                    child.age,
                    child.regime,
                    renfortOptionWarranty.typerate
                );
                const childPrice =
                    index < 2 || child.age >= 16
                        ? parseFloat(
                            childRate?.price_with_tva.toFixed(
                                childRate?.is_price === 2 ? 3 : 2
                            )
                        )
                        : 0; // Free for more than 2 child

                totalPrice = totalPrice + childPrice;
                base.push(
                    fixedPrice(
                        child.label,
                        childPrice,
                        childRate?.is_price,
                        childRate?.percent_salary
                    )
                );
              } else {
                base.push(
                    fixedPrice(
                        child.label,
                        0,
                        1,
                        0
                    )
                );
              }
            });
          }
        });

        return rates;
      };

      let base = [];
      let totalPrice = 0;
      const baseWarranty = filteredWarranties.find(
        (warranty) => warranty.id === selectedBase
      );

      if (isCompany) {
        base = baseWarranty.rates.map((rate) =>
          fixedPrice(
            rate.structure_cotisation,
            parseFloat(
              rate.price_with_tva.toFixed(rate.is_price === 2 ? 3 : 2)
            ),
            rate?.is_price,
            rate?.percent_salary
          )
        );
      } else {
        const subscriberRate = findRateByAgeRegime(
          baseWarranty.rates,
          subscriber.age,
          subscriber.regime,
          baseWarranty.typerate
        );
        const subscriberPrice = parseFloat(
          subscriberRate?.price_with_tva.toFixed(
            subscriberRate?.is_price === 2 ? 3 : 2
          )
        );

        totalPrice = totalPrice + subscriberPrice;
        base.push(
          fixedPrice(
            subscriber.label,
            subscriberPrice,
            subscriberRate?.is_price,
            subscriberRate?.percent_salary
          )
        );

        if (spouse && (baseWarranty?.structure_cotisation !== "U" && baseWarranty?.structure_cotisation !== "U - Unique")) {
          const spouseRate = findRateByAgeRegime(
            baseWarranty.rates,
            spouse.age,
            spouse.regime,
            baseWarranty.typerate
          );
          const spousePrice = parseFloat(
            spouseRate?.price_with_tva.toFixed(
              spouseRate?.is_price === 2 ? 3 : 2
            )
          );

          totalPrice = totalPrice + spousePrice;
          base.push(
            fixedPrice(
              spouse.label,
              spousePrice,
              spouseRate?.is_price,
              spouseRate?.percent_salary
            )
          );
        } else if (spouse && (baseWarranty?.structure_cotisation === "U" || baseWarranty?.structure_cotisation === "U - Unique")) {
          base.push(
              fixedPrice(
                  spouse.label,
                  0,
                  1,
                  0
              )
          );
        }
        children &&
          children.forEach((child, index) => {
            if (baseWarranty?.structure_cotisation !== "U" && baseWarranty?.structure_cotisation !== "U - Unique") {
            const childRate = findRateByAgeRegime(
              baseWarranty.rates,
              child.age,
              child.regime,
              baseWarranty.typerate
            );
            const childPrice =
              index < 2 || child.age >= 16
                ? parseFloat(
                    childRate?.price_with_tva.toFixed(
                      childRate?.is_price === 2 ? 3 : 2
                    )
                  )
                : 0; // Free for more than 2 child

            totalPrice = totalPrice + childPrice;
            base.push(
              fixedPrice(
                child.label,
                childPrice,
                childRate?.is_price,
                childRate?.percent_salary
              )
            );
            } else {
                base.push(
                    fixedPrice(
                        child.label,
                        0,
                        1,
                        0
                    )
                );
            }
          });

      }

      let selectedRenforts = [];
      let selectedOptions = [];
      let selectedPCs = [];

      selectedRenfortsOptions.forEach((selectedRenfortOptionId) => {
        const displayedRenfortOption = displayedRenfortsOptions.find(
          (displayedRenfortOption) =>
            displayedRenfortOption.id === selectedRenfortOptionId
        );

        if (displayedRenfortOption) {
          switch (displayedRenfortOption.nature) {
            case "renfort":
              selectedRenforts.push(selectedRenfortOptionId);
              break;
            case "option":
              selectedOptions.push(selectedRenfortOptionId);
              break;
            case "supp":
              selectedPCs.push(selectedRenfortOptionId);
              break;
            default:
              break;
          }
        }
      });

      const optionnalStructure = fullStructures.find(
        (structure) =>
          structure.code ===
          displayedRenfortsOptions.find(
            (displayedRenfortsOptions) =>
              selectedRenfortsOptions[0] === displayedRenfortsOptions.id
          )?.structure_cotisation
      )?.label;

      let renfortsRates = {};
      let optionsRates = {};
      let PCsRates = {};
      if (
        displayedRenfortsOptions.length > 0 &&
        selectedRenfortsOptions.length > 0
      ) {
        renfortsRates = getRatesFromSelectedWarranties(selectedRenforts);
        optionsRates = getRatesFromSelectedWarranties(selectedOptions);
        PCsRates = getRatesFromSelectedWarranties(selectedPCs);
      }

      setTotalPrice(parseFloat(totalPrice.toFixed(2)));
      setSelectedRates((prevState) => {
        return {
          ...prevState,
          base: base,
          renfort: renfortsRates,
          option: optionsRates,
          pc: PCsRates,
          commission: selectedCommission,
          structure: selectedStructure,
          optionnalStructure: optionnalStructure,
        };
      });
    }
  }, [
    filteredWarranties,
    displayedRenfortsOptions,
    selectedBase,
    selectedRenfortsOptions,
  ]);

  function extractNumber(str) {
    if (!str) {
      return null;
    }
    const match = str.match(/(\d+\.\d+)/);
    return match ? parseFloat(match[0]) : null;
  }

  function orderWarranties(warranties) {
    warranties.sort((a, b) => {
      const numberA = extractNumber(a.displayedRates?.[0]);
      const numberB = extractNumber(b.displayedRates?.[0]);
      return numberA - numberB;
    });

    // Deuxième tri par warrantie.product.id
    return warranties.sort((a, b) => {
      // Assurez-vous que warrantie.product.id existe et est un nombre
      const idA = a.product?.id ?? Number.MAX_VALUE;
      const idB = b.product?.id ?? Number.MAX_VALUE;
      return idA - idB;
    });
  }

  return (
    <OAVLayout extra={<OAVSynthese />}>
      <Stepper
        alternativeLabel
        activeStep={2}
        style={{ background: "#fafafa" }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <p
                  style={
                    2 === index
                      ? {
                          fontFamily: "Montserrat !important",
                          fontWeight: "600",
                          color: "#c31d23",
                        }
                      : {
                          fontFamily: "Montserrat !important",
                          fontWeight: "600",
                          color: "#c31d2388",
                        }
                  }
                >
                  {label}
                </p>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {loading ? (
        <div>
          <Box className="spacer5" />
          <LoaderCircular />
          <br />
          <Typography variant={"h6"} alignCenter>
            Chargement des garanties
          </Typography>
        </div>
      ) : isDataEmpty ? (
        <Box
          flex={1}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography variant={"h3"} alignCenter>
            Il n'existe pas de garantie disponible pour ce type de profil
          </Typography>

          <Box className="spacer3" />

          <Button
            className="buttonLightRedStyle"
            onClick={() =>
              history.push(
                "/Tarificateur/" +
                  (isCompany ? "Collectif" : "Individuel") +
                  "/Profil"
              )
            }
          >
            Précédent
          </Button>
        </Box>
      ) : (
        <Box maxWidth={"1000px"} margin={"0 auto"}>
          {oavData.change_guarantee == 1 ||
          !oavData.hasOwnProperty("reprise") ||
          isQuote ? (
            <>
              <Grid
                container
                spacing={2}
                style={{ justifyContent: "left", marginTop: 20 }}
              >
                <Grid item xs={8} md={5}>
                  <Typography
                    variant="h3"
                    style={{ marginBottom: 15, textAlign: "left" }}
                  >
                    Taux de commission
                  </Typography>

                  <CommonSelect
                    label="Selectionnez un taux"
                    field={{
                      value: selectedCommission,
                      onChange: (e) => setSelectedCommision(e),
                    }}
                    options={commissionsList}
                  />
                </Grid>


                  <Grid item xs={5} md={5}>
                    <Typography
                      variant="h3"
                      style={{ marginBottom: 15, textAlign: "left" }}
                    >
                      Structure de cotisation
                    </Typography>
                    <CommonSelect
                      label="Selectionnez une structure"
                      field={{
                        value: selectedStructure,
                        onChange: (e) => setSelectedStructure(e),
                      }}
                      options={structuresList.map((structure) => {
                        return { id: structure.id, value: structure.value };
                      })}
                    />
                  </Grid>


                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    style={{ margin: "1.5rem 0 .8rem", textAlign: "left" }}
                  >
                    Base
                  </Typography>
                  <Grid container spacing={2}>
                    {orderWarranties(displayedBases).map((warranty) => {
                      return (
                        <WarrantyCard
                          key={warranty.id}
                          label={warranty.description}
                          warranty={warranty}
                          rates={warranty.displayedRates}
                          checked={selectedBase === warranty.id}
                          onClick={() => handleBaseClick(warranty)}
                          color={"#C31D23"}
                        />
                      );
                    })}
                  </Grid>
                </Grid>

                {displayedRenfortsOptions?.filter(
                  (warranty) => warranty.nature === "renfort"
                ).length > 0 && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      style={{
                        margin: "1.5rem 0 .8rem",
                        color: "#285A42",
                        textAlign: "left",
                      }}
                    >
                      Renforts
                    </Typography>

                    <Grid container spacing={2}>
                      {orderWarranties(displayedRenfortsOptions)
                        ?.filter((warranty) => warranty.nature === "renfort")
                        ?.map((warranty) => {
                          return (
                            <WarrantyCard
                              key={warranty.id}
                              label={warranty.description}
                              warranty={warranty}
                              rates={warranty.displayedRates}
                              checked={selectedRenfortsOptions.includes(
                                warranty.id
                              )}
                              onClick={() =>
                                handleRenfortOptionClick(warranty.id)
                              }
                              color={"#285A42"}
                            />
                          );
                        })}
                    </Grid>
                  </Grid>
                )}

                {displayedRenfortsOptions?.filter(
                  (warranty) => warranty.nature === "option"
                ).length > 0 && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      style={{
                        margin: "1.5rem 0 .8rem",
                        color: "#252525",
                        textAlign: "left",
                      }}
                    >
                      Options
                    </Typography>

                    <Grid container spacing={2}>
                      {orderWarranties(displayedRenfortsOptions)
                        ?.filter((warranty) => warranty.nature === "option")
                        ?.map((warranty) => {
                          return (
                            <WarrantyCard
                              key={warranty.id}
                              label={warranty.description}
                              warranty={warranty}
                              rates={warranty.displayedRates}
                              checked={selectedRenfortsOptions.includes(
                                warranty.id
                              )}
                              onClick={() =>
                                handleRenfortOptionClick(warranty.id)
                              }
                              color={"#252525"}
                            />
                          );
                        })}
                    </Grid>
                  </Grid>
                )}

                {displayedRenfortsOptions?.filter(
                  (warranty) => warranty.nature === "supp"
                ).length > 0 && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      style={{
                        margin: "1.5rem 0 .8rem",
                        color: "#252525",
                        textAlign: "left",
                      }}
                    >
                      Prestations supplémentaires
                    </Typography>

                    <Grid container spacing={2}>
                      {orderWarranties(displayedRenfortsOptions)
                        ?.filter((warranty) => warranty.nature === "supp")
                        ?.map((warranty) => {
                          return (
                            <WarrantyCard
                              key={warranty?.id}
                              waranty={warranty}
                              label={warranty?.description}
                              rates={warranty?.displayedRates}
                              checked={selectedRenfortsOptions.includes(
                                warranty?.id
                              )}
                              onClick={() =>
                                handleRenfortOptionClick(warranty?.id)
                              }
                              color={"#252525"}
                            />
                          );
                        })}
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {selectedPacks && (
                <Grid item xs={12} style={{ marginBottom: 10, paddingTop: 30 }}>
                  <Typography
                    variant="h3"
                    style={{ margin: "1.5rem 0 .8rem", textAlign: "left" }}
                  >
                    Tableaux et exemples de remboursement
                  </Typography>

                  {selectedPacks?.package_documents
                    .filter(
                      (document) =>
                        (document.ending_date
                          ? document.ending_date >= oavData.starting_date
                          : document) &&
                        document.starting_date <= oavData.starting_date
                    )
                    ?.map((document) => (
                      <Button
                        key={document.id}
                        type="button"
                        onClick={() => handleDocumentClick(document.id)}
                        className="buttonGreyStyle"
                        style={{
                          margin: "0.1rem 1rem 0.5rem 0rem",
                          fontSize: "11px",
                          float: "left",
                        }}
                      >
                        {document.name}
                      </Button>
                    ))}
                </Grid>
              )}

              <Box className="spacer3" />

              {!isCompany && (
                <Grid
                  container
                  xs={12}
                  md={12}
                  style={{
                    justifyContent: "center",
                    backgroundColor: "#FFF",
                    padding: "0.5rem 1rem",
                    borderRadius: 4,
                    marginBottom: "2rem",
                    marginTop: "5rem",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      marginTop: "0.5rem",
                      marginBottom: "0.5rem",
                      color: "#C31D23",
                    }}
                  >
                    Cotisation : <strong>{totalPrice}€ </strong>/ mois
                  </Typography>
                </Grid>
              )}
            </>
          ) : (
            <Grid style={{ marginTop: "auto", marginBottom: "auto" }}>
              <SquaresPlusIcon
                style={{ width: "100px", height: "100px", color: "#C31D2340" }}
              />
              <Typography component="p" variant="h3">
                {!limitYear
                  ? "Votre contrat a moins d'un an, vous ne pouvez pas modifier les garanties pour le moment"
                  : "Vous avez choisi de maintenir vos garanties"}
              </Typography>
            </Grid>
          )}

          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                marginBottom: 10,
                paddingTop: 20,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Button
                className="buttonLightRedStyle"
                onClick={() =>
                  history.push(
                    "/Tarificateur/" +
                      (isCompany ? "Collectif" : "Individuel") +
                      "/Profil"
                  )
                }
              >
                Précédent
              </Button>
            </Grid>

            {(!oavData?.reprise || isQuote) && (
              <Grid
                item
                xs={12}
                md={4}
                style={{ display: "flex", paddingTop: 20 }}
              >
                <Button
                  className={
                    !selectedPacks
                      ? "buttonLightRedStyle"
                      : "buttonGeneralStyle"
                  }
                  disabled={!selectedPacks}
                  onClick={() => {
                    handleSubmitClick();
                    history.push(
                      "/Tarificateur/" +
                        (isCompany ? "Collectif" : "Individuel") +
                        "/Devis"
                    );
                  }}
                >
                  Devis
                </Button>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={4}
              style={{ display: "flex", paddingTop: 20 }}
            >
              <Button
                className={
                  !selectedPacks ? "buttonLightRedStyle" : "buttonGeneralStyle"
                }
                disabled={!selectedPacks}
                onClick={() => {
                  handleSubmitClick();
                  history.push(
                    "/Tarificateur/" +
                      (isCompany ? "Collectif" : "Individuel") +
                      "/Souscrire"
                  );
                }}
              >
                Souscrire
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      <CustomModal open={!!file} handleClose={() => setFile(null)}>
        <PDFViewer file={file} />
      </CustomModal>
    </OAVLayout>
  );
};

export default OAVWarranties;
