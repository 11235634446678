import React, {useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {Grid, Typography, Button, Box, Divider, FormControlLabel, Checkbox} from "@material-ui/core";
import {
    formatErrorZip,
    formatErrorPhoneNumber,
    formatErrorEmail,
} from "../../../utils/formValidation";
import OAVLayout from "../OAVComponents/OAVLayout";
import CommonTextField from "../../FormComponents/CommonTextField";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import {useDispatch, useSelector} from "react-redux";
import {updateOav} from "../../../Store/action/oav";
import {useHistory} from "react-router-dom";
import OAVSynthese from "../OAVComponents/OAVSynthese";
import {addQuote} from "../../../Axios/Call/ClientCredential";
import LoaderGeneration from "../../Commons/Loading/LoaderGeneration";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CommonSelect from "../../FormComponents/CommonSelect";


const OAVDevis = () => {
    const civilites = useSelector(state => state.user.civilites);
    const [loading, setLoading] = useState(false);
    const [successapi, setsuccessapi] = useState(false);
    const [errorapi, seterrorapi] = useState(false);
    const oav = useSelector((state) => state.oav.data);
    const broker_id = useSelector(
        (state) => state.user.userData.broker_id || state.user.userData.id
    );
    const history = useHistory();
    const dispatch = useDispatch();

    // Form steps and setting

    const {register, handleSubmit, watch, errors, control, setValue, getValues} =
        useForm({
            mode: "onBlur",
            reValidateMode: "all",

            defaultValues: {
                company: oav?.companyData?.company,
                siren: oav?.companyData?.siren,
                nic: oav?.companyData?.nic,
                idcc: oav?.companyData?.idcc,
                address: oav?.personnalData?.address,
                zip_code: typeof oav?.zip_code === "string"
                    ? oav.zip_code
                    : oav?.zip_code?.place?.zipcode,
                city: typeof oav?.zip_code === "string"
                    ? oav?.personnalData?.city
                    : oav?.zip_code?.place?.place,
                firt_name: oav?.personnalData?.firstname,
                last_name: oav?.personnalData?.lastname,
                email: oav?.personnalData?.email,
                phone: oav?.personnalData?.phone,
                job: oav?.personnalData?.job,
                civilite: oav?.personnalData?.civilite
                ? oav?.personnalData?.civilite
                : 1,
            },
        });

    const createDataSub = (
        broker_id,
        product_id,
        profession_id,
        starting_date,
        madelin,
        firt_name,
        last_name,
        civilite,
        email,
        phone,
        role,
        company,
        siren,
        nic,
        naf,
        address,
        city,
        zip_code,
        address_correspondance,
        city_correspondance,
        zip_code_correspondance,
        first_name_comptable,
        last_name_comptable,
        phone_comptable,
        email_comptable,
        regime,
        college,
        effectif,
        age_moyen,
        structure_cotisation,
        appel,
        periodicite,
        peridiocite_appel,
        taux,
        idcc,
        commercial_id,
        price,
        total_price,
        meta,
        iban,
        bic,
        bank_account_owner,
        iban_payment,
        bic_payment,
        bank_account_owner_payment,
        ref_quote_id,
        packages
    ) => {
        return {
            type: "quote",
            signed: 0,
            status_signed: "waiting",
            status_not_signed: "sended",
            devis_status: "sent",
            contract_status: "sent",
            broker_id,
            product_id,
            profession_id,
            starting_date,
            madelin,
            firt_name,
            last_name,
            civilite,
            email,
            phone,
            role: role,
            company,
            siren,
            nic,
            naf,
            address,
            city,
            zip_code,
            address_correspondance,
            city_correspondance,
            zip_code_correspondance,
            first_name_comptable,
            last_name_comptable,
            phone_comptable,
            email_comptable,
            regime,
            college,
            effectif,
            age_moyen,
            structure_cotisation,
            appel,
            periodicite,
            peridiocite_appel,
            taux,
            idcc,
            commercial_id,
            price,
            total_price,
            meta,
            iban,
            bic,
            bank_account_owner,
            iban_payment,
            bic_payment,
            bank_account_owner_payment,
            ref_quote_id,
            packages: packages
        };
    };

    const handleStep = (data) => {
        setLoading(true);

        const QSP = [];
        for (const [key, value] of Object.entries(oav)) {
            //console.log(key, value);
            if (key === "base") {
                if (value !== 0 && value !== "0") {
                    QSP.push({id: oav?.pack, guarantee_id: value * 1, type: "base"});
                }
            }
            if (key === "renfort") {
                oav.renfort.forEach((renfortId) => {
                    if (value.length > 0) {
                        QSP.push({
                            id: oav?.pack,
                            guarantee_id: renfortId,
                            type: "renfort",
                        });
                    }
                });
            }
            if (key === "option") {
                oav.option.forEach((optionId) => {
                    if (value.length > 0) {
                        QSP.push({id: oav?.pack, guarantee_id: optionId, type: "option"});
                    }
                });
            }
        }

        //console.log({ QSP });
        //console.log(oav, data);
        const tmpdata = createDataSub(
            broker_id,
            oav.product,
            oav.activity_id,
            oav.starting_date,
            "non_madelin",
            data.firt_name,
            data.last_name,
            data.civilite,
            data.email,
            data.phone,
            data.job,
            data.company,
            data.siren,
            data.nic,
            data.naf,
            data.address,
            data.city,
            data.zip_code,
            data.address_correspondance,
            data.city_correspondance,
            data.zip_code_correspondance,
            data.first_name_comptable,
            data.last_name_comptable,
            data.phone_comptable,
            data.email_comptable,
            oav.regime,
            oav.college,
            oav.effectif,
            oav.age_moyen,
            oav.structure_cotisation,
            data.appel,
            data.periodicite,
            data.peridiocite_appel,
            oav.selectedCommission,
            data.idcc,
            oav?.commercial_id,
            oav.price,
            oav.total_price,
            oav.meta,
            data.iban,
            data.bic,
            data.bank_account_owner,
            data.iban,
            data.bic,
            data.bank_account_owner,
            data.ref_quote_id,
            JSON.stringify(QSP)
        );

        addQuote(tmpdata)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    setsuccessapi(true);
                    history.push(`/Devis/Collectifs/${res.data.id}`);
                } else {
                    seterrorapi(true);
                }
            })
            .catch((err) => console.warn(err))
            .finally(() => {
                for (const [key, value] of Object.entries(data)) {
                    dispatch(updateOav(key, value));
                }
                setLoading(false);
            });
    };

    const handleCreatingSiren = () => {
        const currentValue = getValues("creatingSiren");
        setValue("creatingSiren", !currentValue);
        currentValue
            ? setValue('siren', '')
            : setValue('siren', 'En cours de création')
    };

    // hooks
    return (
        <OAVLayout extra={<OAVSynthese/>}>
            {successapi && (<AlertGeneral setTrigger={setsuccessapi} color="" description="Devis enregistré"/>)}
            {errorapi && (<AlertGeneral setTrigger={seterrorapi} color="danger" description="Une erreur est survenue"/>)}
            {loading ? (<LoaderGeneration/>)
                : (<Box maxWidth="900px" margin="0 auto">
                    <form onSubmit={handleSubmit(handleStep)} style={{marginTop: 20, textAlign: "left"}} noValidate>
                        <Grid container alignItems="center">
                            <GroupAddIcon width="50px" fontSize="large" style={{color: "#C31D23", marginRight: 10}}/>
                            <Typography variant="h1">Devis collectif</Typography>
                        </Grid>

                        <Divider style={{marginTop: 10, marginBottom: 30, color: "#C31D2330"}}/>

                        <Box className="spacer2"/>

                        <Grid container spacing={2}>
                            <Grid container style={{marginLeft: 10, marginTop: 20}}>
                                <Typography variant="h3" component="p">
                                    Votre entreprise
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="company"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Raison sociale"
                                                error={errors.company}
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box style={{ display: "flex", alignItems: 'center', gap: 10}}>
                                    <FormControlLabel
                                        style={{display: "block", textAlign: "center", margin: '0.8rem 0 0 0'}}
                                        control={
                                            <Checkbox
                                                value={getValues("creatingSiren")}
                                                style={{ color: "#C31D23" }}
                                                checked={getValues("creatingSiren")}
                                                onClick={handleCreatingSiren}
                                                defaultValue={false}
                                                defaultChecked={false}
                                                name="creatingSiren"
                                            />
                                        }
                                        name="creatingSiren"
                                        label={"En cours de création"}
                                        ref={register("creatingSiren")}
                                    />

                                    <Controller
                                        control={control}
                                        rules={{required: true}}
                                        name="siren"
                                        render={(field) => {
                                            return (
                                                <CommonTextField
                                                    readOnly={watch('creatingSiren')}
                                                    field={field}
                                                    label="Numéro de siren"
                                                    error={errors.siren}
                                                    InputProps={{
                                                        shrink: watch('creatingSiren')
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={7} md={2}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="nic"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Code nic"
                                                error={errors.nic}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="idcc"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Numéro idcc"
                                                error={errors.idcc}
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid container style={{marginLeft: 10, marginTop: 20}}>
                                <Typography variant="h3">Adresse de l'entreprise</Typography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="address"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Adresse (numéro et libellé de voie)"
                                                error={errors.address}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Controller
                                    control={control}
                                    name="zip_code"
                                    rules={{required: true, validate: formatErrorZip}}
                                    render={(field) => (
                                        <CommonTextField
                                            field={field}
                                            label="Code postal"
                                            name="zip_code"
                                            InputProps={{disabled: true}}
                                            error={errors.zip_code}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="city"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Ville"
                                                error={errors.city}
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid container style={{marginLeft: 10, marginTop: 20}}>
                                <Typography variant="h3">Interlocuteur</Typography>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="civilite"
                                    render={(field) => {
                                        return (
                                            <CommonSelect
                                                field={field}
                                                label="Civilité"
                                                error={errors.civilite}
                                                options={civilites}
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} md={6}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="firt_name"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Prénom"
                                                error={errors.firt_name}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="last_name"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Nom"
                                                error={errors.last_name}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Controller
                                    control={control}
                                    rules={{ validate: formatErrorEmail}}
                                    name="email"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                type={"email"}
                                                field={field}
                                                label="Email"
                                                error={errors.email}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Controller
                                    control={control}
                                    rules={{required: true, validate: formatErrorPhoneNumber}}
                                    name="phone"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                type={"tel"}
                                                field={field}
                                                label="Téléphone"
                                                error={errors.phone}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="job"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Fonction"
                                                error={errors.job}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box className="spacer2"/>

                        {/* <Grid container > */}
                        <Grid container spacing={2} style={{float: "center"}}>
                            <Button
                                className="buttonLightRedStyle"
                                onClick={() => history.push("/Tarificateur/Garanties")}
                                style={{marginLeft: 10, marginRight: 10}}
                            >
                                Précédent
                            </Button>

                            <Button
                                type="submit"
                                className="buttonGeneralStyle"
                                onClick={() => setValue("quote_type", "download")}
                                style={{marginLeft: 10, marginRight: 10}}
                            >
                                Générer le devis
                            </Button>

                            <Button
                                className="buttonGreenStyle"
                                onClick={() => history.push("/Tarificateur/Besoin")}
                                style={{marginLeft: 10, marginRight: 10}}
                            >
                                Nouveau devis
                            </Button>
                        </Grid>
                    </form>
                </Box>
            )}
        </OAVLayout>
    );
};

export default OAVDevis;
