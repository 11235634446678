//--------------------------- Lottie ---------------------------
import React from "react";
import Lottie from "lottie-react";
import loader from "../../../Assets/Animation/loader.json";
import error from "../../../Assets/Animation/error.json";

interface Props {
  animationData?: string;
}

const LoadingButton: React.FC<Props> = ({ animationData }) => {
  let animation: any = loader;
  if (animationData === "error") {
    animation = error;
  }

  const defualtOptions = {
    animationData: animation,
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      animationData={animation}
      loop={true}
      autoplay={true}
      rendererSettings={{
        preserveAspectRatio: "xMidYMid slice",
      }}
      style={{
        height: animationData === "error" ? 120 : 120,
        width: animationData === "error" ? 120 : 200,
      }}
    />
  );
};

export default LoadingButton;
