import React, { useState, useEffect } from "react";
import CustomHeader from "../../Components/Header/CustomHeader";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular";
import HeaderSections from "../../Components/Commons/HeaderSections/HeaderSections";
import { BsFileCheck } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const Documents = ({ children }) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [section, setSection] = useState("personnel");
  const userData = useSelector((state) => state.user.userData);


  const history = useHistory();
  const location = useLocation();
  const broker_type = userData?.broker_type;

  const sections = [
    {
      action: "generic",
      name: "Documents génériques",
      redirect: `/Documents/Generiques`,
    },
    broker_type === "broker-admin" && ( {
      action: "personnel",
      name: "Documents personnels",
      redirect: `/Documents/Personnels`,
    }),

  ];

  useEffect(() => {
    if (location.pathname) {
      if (location.pathname.includes("Generiques")) {
        setSection("generic");
      }
      if (location.pathname.includes("Personnels")) {
        setSection("personnel");
      }
    }
  }, [location.pathname]);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={
            "Une erreur est survenue lors de l'appel à la base de données"
          }
        />
      )}
      <CustomHeader 
        icon={
          <BsFileCheck
            size={30}
            style={{ fill: "rgb(195, 29, 35)", marginRight: ".5rem" }}
          />
        }
        pageName={`Documents ${section}`}
        childComponent={
          <HeaderSections
            setSection={setSection}
            section={section}
            sections={sections}
          />
        }
      />
      {loadingApi ? <LoaderCircular /> : <>{React.cloneElement(children)}</>}
    </>
  );
};

export default Documents;
