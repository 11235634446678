import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Paper,
  Box,
} from "@material-ui/core";
import { FaUserCircle } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { AiFillFileAdd } from "react-icons/ai";
import OAVLayout from "../OAVComponents/OAVLayout";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cleanOavData, updateOav } from "../../../Store/action/oav";
import OAVSynthese from "../OAVComponents/OAVSynthese";
import {
  getAllCommercials,
  getAllProfessions,
  getQuotes,
    getQuote,
  getAllProductsIndependants
} from "../../../Axios/Call/ClientCredential";
import CommonSelect from "../../../Containers/Components/FormComponents/CommonSelect";
import CommonTextField from "../../FormComponents/CommonTextField";
import LoaderCircular from "../../Commons/Loading/LoaderCircular";
import moment from "moment";
import "moment/locale/fr";
import { useWindowWidth } from "@react-hook/window-size";
import * as actionCreator from "../../../Store/action";

moment.locale("fr");

const OAVBesoin = () => {
  const oav = useSelector((state) => state.oav.data);
  const [prestations, setprestations] = useState([]);
  const [professions, setprofessions] = useState([]);
  const [produits_independants, setproduits_independants] = useState([
    { id: null, value: "Aucun" },
  ]);

  const [filteredProducts, setfilteredProducts] = useState([
    { id: null, value: "Aucun", madelin: null },
  ]);
  const [commercials, setcommercials] = useState([]);
  const [quotes, setquotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const userData = useSelector((state) => state.user.userData);

  const history = useHistory();
  const dispatch = useDispatch();
  const width = useWindowWidth();
  // Steps

  const steps = ["Besoin", "Profil", "Choix de la garantie"];

  const OKRED = ["#c31d23", "#ffffff"];
  const OKWHITE = ["#ffffff", "#c31d23"];

  const setWatchColor = (target, value, colors) => {
    return getValues(target) === value ? colors[0] : colors[1];
  };

  // Form steps and settings

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    unregister,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    defaultValues: {
      contrat: oav?.contrat,
      status: oav?.status,
      madelin: oav?.madelin,

      //motif: oav?.motif, TO GET BACK FOR PRODUCTION
      motif: "adhesion",
      ccn_mode: oav?.ccn_mode,
      ccn: oav?.ccn,
      adherent: oav?.adherent,
    },
  });

  function handleStep(data) {
    let profession_id = professions.filter((p) => p.value === data?.status)[0]
      ?.id;
    for (const [key, value] of Object.entries(data)) {
      if (key === "ccn") {
        let ccnId = commercials.find((c) => c.name === value)?.id;

        dispatch(updateOav("commercial_id", ccnId));
        dispatch(updateOav(key, value));
      }
      dispatch(updateOav(key, value));
    }
    dispatch(updateOav("profession_id", profession_id));
    if (data.ccn_mode === "ccn") {
      let tmpcommid = commercials.filter((p) => p.value === data.ccn)[0]?.id;
      dispatch(updateOav("commercial_id", tmpcommid));
    }
    if (getValues("contrat") === "individuel") {
      history.push("/Tarificateur/Individuel/Profil");
    }
    if (getValues("contrat") === "collectif") {
      history.push("/Tarificateur/Collectif/Profil");
    }
  }

  function loadQuoteData(id, value) {
    setLoading(true);
    setName(value);
    setValue("adherent", "");
    setValue("adherent_id", id);
    dispatch(actionCreator.cleanOavData());
    getQuote(id)
        .then((res) => {

      console.log(res.data);
          if (res?.data?.age_moyen) {
            history.push(`/Tarificateur/Collectif/Profil?current=${id}`);

          } else {
            history.push(`/Tarificateur/Individuel/Profil?current=${id}`);
          }
        });

  }

  useEffect(() => {
    dispatch(cleanOavData());
    setValue("status", oav?.status);
    getAllProfessions().then((res) => {
      setprofessions(
        res?.data.map((p) => {
          return {
            id: p.id,
            value: p.name,
          };
        })
      );
    });

    getAllProductsIndependants().then((res) => {
      const mappedData = res?.data.map((p) => {
        return {
          id: p.id,
          value: p.description,
          madelin: p.madelin,
        };
      });
      mappedData.unshift({ id: null, value: "Aucun", madelin: null });
      setproduits_independants(mappedData);
    });

    getAllCommercials().then((res) => {
      setcommercials(
        res?.data.map((p) => {
          return {
            id: p.id,
            value: p.name,
            disabled: p.id === 5 && true,
          };
        })
      );
    });

    getQuotes().then((res) => {
      const data = res.data
        .filter((data) => data.broker_id === userData.id)
        .map((q) => {
          return { ...q, value: `${q.firt_name} ${q.last_name}` };
        });
      setquotes(data);
    });
  }, []);

  useEffect(() => {
    if (getValues("contrat") === "individuel") {
      unregister(["ccn_mode", "ccn"]);
    }
    if (getValues("contrat") === "collectif") {
      unregister(["status", "madelin"]);
    }
  }, [unregister, watch("contrat")]);

  useEffect(() => {
    if (getValues("motif") === "adhesion") {
      setName("");
      unregister("adherent");
      unregister("adherent_id");
    }
    if (getValues("motif") === "avenant") {
      register("adherent");
      register("adherent_id");
    }
  }, [unregister, watch("motif")]);

  useEffect(() => {
    setValue("status", oav?.status);
    setValue("madelin", oav?.madelin);
    setValue("ccn", oav?.ccn);
    setValue("ccn_mode", oav?.ccn_mode);
  }, [watch("contrat"), loading]);

  useEffect(() => {
    setValue("adherent", oav?.adherent);
  }, [watch("motif")]);

  useEffect(() => {}, [watch("madelin"), watch("ccn_mode"), watch("ccn")]);

  useEffect(() => {

    console.log(produits_independants)

    const madelin = getValues('madelin');

    const checkMadelin = madelin === 'madelin' ? 0 : 1;
    

    const filteredProductsAction = produits_independants.filter(p => 
      p.madelin === null || (p.madelin === checkMadelin)
    );

    setfilteredProducts(filteredProductsAction);

  }, [watch('madelin')]);

  useEffect(() => {}, [watch("adherent")]);

  useEffect(() => {
    if (getValues("ccn_mode") !== "ccn") {
      unregister("ccn");
    }
  }, [watch("ccn_mode")]);

  useEffect(() => {}, [watch("status")]);

  console.log(quotes,'numerooo')

  return (
    <OAVLayout extra={<OAVSynthese />}>
      <Stepper
        alternativeLabel
        activeStep={0}
        style={{ background: "#fafafa" }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <p
                  style={
                    0 === index
                      ? {
                          fontFamily: "Montserrat !important",
                          fontWeight: "600",
                          color: "#c31d23",
                        }
                      : {
                          fontFamily: "Montserrat !important",
                          fontWeight: "600",
                          color: "#c31d2388",
                        }
                  }
                >
                  {label}
                </p>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {loading ? (
        <LoaderCircular />
      ) : (
        <Box maxWidth="900px" margin="0 auto" width={"100%"}>
          <form
            onSubmit={handleSubmit(handleStep)}
            style={{
              marginTop: 20,
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
            }}
            noValidate
          >
            <Typography component="p" variant="h3" style={{opacity:"0.8", marginBottom:10}}>
              Marché
            </Typography>

            <RadioGroup name="contrat">
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    className="paperBox"
                    style={{
                      background: setWatchColor("contrat", "individuel", OKRED),
                    }}
                  >
                    <FaUserCircle
                      size={40}
                      fill={setWatchColor("contrat", "individuel", OKWHITE)}
                    />
                    <Box className="boxLabel">
                      <FormControlLabel
                        value="individuel"
                        checked={getValues("contrat") === "individuel"}
                        control={
                          <Radio
                            style={{
                              color: setWatchColor(
                                "contrat",
                                "individuel",
                                OKWHITE
                              ),
                            }}
                          />
                        }
                        ref={register("contrat")}
                        onChange={(e) => setValue("contrat", e.target.value)}
                      />
                      <p
                        style={{
                          color: setWatchColor(
                            "contrat",
                            "individuel",
                            OKWHITE
                          ),
                        }}
                      >
                        Individuel
                      </p>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    className="paperBox"
                    style={{
                      background: setWatchColor("contrat", "collectif", OKRED),
                    }}
                  >
                    <IoIosPeople
                      size={40}
                      fill={setWatchColor("contrat", "collectif", OKWHITE)}
                    />
                    <Box className="boxLabel">
                      <FormControlLabel
                        value="collectif"
                        checked={getValues("contrat") === "collectif"}
                        control={
                          <Radio
                            style={{
                              color: setWatchColor(
                                "contrat",
                                "collectif",
                                OKWHITE
                              ),
                            }}
                          />
                        }
                        ref={register("contrat")}
                        onChange={(e) => setValue("contrat", e.target.value)}
                      />
                      <p
                        style={{
                          color: setWatchColor("contrat", "collectif", OKWHITE),
                        }}
                      >
                        Collectif
                      </p>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </RadioGroup>


            {getValues("contrat") === "individuel" && <Typography component="p" variant="h3" style={{opacity:"0.8", marginTop:20}}>
              Statut professionnel
            </Typography>}

            <Grid container spacing={4} style={{ marginTop: "1px" }}>


         
      
              {getValues("contrat") === "individuel" ? (
                <Grid item xs={12} md={6}>
                  <Paper elevation={3} className="paperBoxSelect">
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="status"
                      render={(field) => (
                        <CommonSelect
                          field={field}
                          label="Sélectionnez un statut professionnel"
                          options={professions}
                          error={errors.status}
                          required={true}
                        />
                      )}
                    />
                  </Paper>
                  <Box className="spacer1" />
                  <RadioGroup name="madelin" style={{ width: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Paper
                          elevation={3}
                          className="paperBox"
                          style={{
                            background: setWatchColor(
                              "madelin",
                              "non_madelin",
                              OKRED
                            ),
                          }}
                        >
                          <Box className="boxLabel">
                            <FormControlLabel
                              value="non_madelin"
                              checked={getValues("madelin") === "non_madelin"}
                              control={
                                <Radio
                                  checked={
                                    getValues("madelin") === "non_madelin"
                                  }
                                  style={{
                                    color: setWatchColor(
                                      "madelin",
                                      "non_madelin",
                                      OKWHITE
                                    ),
                                  }}
                                />
                              }
                              ref={register("madelin")}
                              onChange={(e) =>
                                setValue("madelin", e.target.value)
                              }
                            />
                            <p
                              style={{
                                color: setWatchColor(
                                  "madelin",
                                  "non_madelin",
                                  OKWHITE
                                ),
                              }}
                            >
                              Non madelin
                            </p>
                          </Box>
                        </Paper>
                      </Grid>

                      <Grid item xs={6}>
                        <Paper
                          elevation={3}
                          className="paperBox"
                          style={{
                            background: setWatchColor(
                              "madelin",
                              "madelin",
                              OKRED
                            ),
                          }}
                        >
                          <Box className="boxLabel">
                            <FormControlLabel
                              value="madelin"
                              checked={getValues("madelin") === "madelin"}
                              control={
                                <Radio
                                  style={{
                                    color: setWatchColor(
                                      "madelin",
                                      "madelin",
                                      OKWHITE
                                    ),
                                  }}
                                />
                              }
                              ref={register("madelin")}
                              onChange={(e) =>
                                setValue("madelin", e.target.value)
                              }
                            />
                            <p
                              style={{
                                color: setWatchColor(
                                  "madelin",
                                  "madelin",
                                  OKWHITE
                                ),
                              }}
                            >
                              Madelin
                            </p>
                          </Box>
                        </Paper>
                      </Grid>

                      <Grid item xs={12} md={12} >
                        <Typography component="p" variant="h3">
                          Prestations complémentaires
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                      <Paper elevation={3} className="paperBoxSelect">
                    <Controller
                      control={control}
                      rules={{ required: false }}
                      name="produits_independants"
                      render={(field) => (
                        <CommonSelect
                          field={field}
                          label="(Facultatif) Sélectionnez un produit complémenaire"
                          options={filteredProducts}
                          error={errors.produits_independants}
                          required={false}
                        />
                      )}
                    />
                  </Paper>
                   </Grid>
                    </Grid>
                  </RadioGroup>



            

                </Grid>
              ) : (
                <Grid item xs={12} md={6}></Grid>
              )}

              {getValues("contrat") === "collectif" && (
                <Grid item xs={12} md={6}>
                  <RadioGroup name="CCN" row>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Paper
                          elevation={3}
                          className="paperBox"
                          style={{
                            background: setWatchColor(
                              "ccn_mode",
                              "standard",
                              OKRED
                            ),
                          }}
                        >
                          <Box className="boxLabel">
                            <FormControlLabel
                              value="standard"
                              checked={getValues("ccn_mode") === "standard"}
                              control={
                                <Radio
                                  style={{
                                    color: setWatchColor(
                                      "ccn_mode",
                                      "standard",
                                      OKWHITE
                                    ),
                                  }}
                                />
                              }
                              ref={register("ccn_mode")}
                              onChange={(e) =>
                                setValue("ccn_mode", e.target.value)
                              }
                            />
                            <p
                              style={{
                                color: setWatchColor(
                                  "ccn_mode",
                                  "standard",
                                  OKWHITE
                                ),
                              }}
                            >
                              Standard
                            </p>
                          </Box>
                        </Paper>
                      </Grid>
                      <Grid item xs={6}>
                        <Paper
                          elevation={3}
                          className="paperBox"
                          style={{
                            // background: "#11111150"
                            background: setWatchColor("ccn_mode", "ccn", OKRED),
                          }}
                        >
                          <Box className="boxLabel">
                            <FormControlLabel
                              value="ccn"
                              checked={getValues("ccn_mode") === "ccn"}
                              control={
                                <Radio
                                  style={{
                                    color: setWatchColor(
                                      "ccn_mode",
                                      "ccn",
                                      OKWHITE
                                    ),
                                  }}
                                />
                              }
                              ref={register("ccn_mode")}
                              onChange={(e) =>
                                setValue("ccn_mode", e.target.value)
                              }
                            />
                            <p
                              style={{
                                color: setWatchColor(
                                  "ccn_mode",
                                  "ccn",
                                  OKWHITE
                                ),
                              }}
                            >
                              CCN
                            </p>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  <Box className="spacer1" />
                  {getValues("ccn_mode") === "ccn" ? (
                    <>
                      <Paper elevation={3} className="paperBoxSelect">
                        <Controller
                          control={control}
                          name="ccn"
                          defaultValue={getValues("ccn")}
                          render={(field) => (
                            <CommonSelect
                              field={field}
                              label="CCN"
                              options={commercials}
                              error={errors.acts}
                              required={getValues("ccn_mode") === "CCN"}
                            />
                          )}
                        />
                      </Paper>
                      <Typography variant="h5" style={{ marginTop: 10 }}>
                        Pour les autres CCN, merci de{" "}
                        <b
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push("/contacts")}
                        >
                          nous contacter directement.
                        </b>
                      </Typography>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              )}

              {/*  */}
            </Grid>

            {/*  */}
            <Box className="spacer2" />
            <Typography component="p" variant="h3" style={{opacity:"0.8", marginBottom:10}}>
              Adhésion
            </Typography>

            <RadioGroup name="motif">
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    className="paperBox"
                    style={{
                      background: setWatchColor("motif", "adhesion", OKRED),
                    }}
                  >
                    <AiFillFileAdd
                      size={40}
                      fill={setWatchColor("motif", "adhesion", OKWHITE)}
                    />
                    <Box className="boxLabel">
                      <FormControlLabel
                        value="adhesion"
                        checked={getValues("motif") === "adhesion"}
                        control={
                          <Radio
                            style={{
                              color: setWatchColor(
                                "motif",
                                "adhesion",
                                OKWHITE
                              ),
                            }}
                          />
                        }
                        ref={register("motif")}
                        onClick={(e) => setValue("motif", e.target.value)}
                      />
                      <p
                        style={{
                          color: setWatchColor("motif", "adhesion", OKWHITE),
                        }}
                      >
                        Adhesion
                      </p>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    className="paperBox"
                    style={{
                      background: setWatchColor("motif", "avenant", OKRED),
                    }}
                  >
                    <FaUserCircle
                      size={40}
                      fill={setWatchColor("motif", "avenant", OKWHITE)}
                    />
                    <Box className="boxLabel">
                      <FormControlLabel
                        style={{
                          color: setWatchColor("motif", "avenant", OKWHITE),
                          fontSize: "16px",
                        }}
                        value="avenant"
                        checked={getValues("motif") === "avenant"}
                        control={
                          <Radio
                            style={{
                              color: setWatchColor("motif", "avenant", OKWHITE),
                            }}
                          />
                        }
                        ref={register("motif")}
                        onClick={(e) => setValue("motif", e.target.value)}
                      />
                      <p
                        style={{
                          color: setWatchColor("motif", "avenant", OKWHITE),
                        }}
                      >
                        Avenant
                      </p>
                    </Box>
                    {!!name && (
                      <Typography
                        style={{
                          textAlign: "left",
                          color: "white",
                          alignSelf: "start",
                          fontSize: "16px",
                        }}
                      >
                        Adhérent sélectionné: <b>{name}</b>
                      </Typography>
                    )}
                  </Paper>
                  {getValues("motif") === "avenant" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Paper elevation={0}>
                          <Controller
                            control={control}
                            rules={{}}
                            name="adherent"
                            render={(field) => (
                              <CommonTextField
                                field={field}
                                errors={errors.field}
                                label={"Nom de l'adhérent"}
                              />
                            )}
                          />

                          {getValues("adherent")?.trim()?.length > 0 && (
                            <>
                              {quotes?.map((q) => {
                            
                                if (q.type === "subscription") {
                                  if (
                                    q.value
                                      .toLowerCase()
                                      ?.match(
                                        getValues("adherent")
                                          ?.toLowerCase()
                                          ?.trim()
                                      )
                                  ) {
                                    return (
                                      <Typography
                                        variant="body2"
                                        className="hoveredTypography"
                                        onClick={() =>
                                          loadQuoteData(q.id, q.value)
                                        }
                                      >
                                        {q.company ? q.company : q.value}
                                        {q.company
                                          ? ` (${q.city})`
                                          : moment(q.birth_date).format(
                                              " (DD/MM/yyyy)"
                                            )}

                                        {" - "}
                                        {`Contrat n° ${q.numero_devis}${q.number_avenant ? `.${q.number_avenant}` : ""}`}
                                        {moment(q.starting_date).format(
                                          " (DD/MM/yyyy)"
                                        )}
                                      </Typography>
                                    );
                                  }
                                }
                              })}
                            </>
                          )}
                        </Paper>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </RadioGroup>
            {/*  */}
            <Box className="spacer2" />
            <Button
              className={"buttonGeneralStyle"}
              style={{ alignSelf: "center" }}
              type="submit"
            >
              Suivant
            </Button>
          </form>
        </Box>
      )}
    </OAVLayout>
  );
};

export default OAVBesoin;
