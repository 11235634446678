import React from "react";
import { CircularProgress, withStyles} from "@material-ui/core";
import classes from "./LoaderCircular.module.css";

const LoaderCircular = () => {
  const StyledLoader = withStyles({
    root: {
        zIndex: 100,
        display: "block",
        margin: "0 auto"
    },
    colorPrimary: {
      color: "#C31D23",
    },
  })((props) => <CircularProgress {...props} />);
  return (
  <StyledLoader />
  );
};

export default LoaderCircular;
