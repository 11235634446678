export function removeItemByIndex(arr, index) {
  return arr.filter((_, i) => i !== index);
}

export function updateItemByIndex(arr, item, index) {
  const newArr = [...arr];
  newArr[index] = item;
  return newArr;
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function upperCaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function defaultLabelDisplayedRows({ from, to, count }) {
  return `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`;
}

export function getAge(dateString) 
{
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age--;
    }
    return age;
}
