import { useState, useEffect,useCallback } from "react";
import { Box, Typography, Grid, Button, TextField } from "@material-ui/core";

import CommonSelect from "../../../Components/FormComponents/CommonSelect";
import { getQuotes, getZones } from "../../../Axios/Call/ClientCredential";
import CommonDateTimePicker from "../../../Components/FormComponents/CommonDateTimePicker";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  ResponsiveContainer,
  Bar,
  BarChart,
  Tooltip,
  Legend,
  YAxis,
  XAxis,
} from "recharts";
import { Sector } from "recharts";
import moment from "moment";
import "moment/locale/fr";
import { getDpts, getRegions } from "../../../Axios/Call/GouvApi";

import Note from "../../../Components/Dashboard/Note"
import { getAllNotes, upsertNote } from "../../../Axios/Call/ClientCredential"
import RenderEditorHtml from "../../Components/Commons/Editor/RenderEditorHtml";

const COURTIER_SLUG = "note-aux-courtiers"
const INFO_SLUG = "informations-utiles"
const INTERN_NOTES = "note-interne"

const notesList = [COURTIER_SLUG, INFO_SLUG, INTERN_NOTES]

const notesTitles = {
  [COURTIER_SLUG]: "Note aux courtiers",
  [INFO_SLUG]: "Informations utiles",
  [INTERN_NOTES]: "Note interne",
}

const Performances = () => {

  const [notes, setNotes] = useState({})

  const fetchNotes = useCallback(async () => {
    const { data } = await getAllNotes()
    setNotes(data)
  }, [])

  useEffect(() => {
    fetchNotes()
  }, [fetchNotes])

  const getOnSaveNote = useCallback(
    (slug) => async (data) => {
      await upsertNote(slug, data)
      fetchNotes()
    },
    [fetchNotes]
  )

  const [starting_date, setstarting_date] = useState(
    moment().startOf('year')
  );
  const [ending_date, setending_date] = useState(moment().add(3, "month"));
  const [product, setproduct] = useState("all");
  const [market, setmarket] = useState("individual");
  const [broker, setbroker] = useState("all");
  const [zone, setzone] = useState(4);
  const [zones, setZones] = useState([]);
  const [graphData, setgraphData] = useState([]);
  const [pieData, setpieData] = useState([]);
  const [pieData2, setpieData2] = useState([]);
  const [pieData3, setpieData3] = useState([]);
  const [boxData, setboxData] = useState({});
  const [quotes, setquotes] = useState([]); // all the devis/contrats
  const [activeIndex, setactiveIndex] = useState(0);
  const [activeIndex2, setactiveIndex2] = useState(0);
  const [activeIndex3, setactiveIndex3] = useState(0);
  const [dpts, setdpts] = useState([]);
  const [regions, setRegions] = useState([]);

  const [indivQuotes, setIndivQuotes] = useState([]);
  const [collQuotes, setCollQuotes] = useState([]);

  const markets = useSelector((state) => state.user.markets);
  const products = useSelector((state) => state.user.products);
  const testBrokerId = useSelector((state) => state.user.testBrokerId);
  const userData = useSelector((state) => state.user.userData);



  const onKeyDown = (e) => {
    e.preventDefault();
  };

  // const brokers = useSelector((state) => state.user.brokers);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={"#C31D23"}
          style={{ fontWeight: "bold", fontSize: "25px" }}
        >
          {value}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          style={{ fontWeight: "bold", fontSize: "11px" }}
        >
          {payload.name}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#333"
          style={{ fontWeight: 400, fontSize: "14px", marginTop: "5px" }}
        >
          {`${(percent * 100).toFixed(2)}%`}
        </text>
      </g>
    );
  };

  const onPieEnter = (_, index) => setactiveIndex(index);
  const onPieEnter2 = (_, index) => setactiveIndex2(index);
  const onPieEnter3 = (_, index) => setactiveIndex3(index);

  const createGraphStats = (contrats, devis, start, end) => {
    const graph = [];

    function monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    }

    const diff = monthDiff(new Date(start), new Date(end));

    for (let i = 0; i < diff + 1; i++) {
      let tmpcontrat = 0;
      let tmpdevis = 0;

      let tmpstart =
        i === 0
          ? new Date(start)
          : new Date(moment(start).startOf("month").add(i, "month"));
      let tmpend =
        i === diff
          ? new Date(end)
          : new Date(moment(start).endOf("month").add(i, "month"));

      contrats?.map((q) => {
        if (
          new Date(q.created_at) >= tmpstart &&
          new Date(q.created_at) <= tmpend
        ) {
          tmpcontrat += 1;
        }
      });


    

    
      let devisTmp = [];
      devis?.map((q) => {
        if (
          new Date(q.created_at) >= tmpstart &&
          new Date(q.created_at) <= tmpend
        ) {
          devisTmp.push(q);
          //tmpdevis += 1;
        }
      });

      const uniqueQuotes = filterUniqueQuotes(devisTmp);
     
      

      graph.push({
        date: moment(tmpstart).format("MMMM YYYY"),
        Devis: uniqueQuotes,
        Contrats: tmpcontrat,
      });
    }

    setgraphData(graph);
  };

  const calculateNumberOfPerson = (quote) => {
    // que pour les quotes indiv
    let value = 1;
    if (quote?.joint_birth_date) {
      value++;
    }
    value += quote?.childrens?.length || 0;
    return value;
  };

  const calculateTotal = (data, PMSS, effectif) => {
    // que pour les quotes collectives
    let total = 0;
    if (!!data && data !== "000") {
      let formated = data;
      for (const [key, value] of Object?.entries(formated)) {
        total += value;
      }
      return Math.round((total / 100) * PMSS * 12 * effectif);
    } else {
      return total;
    }
  };

  function filterByDates(data, start, end) {
    const tmp = data.filter((q) => {
      if (
        new Date(q.created_at) >= new Date(start) &&
        new Date(q.created_at) <= new Date(end)
      ) {
        return q;
      }
    });
    return tmp;
  }

  function filterByMarket(data, market) {
    switch (market) {
      case "all":
        return data;
      case "individual":
        return data.filter((q) => !q.company);
      case "company":
        return data.filter((q) => !!q.company);
      default:
        break;
    }
  }

  function filterByProduct(data, id) {
    switch (id) {
      case "all":
        return data;
      default:
        return data.filter((q) => q.product_id === product * 1);
    }
  }

  function thisYear() {
    let filteredByNo = quotes;
    var first = new Date(new Date().getFullYear(), 0, 1);
    var last = new Date(new Date().getFullYear(), 11, 31);
    setstarting_date(first);
    setending_date(last);
    filteredByNo = filterByDates(filteredByNo, starting_date, ending_date);
  }

  function thisMonth() {
    let filteredByNo = quotes;
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    setstarting_date(firstDay);
    setending_date(lastDay);
    filteredByNo = filterByDates(filteredByNo, starting_date, ending_date);
  }

  function fromBeginning() {
    let filteredByNo = quotes;
    const result = quotes.reduce((r, o) => o.created_at < r.created_at ? o : r);
    var lastDay = new Date();
    // //console.log(result);
    // //console.log(result.created_at);
    setstarting_date(result.created_at);
    setending_date(lastDay);
    filteredByNo = filterByDates(filteredByNo, starting_date, ending_date);
  }

  function filterByZone(data, id) {
    // //console.log(id, data);
    switch (id) {
      case "all":
        return data;

      default:
        // ici j'ai l'id 1 / 2 / 3
        // avec cet id je peux récupérer la zone et ses caps
        // une fois que j'ai ça, je filter les zip_code préalablement formatés des quote avec les caps
        let res = [];
        zones?.map((z) => {
          if (z.id * 1 === id * 1) {
            data?.map((q) => {
              const charsZoneFull = q.zip_code.split("");
              let zoneCaps; // c'est le caps formaté
              if (charsZoneFull !== undefined) {
                zoneCaps = `${charsZoneFull[0]}${charsZoneFull[1]}`;
                if (zoneCaps === "97") {
                  zoneCaps = `${charsZoneFull[0]}${charsZoneFull[1]}${charsZoneFull[2]}`;
                }
              }
              // //console.log(z.caps, "vs", zoneCaps);
              // //console.log(_.includes(z.caps, zoneCaps));
              // //console.log("******************************");
              if (_.includes(z.caps, zoneCaps)) {
                res.push(q);
              }
            });
          }
        });
        return res;
    }
  }

  function filterByBroker(data, id) {
    switch (id) {
      case "all":
        return data;
      default:
        return data.filter((q) => q.broker_id === id * 1);
    }
  }

  function filterByNoAnswear(data) {
    return data.filter(
      (q) =>
        q.devis_status !== "no-answear" && q.contrat_status !== "no-answear"
    );
  }

  function findOnGoingQuotes(data) {
    //DEBUG
    // return data?.filter((q) => q.signed === "0" || q.signed === 0);
    // //console.log(data);
    return data.filter((q) => q.type === "quote" || q.signed === 1);
  }

  function findOnGoingContrat(data) {
    //DEBUG
    // return data?.filter((q) => q.signed === "1" || q.signed === 1);
    // //console.log(data);
    return data.filter((q) => q.type === "subscription" && (q.contract_status === "signed" || q.contract_status === "managment"));
  }

  const getSignedQuotes = (data) => {
    return data.filter(q => q.signed === 1);
  };

  const getSignedQuotesPercent = (quotes, signedQuotes) => {

    let conversion = 0;

    const uniqueNames = [...new Set(quotes.map(item => (item.last_name + item.firt_name + item.zip_code)))]; 
    const uniqueCompany = [...new Set(quotes.map(item => (item.company + item.zip_code)))]; 

    const adhesionIndiv = [...new Set(quotes.filter(q => q.type === "subscription").filter((q) => !q.company))]; 
    const adhesionColl = [...new Set(quotes.filter(q => q.type === "subscription").filter((q) => q.company))]; 
    
    if (market==='individual'){
      conversion = adhesionIndiv.length / uniqueNames.length;
    }
    else if (market==='company') {
      conversion = adhesionColl.length / uniqueCompany.length;
    }
   
    conversion = (conversion * 100).toFixed(1);

    if (isNaN(conversion) || conversion == 0) {
      return 0;
    } else {
      return conversion;
    }
  };

  // TO GET NUMBER OF UNIQUE QUOTES
  const filterUniqueQuotes = (quotes) => {

    let uniqueNames = [];
    let uniqueCompany = [];

 if (market==='individual'){
    uniqueNames = [...new Set(quotes?.map(item => (item.last_name + item.firt_name + item.zip_code)))]; 
    return uniqueNames.length; 
 }
 else if (market==='company') {
    uniqueCompany = [...new Set(quotes?.map(item => (item.company + item.zip_code)))]; 
    return uniqueCompany.length; 
 }

  }




  useEffect(() => {
    getQuotes().then((res) => {
      //Filter test account
      setquotes(res.data.filter(data => data.broker_id === userData.id));
    }).catch(error => console.log(error));
    getDpts().then((res) => setdpts(res.data));
    getRegions().then((res) => setRegions(res.data));
    getZones().then((res) => setZones(res.data));
  }, []);




// TO GET ONLY USER DATA
  useEffect(() => {
    getQuotes().then((res) => {
      let data = res.data.filter(data => data.broker_id === userData.id);
      setquotes(data);
    });
    getDpts().then((res) => setdpts(res.data));
    getRegions().then((res) => setRegions(res.data));
  }, [userData]);



  useEffect(() => {
    // quand une des valeurs de filtre change -----------------------------------
    // alors je filtre avec tous les filtres ------------------------------------
    let data = quotes;
    let filteredByNo = quotes;

    //DEBUG
    // let filteredByNo = filterByNoAnswear(data);
    // //console.log("par no-answear", filteredByNo);

    filteredByNo = filterByDates(filteredByNo, starting_date, ending_date);

    filteredByNo = filterByBroker(filteredByNo, broker);

    filteredByNo = filterByMarket(filteredByNo, market);

    filteredByNo = filterByProduct(filteredByNo, product);

    filteredByNo = filterByZone(filteredByNo, zone);

    // puis je compute ----------------------------------------------------------

    // => all filtré
    let X = filterByProduct(
      filterByMarket(filterByDates(data, starting_date, ending_date), market),
      product
    );
    // => all signé = 1
    let Y = findOnGoingContrat(filteredByNo);

    // => le nombre de personnes et le prix

    let onGoingQuotes = findOnGoingQuotes(filteredByNo);
    let onGoingContrat = findOnGoingContrat(filteredByNo);
    let signedQuotes = getSignedQuotes(filteredByNo);


    let signedQuotesPercent = getSignedQuotesPercent(onGoingQuotes, onGoingContrat);

    let indivPP = 0;
    let collecPP = 0;

    let indivQuotePrice = 0;
    let indivContratPrice = 0;

    let collecQuotePrice = 0;
    let collecContratPrice = 0;

    // =======> pour les indivs
    // ===========> quotes
    filterByMarket(onGoingQuotes, "individual")?.forEach((q) => {
      !!q.total_price
        ? (indivQuotePrice += q?.total_price * 1 * 12)
        : (indivQuotePrice += 0);
    });

    // ===========> contrat
    filterByMarket(onGoingContrat, "individual")?.forEach((q) => {
      indivPP += calculateNumberOfPerson(q);
      indivContratPrice += q?.total_price * 1 * 12;
    });

    // =======> pour les companies
    // ===========> quotes
    filterByMarket(onGoingQuotes, "company")?.forEach((q) => {
      !!q.meta
        ? (collecQuotePrice += calculateTotal(q?.meta, 3428, q?.effectif))
        : (collecQuotePrice += 0);
    });

    // ===========> contrat
    filterByMarket(onGoingContrat, "company")?.forEach((q) => {
      collecPP += q?.effectif;
      collecContratPrice += calculateTotal(q?.meta, 3428, q?.effectif);
    });

    // => GRAPH FUNCTIONS

    createGraphStats(onGoingContrat, onGoingQuotes, starting_date, ending_date);

    // je dois récup le nb de produit dans les quotes

    let pie1 = products
      ?.map((p) => {
        let res = { name: p.description, value: 0 };
        filteredByNo.forEach((q) => {
          if (q.product_id === p.id) {
            return (res.value += 1);
          }
        });
        return res;
      })
      ?.filter((p) => p.value !== 0);
    setpieData(pie1);

    let pie2 = regions
      ?.map((r) => {
        let res = { name: r.nom, value: 0 };
        dpts?.map((d) => {
          if (d.codeRegion === r.code) {
            filteredByNo.map((q) => {
              const charsZoneFull = q.zip_code.split("");
              let zoneCaps;
              if (charsZoneFull !== undefined) {
                zoneCaps = `${charsZoneFull[0]}${charsZoneFull[1]}`;
                if (zoneCaps === "97") {
                  zoneCaps = `${charsZoneFull[0]}${charsZoneFull[1]}${charsZoneFull[2]}`;
                }
              }
              if (d.code === zoneCaps) {
                return (res.value += 1);
              }
            });
          }
        });
        return res;
      })
      .filter((p) => p.value !== 0);
    setpieData2(pie2);

    

    // puis j'affiche -----------------------------------------------------------

    // //console.log(data)
    setboxData({
      pp: indivPP + collecPP,
      ttlQuotes: indivQuotePrice,
      ttlContrats: indivContratPrice,
      numberQuotes: filterUniqueQuotes(onGoingQuotes),
      numberContrats: onGoingContrat.length,
      numberIndivQuotes: indivQuotes.length,
      numberCollQuotes: collQuotes.length,

      // tt: (100 - ((X.length - Y.length) / X.length) * 100).toFixed(2),
      tt: signedQuotesPercent
    });
  }, [
    products,
    quotes,
    market,
    product,
    starting_date,
    ending_date,
    broker,
    zone,
  ]);


  return (

    <div>




      <Box className="spacer2" />

      {/* colored blocks */}

      <Grid container spacing={1}>


        <Grid item xs={12} md={2}>
          <Grid container

            style={{
              backgroundColor: "#C31D23",
              borderRadius: "5px",
              padding: "1rem 1rem",


            }}
          >
            <Typography style={{ color: "white", fontSize: "32px", width: "100%", textAlign: "left" }} variant='h3'>
              {boxData?.pp}
            </Typography>
            <Typography
              style={{
                color: "#EEE",
                fontSize: "16px",


              }}
            >
              Personnes protégées
            </Typography>

          </Grid>
        </Grid>


        <Grid item xs={12} md={2}>
          <Grid container
            style={{
              backgroundColor: "#4B8E4A",
              borderRadius: "5px",
              padding: "1rem 1rem",

            }}
          >

            <Typography style={{ color: "white", fontSize: "32px", width: "100%", textAlign: "left" }} variant='h3'>
            {(boxData.numberContrats / boxData?.numberQuotes * 100).toFixed(1)}%

            </Typography>
            <Typography
              style={{
                color: "white",
                fontSize: "16px",

              }}
            >
              Transformation
            </Typography>

          </Grid>

        </Grid>




        <Grid item xs={12} md={2}>
          <Grid container
            style={{
              backgroundColor: "#3B4A66",
              borderRadius: "5px",
              padding: "1rem 1rem",
            }}
          >

            <Typography style={{ color: "white", fontSize: "32px", width: "100%", textAlign: "left" }} variant='h3'>
              {boxData.numberQuotes}
            </Typography>

            <Typography
              style={{
                color: "white",
                fontSize: "16px",
              }}>
              Devis réalisés
            </Typography>

          </Grid>
        </Grid>



        {/* --- */}

        <Grid item xs={12} md={2}>
          <Grid container
            style={{
              backgroundColor: "#3B4A66",
              borderRadius: "5px",
              padding: "1rem 1rem",

            }}
          >   <Typography style={{ color: "white", fontSize: "32px", width: "100%", textAlign: "left" }} variant='h3'>
              {market === "company" ? "-- " : Math.round(boxData?.ttlQuotes).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€
            </Typography>
            <Typography
              style={{
                color: "white",
                fontSize: "16px",
              }}
            >
              Montant des devis
            </Typography>

          </Grid>
        </Grid>

        <Grid item xs={12} md={2} >

          <Grid container
            style={{
              backgroundColor: "#C31D23",
              borderRadius: "5px",
              padding: "1rem 1rem",
            }}
          >

            <Typography style={{ color: "white", fontSize: "32px", width: "100%", textAlign: "left" }} variant='h2'>
              {boxData.numberContrats}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontSize: "16px",

              }}
            >
              Adhésions
            </Typography>

          </Grid>
        </Grid>
        <Grid item xs={12} md={2}>
          <Grid container
            style={{
              backgroundColor: "#C31D23",
              borderRadius: "5px",
              padding: "1rem 1rem",


            }}
          >
            <Typography style={{ color: "white", fontSize: "32px", width: "100%", textAlign: "left" }} variant='h3'>
              {market === "company" ? "-- " : Math.round(boxData?.ttlContrats).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€
            </Typography>

            <Typography
              style={{
                color: "white",
                fontSize: "16px",

              }}
            >
              Contrats signés
            </Typography>

          </Grid>
        </Grid>

      </Grid>

      <Box className="spacer1" />




    
      {/* ****************** GRAPHS *********************** */}
      <Grid container spacing={2}>





        <Grid item xs={12} md={2} style={{ marginTop: 10 }}>
          <Grid item xs={12} md={12} lg={12} style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", padding: 30, borderRadius: 5 }}>
            <Grid container spacing={1} >


              <Grid item xs={12}>
                <Typography variant='h3' style={{ textAlign: 'left' }}>Filtres</Typography>
              </Grid>

              <Grid item xs={12}>
                <CommonSelect

                  label="Marché"
                  name="market"
                  defaultValue={"individual"}
                  field={{ value: market, onChange: setmarket }}
                  options={[...markets]}
                />
              </Grid>
              <Grid item xs={12}>
                <CommonSelect
                  //label="Secteur"
                  name="zone"
                  field={{ value: zone, onChange: setzone }}
                  defaultValue={4}
                  options={[
                    ...zones.map((zone) => {
                      return { id: zone.id, value: zone.code };
                    }),
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <CommonSelect
                  label={"Produits"}
                  name="market"
                  defaultValue={[]}
                  field={{ value: product, onChange: setproduct }}
                  options={[
                    { id: "all", value: "Tous les produits" },
                    ...products.map((product) => {
                      return { id: product.id, value: product.description };
                    }),
                  ]}
                />
              </Grid>
             
            </Grid>
          </Grid>
        </Grid>


        <Grid item xs={8} md={7} xl={8} style={{ marginTop: 30 }}>
          <ResponsiveContainer height={450}>
            <BarChart
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              data={graphData}
            >
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Devis" stackId="a" fill="rgb(40, 57, 90)" />
              <Bar dataKey="Contrats" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>


        <Grid item xs={12} md={3} xl={2}>

          <Grid container spacing={2}>
            <Grid item xs={12} style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", padding: 30, borderRadius: 5, margin: 15 }}>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h3' style={{ textAlign: 'left' }}>Période</Typography>
                </Grid>


                <Grid item xs={12} style={{ textAlign: 'left' }}>
                  <CommonDateTimePicker
                    label={"Début"}
                    name="starting_date"
                    field={{ value: starting_date, onChange: setstarting_date }}
                 
                  />
                </Grid>
                <Grid item xs={12}>
                  <CommonDateTimePicker
                    label={"Fin"}
                    name="ending_date"
                    field={{ value: ending_date, onChange: setending_date }}
                  />
                </Grid>


                <Grid container alignItems='center' justifyContent='center' style={{ borderRadius:5, marginTop:10}}>
              <Button
  
                type="submit"
                style={{ alignSelf: "center",border:'solid 1px #999', margin:10 }}
                onClick={() => thisMonth()}
              >
                Mois
              </Button>
      
  
              <Button

      
                type="submit"
                style={{ alignSelf: "center",border:'solid 1px #999', margin:10 }}
                onClick={() => thisYear()}
              >
                Année
              </Button>
          
              <Button
                type="submit"
                style={{ alignSelf: "center",border:'solid 1px #999', margin:10 }}
                onClick={() => fromBeginning()}
              >
                Début
              </Button>
    </Grid>               
              </Grid>

            </Grid>


          </Grid>

        </Grid>

        <Grid container justifyContent={'center'} >
      {notesList.map(note => (
          <Grid item md={11} xs={12} xl={11} key={note} >
            {note!==INTERN_NOTES && <RenderEditorHtml title={notesTitles[note]} html_text={notes[note]?.text}/>}
          </Grid>
      ))}


    </Grid>


      </Grid>

    </div>

  );
};


export default Performances;
