import React, { useEffect, useState } from "react";
import CustomHeader from "../../Components/Header/CustomHeader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  InputAdornment,
  TextField,
  Box,
} from "@material-ui/core";
import { BsSearch } from "react-icons/bs";
import {
  getAllBrokerDocs,
  getBrokerDoc,
  showDocument,
} from "../../../Axios/Call/ClientCredential";
import CustomModal from "../../../Components/Commons/Modal/CustomModal";
import moment from "moment";
import PDFViewer from "../../Components/Commons/FileHandler/PDFViewer";

const DocumentsGeneric = () => {
  const [searchValue, setsearchValue] = useState("");
  const [rows, setrows] = useState([]);
  const [open, setopen] = useState(false);
  const [file, setfile] = useState(null);

  const watchFile = (path) => {
    setopen(true);
    showDocument(path)
      .then((res) => {
        setfile(res.data);
      })
      .catch((error) => console.warn(error));
  };

  const getFile = (id) => {
    getBrokerDoc(id)
      .then((res) => {
        setfile(res.data);
      })
      .catch((error) => console.warn(error));
  };

  const handleSearchValue = (val) => {
    setsearchValue(val);
  };

  const createData = (
    name,
    description,
    validity_start,
    validity_end,
    file_path,
    id
  ) => {
    return { name, description, validity_start, validity_end, file_path, id };
  };

  useEffect(() => {
    getAllBrokerDocs().then((res) => {
      setrows(
        res.data
          .filter((d) => d.type === "general")
          ?.map((d) =>
            createData(
              d.name,
              d.description,
              d.starting_date,
              d.ending_date,
              d.file_path,
              d.id
            )
          )
      );
    });
  }, []);

  return (
    <>
      <Box style={{ display: "flex" }}>
        <TextField
          variant="outlined"
          size="small"
          style={{ width: "300px", margin: "1rem 0" }}
          placeholder="Rechercher par nom, date..."
          value={searchValue}
          onChange={(e) => handleSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsSearch size={20} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="h6">Nom du document</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">Description</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">Début de validité</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">Fin de validité</Typography>
              </TableCell>
              <TableCell align="left" />
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.filter(
                (row) =>
                  row.name?.toLowerCase().match(searchValue.toLowerCase().trim()) ||
                  row.description?.toLowerCase().match(searchValue.toLowerCase().trim())
              )
              ?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="left">
                    <Typography variant="body3">{row.name}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body4">{row.description}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body4">
                      {row.validity_start !== null
                        ? moment(row.validity_start).format("DD/MM/yyyy")
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body4">
                      {row.validity_end !== null
                        ? moment(row.validity_end).format("DD/MM/yyyy")
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      className={"buttonGeneralStyleSmall"}
                      onClick={() => watchFile(row.id)}
                    >
                      VOIR
                    </Button>

                    {/*<Button*/}
                    {/*  onClick={() => getFile(row.id)}*/}
                    {/*>*/}
                    {/*  Télécharger*/}
                    {/*</Button>*/}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomModal open={open} handleClose={() => setopen(false)}>
        {file?.name}
        <PDFViewer file={file} />
      </CustomModal>
    </>
  );
};

export default DocumentsGeneric;
