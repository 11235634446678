
import CustomHeader from "../../Components/Header/CustomHeader";
import { AiOutlineFileText } from "react-icons/ai";
import { Grid } from "@material-ui/core";

function Gestion() {
  return (
    <>
    <CustomHeader
      icon={
        <AiOutlineFileText
          size={40}
          style={{ fill: "rgb(195, 29, 35)", marginRight: ".5rem" }}
        />
      }
      pageName={'Gestion'}
    />


<Grid container height='100vh' style={{padding:30}}>
  <iframe width='1920px' height='100%' src="monespacepersonnel.cimut.net/web/mba-alm" style={{borderRadius:5, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", border: 'solid 0px #FFF'}}></iframe>
</Grid>

  </>
  )
}

export default Gestion