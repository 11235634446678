import React from "react";

import {
  createTheme,
  MuiThemeProvider,
  CssBaseline,
  Backdrop,
  Fade,
  Modal,
  makeStyles,
} from "@material-ui/core";
import LoaderCircular from "../Loading/LoaderCircular";

const themeLight = createTheme({
  palette: {
    backgroundColor: {
      default: "rgba(40, 57, 90, 0.59)",
    },
  },
});

const useStyles = makeStyles({
  modal: {
    padding: "24px 6px 24px 6px",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  styledbackdrop: {
    backgroundColor: "rgba(40, 57, 90, 0.59)",
    opacity: "1 !important",
    visibility: "initial !important",
  },
  container: {
    padding: "24px",
    background: "#ffffff",
    borderRadius: "18px",
    margin: "3rem auto",
    position: "relative",
  },
});

const CustomModal = ({ children, handleClose, open, loading, size }) => {
  // TODO: regarder si je peux le mettre au dessus
  //   const [open, setOpen] = useState(false);

  const styles = useStyles();

  //   functions
  //   const returnHandleClose = () => {
  //     return false
  //   };
  //   const returnHandleOpen = () => {
  //     return true
  //   };

  const StyledBackdrop = ({ children }) => {
    return (
      <MuiThemeProvider theme={themeLight}>
        <CssBaseline />
        <Backdrop className={styles.styledbackdrop} onClick={handleClose}>
          {children}
        </Backdrop>
      </MuiThemeProvider>
    );
  };

  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 100,
      }}
    >
      <Fade in={open}>
        <div className={styles.container} style={{ maxWidth: size || "840px", }} >
          {loading ? <LoaderCircular /> : <>{children}</>}
        </div>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
