import axios from "axios";

const api = process.env.REACT_APP_API_URL ?? "https://mba-api.vigee.fr/api";
// const api = process.env.REACT_APP_API_URL ?? "https://api.admin.mbamutuelle.com/api";

export const clientCredentialAxios = axios.create({
  baseURL: (axios.defaults.baseURL = api),
});

export const authApiAxios = axios.create({
  baseURL: (axios.defaults.baseURL = api),
});

export const authGouvAxios = axios.create({
  baseURL: (axios.defaults.baseURL = "https://geo.api.gouv.fr/"),
});

authApiAxios.defaults.headers.common["Authorization"] =
  localStorage.getItem("token");
clientCredentialAxios.defaults.headers.common["Authorization"] =
  localStorage.getItem("token");

export const initAxios = () => {
  authApiAxios.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");
  clientCredentialAxios.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");
};

if (process.env.NODE_ENV === "development") {
  if (document.cookie.includes("XDEBUG_SESSION")) {
    authApiAxios.interceptors.request.use((config) => {
      config.params = {
        ...config.params,
        XDEBUG_SESSION: "PHPSTORM",
      };
      return config;
    });

    clientCredentialAxios.interceptors.request.use((config) => {
      config.params = {
        ...config.params,
        XDEBUG_SESSION: "PHPSTORM",
      };
      return config;
    });
  }
}
