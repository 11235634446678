import React, { useEffect } from "react";
//--------- Others ------------------
import * as actionCreator from "../Store/action/index";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const LayoutNotLogin = ({ children, page }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("token")) {
     // //console.log(" ***************** populate *****************");
      dispatch(actionCreator.populateUserData());
      dispatch(actionCreator.populateGaranties());
      dispatch(actionCreator.populateProducts());
      dispatch(actionCreator.populatePacks());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData?.status === "active") {
        // if (userData?.email_verified_at) {
          if (userData.type === "broker" || userData.type === "admin") {
          }
        // } else {
        //   dispatch(actionCreator.cleanUserData());
        // }
      } else {
        dispatch(actionCreator.cleanUserData());
      }
    }
  }, [userData]);

  return <>{children}</>;
};

export default LayoutNotLogin;
