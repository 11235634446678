import React, { useEffect, useState } from "react";
import CustomHeader from "../../Components/Header/CustomHeader";
import { useParams, useHistory } from "react-router-dom";
import { getQuote } from "../../../Axios/Call/ClientCredential";
import { useForm, Controller, useFieldArray, get } from "react-hook-form";

import {
  Grid,
  Typography,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import CommonDateTimePicker from "../../../Components/FormComponents/CommonDateTimePicker";
import CommonTextField from "../../../Components/FormComponents/CommonTextField";
import CommonSelect from "../../../Components/FormComponents/CommonSelect";
import { useSelector } from "react-redux";
import Back from "../../../Components/Commons/Back/Back";
import _ from "lodash";
import { upperCaseFirst } from "../../../utils/utils";
import {
  BsFillCheckSquareFill,
  BsFillCalendar2CheckFill,
} from "react-icons/bs";
import { ImCheckboxUnchecked } from "react-icons/im";
import { MdHealthAndSafety } from "react-icons/md";
import { GiHealthIncrease } from "react-icons/gi";
import { BiPackage } from "react-icons/bi";
import { AiOutlineEuroCircle, AiOutlineCalendar } from "react-icons/ai";
import QuoteDocuments from "../../../Components/QuoteDocuments/QuoteDocuments";
import { MdPersonOutline } from "react-icons/md";
import { authApiAxios as axios } from "../../../Axios/AxiosInstance";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import moment from "moment";
import { TextField } from "@material-ui/core";

const ContratIndividuelSingle = () => {
  const [taux, setTaux] = useState(0);
  const params = useParams();
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [names, setNames] = useState({
    product: "Inconnu",
    pack: "Inconnu",
    garanties: [],
  });
  const [price, setPrice] = useState(0);
  const [accept, setaccept] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewLoading, setPreviewLoading] = useState(true);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [successapi, setSuccessapi] = useState(false);
  const [numberQuote, setNumberQuote] = useState("");

  // accepte de recevoir les informations sur les contrats MBA Mutuelle

  const packs = useSelector((state) => state.user.packs);
  const garanties = useSelector((state) => state.user.garanties);
  const produits = useSelector((state) => state.user.products);
  const modalites = useSelector((state) => state.user.modalites);
  const civilites = useSelector((state) => state.user.civilites);
  const id_type = useSelector((state) => state.user.id_type);
  const periodicites = useSelector((state) => state.user.periodicites);
  const starting_date = useSelector((state) => state.user.starting_date);

  const dates_prelevement = useSelector(
    (state) => state.user.dates_prelevement
  );
  const regimes = useSelector((state) => state.user.regimes);

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "all",
    shouldDirty: true,
    defaultValues: {
      starting_date: null,
      civilite: "",
      firt_name: "",
      last_name: "",
      birth_date: null,
      birth_name: "",
      ssn: "",
      joint_first_name: "",
      joint_last_name: "",
      joint_birth_date: null,
      joint_regime: "",
      joint_ssn: "",
      childrens: [],
      id_type: "",
      id_number: "",
      delivery_date: null,
      delivery_city: "",
      address: "",
      zip_code: "",
      city: "",
      email: "",
      phone: "",
      bank_account_owner: "",
      iban_payment: "",
      periodicite: "",
      bic_payment: "",
      modalite: "",
      info_contact: false,
    },
  });

  useEffect(() => {
    let nameProduct = "...";
    let idPro = data?.product_id;
    nameProduct = produits?.find((p) => idPro === p.id)?.description;
    // -------------
    let namePack = "...";
    if (_.some(data.documents, "package_id")) {
      namePack = packs?.find(
        (p) => p.id === data.documents?.find((e) => e)?.package_id
      )?.name;
    } else if (_.some(data.packages, "package_id")) {
      namePack = packs?.find(
        (p) => p.id === data.packages.find((e) => e)?.package_id
      )?.name;
    }
    // -----------
    let ids = []; // lsite des ID des garanties du client
    let listGaranties = [];
    if (_.some(data.documents, "package_id")) {
      ids = data.documents?.map((p) => p.guarantee_id);
    } else if (_.some(data.packages, "package_id")) {
      ids = data.packages?.map((p) => p.guarantee_id);
    }
    garanties?.forEach((g) => {
      ids?.forEach((id) => {
        if (id === g.id) {
          listGaranties.push({
            id: g.id,
            name: g.name,
            nature: g.nature,
            description: g.description,
          });
        }
      });
    });
    setNames({
      product: nameProduct,
      pack: namePack,
      garanties: listGaranties,
    });
  }, [produits, data, packs, garanties]);

  const { fields: childrens } = useFieldArray({
    control,
    name: "childrens",
  });

  const fetchQuote = () => {
    //console.log(params.id);
    getQuote(params.id).then((res) => {
      //console.log("data =>", res.data);
      const tmp = res.data;
      setNumberQuote(
        tmp.number_avenant
          ? tmp.numero_devis + "." + tmp.number_avenant + " (Avenant)"
          : tmp.numero_devis
      );
      setdata(tmp);
      setaccept(tmp.info_contact === "1" ? true : false);
      setPrice(res?.data?.total_price.toFixed(2));
      setValue("civilite", res.data.civilite);
      setValue("firt_name", res.data.firt_name);
      setValue("last_name", res.data.last_name);
      setValue("ssn", res.data.ssn);
      setValue("birth_date", res.data.birth_date);
      setValue("birth_name", res.data.birth_name);
      setValue("joint_regime", res.data.joint_regime);
      setValue("joint_birth_date", res.data.joint_birth_date);
      setValue("joint_ssn", res.data.joint_ssn);
      setValue("joint_first_name", res.data.joint_first_name);
      setValue("joint_last_name", res.data.joint_last_name);
      setValue("id_type", res.data.id_type);
      setValue("id_number", res.data.id_number);
      setValue("delivery_date", res.data.delivery_date);
      setValue("delivery_city", res.data.delivery_city);
      setValue("address", res.data.address);
      setValue("zip_code", res.data.zip_code);
      setValue("city", res.data.city);
      setValue("phone", res.data.phone);
      setValue("email", res.data.email);
      setValue("bank_account_owner", res.data.bank_account_owner_payment);
      setValue("iban_payment", res.data.iban_payment);
      setValue("bic_payment", res.data.bic_payment);
      setValue("periodicite", res.data.periodicite);
      setValue("modalite", res.data.modalite);
      setValue("date_prelevement", res.data.date_prelevement);
      setValue("starting_date", res.data.starting_date);
      setTaux(res.data.taux);

      if (Array.isArray(res.data.childrens)) {
        setValue(
          "childrens",
          res.data.childrens.map((child) => ({
            name: child.name,
            surname: child.surname,
            ssn: child.ssn,
            birth_date: child.birth_date,
            regime: child.regime,
          }))
        );
      }
    });
  };

  useEffect(fetchQuote, [setValue, params.id]);

  useEffect(() => {
    setValue("bank_account_owner", data.bank_account_owner_payment);
    setValue("iban_payment", data.iban_payment);
    setValue("bic_payment", data.bic_payment);
    setValue("periodicite", data.periodicite);
    setValue("date_prelevement", data.date_prelevement);
  }, [watch("modalite")]);

  const handleGetPreview = async (data) => {
    setPreviewOpen(true);
    const response = await axios.post(
      `subscription/${params.id}/preview`,
      data
    );
    setPreviewUrl(response.data.url);
  };

  const handlePatch = async () => {
    setPreviewLoading(true);
    const data = getValues();
    await axios.patch(`subscription/${params.id}`, data);
    fetchQuote();
    handleClosePreview();
    setSuccessapi(true);
  };

  const handleClosePreview = () => {
    setPreviewUrl(null);
    setPreviewLoading(true);
    setPreviewOpen(false);
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <div style={{ paddingTop: 10 }}>
      {successapi && (
        <AlertGeneral
          setTrigger={setSuccessapi}
          color=""
          description="Contrat enregistré"
        />
      )}

      <Dialog
        open={previewOpen}
        onClose={handleClosePreview}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Prévisualisation</DialogTitle>
        <DialogContent>
          {(previewLoading || !previewUrl) && (
            <div
              style={{
                minHeight: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {previewUrl && (
            <iframe
              src={previewUrl}
              title="Prévisualisation"
              style={{
                minHeight: "80vh",
                width: "100%",
                display: previewLoading ? "none" : "block",
              }}
              onLoad={() => setPreviewLoading(false)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview}>Fermer</Button>
          <Button
            disabled={previewLoading}
            onClick={handlePatch}
            color="primary"
            variant="contained"
          >
            Enregistrer et renvoyer
          </Button>
        </DialogActions>
      </Dialog>

      <CustomHeader
        icon={<Back />}
        pageName={`Contrat ${numberQuote}`}
        userName={"Username here"}
      />

      <Box className="spacer2" />

      {/* infos & link */}

      {/* HERE */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} className="containerSingle">
          {" "}
          <MdHealthAndSafety
            size={30}
            style={{ fill: "#FFF", marginRight: ".5rem" }}
          />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
          >
            Produit
            <Typography
              variant="h6"
              style={{ color: "white", textAlign: "center" }}
            >
              <strong>{names.product}</strong>
            </Typography>{" "}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} className="containerSingle">
          <GiHealthIncrease
            size={30}
            style={{ fill: "#FFF", marginRight: ".5rem" }}
          />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
          >
            Garanties
            <br />
            {names.garanties?.map((g) => {
              switch (g.nature) {
                case "base":
                  return (
                    <Typography
                      variant="h6"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      {upperCaseFirst(g.nature)} :{" "}
                      <strong>{g.description}</strong>
                    </Typography>
                  );

                case "option":
                  return (
                    <Typography
                      variant="h6"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      {upperCaseFirst(g.nature)} :{" "}
                      <strong>{g.description}</strong>
                    </Typography>
                  );

                case "supp":
                  return (
                    <Typography
                      variant="h6"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      {upperCaseFirst("Produit complémentaire")} :{" "}
                      <strong>{g.description}</strong>
                    </Typography>
                  );

                default:
                  return (
                    <Typography
                      variant="h3"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      ...
                    </Typography>
                  );
              }
            })}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          className="containerSingle"
          style={{ backgroundColor: "#1278B2" }}
        >
          <AiOutlineEuroCircle
            size={30}
            style={{ fill: "#FFF", marginRight: ".5rem" }}
          />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
            direction="column"
            alignItems="center"
            justify="center"
          >
            Cotisation
            <br />
            <strong>{price}</strong>€ /mois
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={1}
          className="containerSingle"
          style={{ backgroundColor: "#000", maxHeight: "120px" }}
        >
          <AiOutlineEuroCircle
            size={30}
            style={{ fill: "#FFF", marginRight: ".5rem" }}
          />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
            direction="column"
            alignItems="center"
            justify="center"
          >
            Taux
            <br />
            <strong>{taux}</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} lg={5}>
          {data && (
            <QuoteDocuments quote={data} onDocumentUploaded={fetchQuote} />
          )}

          {/*
          <Grid item xs={12} md={12}>
          <Button
              variant="h4"
              style={{
                marginTop: 50,
                backgroundColor: "#C31D23",
                color: "#FFF",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/Souscription/Pieces/${data.uid}`)}
            >
              Lien vers les documents personnels
            </Button>
          </Grid>
*/}
        </Grid>

        {/* form */}
        <Grid item xs={12} lg={6} style={{ paddingLeft: 60, paddingRight: 60 }}>
          {" "}
          <form
            style={{ marginTop: 2, textAlign: "left" }}
            noValidate
            onSubmit={handleSubmit(handleGetPreview)}
          >
            <Typography
              variant="h1"
              style={{ marginTop: 20, textAlign: "left" }}
            >
              <AiOutlineCalendar
                size={30}
                style={{ marginRight: 10, marginBottom: "-5px" }}
              />
              Date d'effet
            </Typography>

            <Grid item xs={6} md={4}>
              <Controller
                name={"starting_date"}
                control={control}
                render={(field) => (
                  <CommonDateTimePicker
                    field={field}
                    name={"starting_date"}
                    error={errors.birth_date}
                    label={"Date d'effet"}
                    maxDate={moment(getValues("starting_date")).endOf("year")}
                    //disabled={true}
                    readOnly={true}
                    renderInput={(params) => (
                      <TextField onKeyDown={onKeyDown} {...params} />
                    )}
                  />
                )}
              />
            </Grid>

            <Typography
              variant="h1"
              style={{ marginTop: 20, textAlign: "left" }}
            >
              <MdPersonOutline
                size={30}
                style={{ marginRight: 10, marginBottom: "-5px" }}
              />
              Informations
            </Typography>
            <Box className="spacer2" />

            <Grid container spacing={2}>
              <Grid item xs={2} md={2}>
                <Controller
                  control={control}
                  name="civilite"
                  rules={{ required: true }}
                  render={(field) => {
                    return (
                      <CommonSelect
                        field={field}
                        error={errors.civilite}
                        label={"Civilité"}
                        options={civilites}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={5} md={3}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="last_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Nom"
                        error={errors.last_name}
                        color={"black"}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={5} md={3}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="firt_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Prénom"
                        error={errors.firt_name}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={6} md={4}>
                <Controller
                  name={"birth_date"}
                  control={control}
                  render={(field) => (
                    <CommonDateTimePicker
                      field={field}
                      name={"birth_date"}
                      error={errors.birth_date}
                      label={"Date de naissance"}
                      disabled={true}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <Controller
                  control={control}
                  rules={{}}
                  name="birth_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Nom de naissance"
                        error={errors.birth_name}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={9}>
                <Controller
                  control={control}
                  rules={{}}
                  name="ssn"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type="number"
                        field={field}
                        label="Numéro de sécurité sociale"
                        error={errors.ssn}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Box className="spacer2" />
            <Typography variant="h4">Pièce d'identité</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="id_type"
                  rules={{ required: true }}
                  render={(field) => {
                    return (
                      <CommonSelect
                        field={field}
                        error={errors.id_type}
                        label={"Type de pièce"}
                        options={id_type}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  rules={{ valueAsNumber: true }}
                  name="id_number"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"text"}
                        field={field}
                        label="Numéro de pièce d'identité"
                        error={errors.id_number}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name={"delivery_date"}
                  control={control}
                  rules={{ required: true }}
                  render={(field) => (
                    <CommonDateTimePicker
                      field={field}
                      label={"Délivrée le"}
                      error={errors.delivery_date}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="delivery_city"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Délivré à"
                        error={errors.delivery_city}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Box className="spacer2" />

            {data?.joint_birth_date && (
              <>
                <Typography variant="h4">
                  Conjoint
                  {"  "}{" "}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="joint_first_name"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="Prénom"
                            error={errors.firt_name}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="joint_last_name"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="Nom"
                            error={errors.joint_last_name}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Controller
                      name={"joint_birth_date"}
                      control={control}
                      rules={{}}
                      render={(field) => (
                        <CommonDateTimePicker
                          field={field}
                          name={"joint_birth_date"}
                          label={"Date de naissance"}
                          error={errors.joint_birth_date}
                          required={true}
                          disabled
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="joint_regime"
                      render={(field) => (
                        <CommonSelect
                          label="Régime"
                          field={field}
                          options={regimes}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      rules={{}}
                      name="joint_ssn"
                      render={(field) => {
                        return (
                          <CommonTextField
                            type="number"
                            field={field}
                            label="Numéro de sécurité sociale"
                            error={errors.joint_ssn}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Box className="spacer2" />

            {childrens.map((child, index) => {
              return (
                <div key={child.id}>
                  <Typography variant="h4">
                    Enfant {index + 1}
                    {"  "}{" "}
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                    // style={{ margin: ".8rem 0 .8rem .8rem" }}
                  >
                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`childrens.${index}.name`}
                        control={control}
                        defaultValue={child.name}
                        render={(field) => (
                          <CommonTextField field={field} label={"Nom"} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`childrens.${index}.surname`}
                        defaultValue={child.surname}
                        control={control}
                        render={(field) => (
                          <CommonTextField field={field} label={"Prénom"} />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`childrens.${index}.birth_date`}
                        control={control}
                        defaultValue={child.birth_date}
                        render={(field) => (
                          <CommonDateTimePicker
                            field={field}
                            label={"Date de naissance"}
                            disabled
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Controller
                        control={control}
                        name={`childrens.${index}.regime`}
                        defaultValue={child.regime}
                        render={(field) => (
                          <CommonSelect
                            label="Régime"
                            field={field}
                            options={regimes}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={control}
                        rules={{}}
                        name={`childrens.${index}.ssn`}
                        defaultValue={child.ssn}
                        render={(field) => {
                          return (
                            <CommonTextField
                              type="number"
                              field={field}
                              label="Numéro de sécurité sociale"
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              );
            })}

            <Box className="spacer2" />
            <Typography variant="h4">Coordonnées</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="address"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Adresse (numéro et libellé de voie)"
                        error={errors.address}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  name="zip_code"
                  render={(field) => (
                    <CommonTextField
                      type="number"
                      field={field}
                      label="Code postal"
                      name="zip_code"
                      error={errors.zip_code}
                      InputProps={{ disabled: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="city"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Ville"
                        error={errors.city}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="email"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"email"}
                        field={field}
                        label="Email"
                        error={errors.email}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="phone"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"tel"}
                        field={field}
                        label="Téléphone"
                        error={errors.phone}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Box className="spacer2" />
            <Typography variant="h4">Modalité de paiement</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="modalite"
                  render={(field) => {
                    return (
                      <CommonSelect
                        field={field}
                        label={"Modalité de paiement"}
                        error={errors.modalite}
                        options={modalites}
                      />
                    );
                  }}
                />
              </Grid>

              {getValues("modalite") === "1" && (
                <Grid item xs={12} md={6}>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="date_prelevement"
                    render={(field) => {
                      return (
                        <CommonSelect
                          field={field}
                          label={"Prélèvement le"}
                          options={dates_prelevement}
                          error={errors.date_prelevement}
                        />
                      );
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Box className="spacer2" />

            {getValues("modalite") === "1" && (
              <>
                <Typography variant="h4">Coordonnées bancaires</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="bank_account_owner"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="Titulaire du compte"
                            error={errors.bank_account_owner}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="iban_payment"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="IBAN"
                            error={errors.iban_payment}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name="periodicite"
                      render={(field) => {
                        return (
                          <CommonSelect
                            field={field}
                            label={"Périodicité de prélèvement"}
                            error={errors.periodicite}
                            options={periodicites}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="bic_payment"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="BIC"
                            error={errors.bic_payment}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "#C31D23",
                    fontStyle: "italic",
                    marginTop: ".5rem",
                  }}
                ></Typography>

                <Box className="spacer2" />
                <Grid item xs={12} md={12}>
                  {accept === true ? (
                    <Box style={{ display: "flex", alignItems: "middle" }}>
                      <BsFillCheckSquareFill
                        style={{ height: "20px", width: "20px" }}
                        fill={"#C31D23"}
                      />
                      <Typography
                        variant="body1"
                        style={{ marginLeft: "8px", paddingTop: "1px" }}
                      >
                        Accepte de recevoir les informations sur les contrats
                        MBA Mutuelle
                      </Typography>
                    </Box>
                  ) : (
                    <Box style={{ display: "flex", alignItems: "middle" }}>
                      <ImCheckboxUnchecked
                        style={{ height: "20px", width: "20px" }}
                        fill={"#C31D23"}
                      />
                      <Typography
                        variant="body1"
                        style={{ marginLeft: "8px", paddingTop: "1px" }}
                      >
                        N'accepte pas de recevoir les informations sur les
                        contrats MBA Mutuelle
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </>
            )}
            {data.contract_status === "sent" && (
              <Grid item xs={12} md={12}>
                <Box marginTop={4}>
                  <Button variant="contained" color="primary" type="submit">
                    Modifier le contrat
                  </Button>
                </Box>
              </Grid>
            )}
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContratIndividuelSingle;
