import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
} from "@material-ui/core";
import { BsArrowRightShort } from "react-icons/bs";
import { BsArrowLeftShort } from "react-icons/bs";
import TextFieldCustom from "../../Components/FormComponents/TextFieldCustom";
import { Link, useHistory, useParams } from "react-router-dom";
import { formatErrorEmail } from "../../../utils/formValidation";
import text from "../../../utils/text";
import { login } from "../../../Axios/Call/ClientCredential";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator from "../../../Store/action/index";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import AuthLayoutData from "../../../Components/Auth/Registration/AuthLayoutData";
import LoaderCircular from "../../../Components/Commons/Loading/LoaderCircular";
import Links from "../../../Components/Commons/Links/Links";
import { ButtonUnstyled } from "@mui/material";
import { useWindowHeight } from '@react-hook/window-size';
import Dashboard from "../../Dashboard/Sections/Performances";

const PersonnalData = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [checked, setChecked] = useState(false);
  const [read, setRead] = useState(false);


  const [emailConfirmationError, setEmailConfirmationError] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const history = useHistory();
  const { email } = useParams();

  const dispatch = useDispatch();

  const { register, handleSubmit, reset, errors, setValue, control, watch } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
    });

  const sendForm = () => {
    //console.log(checked);
    if (checked) {
      history.push("/dashboard");
    }
    else {
      <AlertGeneral
      color="danger"
      description={"Vous devez accepter la politique"}
    />
    }

  };




  return (
    <AuthLayoutData>
  
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={"Vous devez accepter la politique"}
        />
      )}

      <Box style={{textAlign: "left"}} >
        <Typography component="h2" variant="h1" style={{ fontSize: "33px",textAlign:"center"  }}>
          {/* {text.Login.title} */}
          Politique de protection des données personnelles
        </Typography>

        <Typography
          component="h2"
          variant="h3"
          style={{ fontSize: "18px", color: "#222", textAlign:"center" }}
        >
         Comme il s’agit de votre première connexion, veuillez lire et accepter notre politique de protection des données personnelles en cliquant sur le lien ci-dessous :
        </Typography>
      </Box>
      <form
        onSubmit={handleSubmit(sendForm)}
        style={{ marginTop: 25 }}
      >
        <Grid container>
         

        <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
        <Button
         onClick={() => window.open('https://www.mbamutuelle.com/reglement-general-de-protection-des-donnees.php', '_blank') && setRead(true)}
         className={"buttonGeneralStyleBlack"} 
        >
                Lire la politique de protection des données personnelles
              </Button>
          </Grid>



          <Grid item xs={12} md={12} style={{ paddingTop: 300 }}>
          <FormControlLabel
              style={{
                display: "block",
                textAlign: "center",
              }}
              control={
                <Checkbox
                  style={{ color: "#C31D23" }}
                  name="acceptation-politique"
                  onClick={() => setChecked(!checked)}
                  disabled= {read ===false ? true : false}
                />
              }
              name="infoContrat"
              label={
                "J'ai lu et j'accepte la politique des données personnelles"
              }
              ref={register({
                required: text.General.required,
              })}
              error={"Vous devez accepter le politique des données personnelles"}
            />


              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={"buttonGeneralStyle"}
                endIcon={<BsArrowRightShort />}
                >
               
  
                Continuer vers l'application
              </Button>
    

          </Grid>
        </Grid>
      </form>
     
     <Links/>

             
      <div style={{ marginTop: 30 }}></div>
    </AuthLayoutData>
  );
};

export default PersonnalData;
