import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  Typography,
  Button,
  Box,
  TextField,
} from "@material-ui/core";

import OAVLayout from "../OAVComponents/OAVLayout";
import { IoMdAddCircleOutline } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";

import { getQuote } from "../../../Axios/Call/ClientCredential";
import {
  formatErrorBeforeDate,
  errorDateJPlus1,
} from "../../../utils/formValidation";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateOav } from "../../../Store/action/oav";
import text from "../../../utils/text";
import OAVSynthese from "../OAVComponents/OAVSynthese";
import CommonDateTimePicker from "../../FormComponents/CommonDateTimePicker";
import CommonSelectId from "../../../Components/FormComponents/CommonSelect";
import moment from "moment";
import "moment/locale/fr";
import ZipcodeAutocomplete from "../../Commons/ZipcodeAutocomplete/ZipcodeAutocomplete";
import { set } from "lodash";
moment.locale("fr");

const OAVProfil = () => {
  const [showconjoint, setshowconjoint] = useState(false);
  const oav = useSelector((state) => state.oav);
  const [previousChangeGuarantee, setPreviousChangeGuarantee] = useState(null);
  const [minDate, setMinDate] = useState(moment().add(1, "days"));
  const regimes = useSelector((state) =>
    state.user.regimes.filter((regime) => regime.market === 0)
  );

  const [showChangeGuarantee, setShowChangeGuarantee] = useState(false);

  const [initialJoint, setInitialJoint] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const steps = ["Besoin", "Profil", "Choix de la garantie"];

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  console.log(oav)

  // Form steps and settings

  const {
    handleSubmit,
    errors,
    getValues,
    control,
    unregister,
    register,
    setValue,
    reset,
    watch,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "all",
    shouldDirty: true,
    defaultValues: {
      starting_date: moment().add(1, "days").format("YYYY-MM-DD"),
      zip_code: oav.data?.zip_code?.place ? oav.data?.zip_code : null, //TODO get correct zipcode object with the zipcode
      regime: oav.data?.regime ? oav.data?.regime : 1,
      birth_date: oav.data?.birth_date,
      joint_regime: oav.data?.joint_regime ? oav.data?.joint_regime : 1,
      joint_birth_date: oav.data?.joint_birth_date,
      enfant: oav.data?.enfant,
      change_guarantee: oav.data.hasOwnProperty("reprise") ? 0 : 1,
      //DEBUG testing purpose only
      // zip_code: "35400",
      // regime:"Général",
      // birth_date: new Date("1991-08-21"),
    },
  });

  useEffect(() => {
    if (oav && oav.data) {
      let enfants = oav.data.enfant;

  // Si enfants est un tableau, ajoutez la clé 'initial' à chaque objet du tableau
  if (Array.isArray(enfants)) {
    enfants = enfants.map(enfant => ({ ...enfant, initial: 1 }));
  }
      reset({
        starting_date: moment().add(1, "days").format("YYYY-MM-DD"),
        zip_code: oav.data.zip_code?.place ? oav.data.zip_code : null,
        regime: oav.data.regime || 1,
        birth_date: oav.data.birth_date,
        joint_regime: oav.data.joint_regime || 1,
        joint_birth_date: oav.data.joint_birth_date,
        enfant: enfants,
        change_guarantee: oav.data.hasOwnProperty("reprise") ? 0 : 1,
      });
    }
  }, [oav, reset]);

  useEffect(() => {
    if (oav.data.hasOwnProperty("reprise")) {
      getQuote(oav.data.reprise.quote).then((res) => {
        const startingDate = moment(res.data.starting_date).format(
          "YYYY-MM-DD"
        );
        const oneYearAgo = moment().subtract(12, "months").format("YYYY-MM-DD");

        if (moment(startingDate).isBefore(oneYearAgo)) {
          setShowChangeGuarantee(true);
        }
      });

      setInitialJoint(oav?.data?.joint_birth_date ?? null);
    }
  }, [oav.data]);

  useEffect(() => {
    setValue("starting_date", null);

    console.log(getValues("change_guarantee"));

    if (getValues("change_guarantee") == 0) {
      setMinDate(moment().add(1, "days"));
    } else {
      if (!oav.data.hasOwnProperty("reprise")) {
        setMinDate(moment().add(1, "days"));
      } else {
        setMinDate(moment().add(2, "months").startOf("month"));
      }
    }
  }, [watch("change_guarantee"), setValue]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "enfant",
  });

  const handleStep = (data) => {
    for (const [key, value] of Object.entries(data)) {
      if (data.enfant === undefined) {
        dispatch(updateOav("enfant", undefined));
      }
      dispatch(updateOav(key, value));
    }
    // history.push("/Tarificateur/Individuel/Garanties");
    history.push("/Tarificateur/Garanties");
  };

  const removeConjoint = () => {
    setValue("joint_regime", undefined);
    setValue("joint_birth_date", undefined);

    for (const [key, value] of Object.entries(oav.data)) {
      if (key === "joint_regime" || key === "joint_birth_date") {
        dispatch(updateOav(key, undefined));
      }
    }
    setshowconjoint(false);
  };

  // hooks

  useEffect(() => {
    if (oav.data?.joint_birth_date) {
      setshowconjoint(true);
    }
  }, [oav.data]);

  useEffect(() => {
    if (!showconjoint) {
      unregister("conjoint");
    }
  }, [showconjoint]);

  return (
    <OAVLayout extra={<OAVSynthese />}>
      <Stepper
        alternativeLabel
        activeStep={1}
        style={{ background: "#fafafa" }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <p
                  style={
                    1 === index
                      ? {
                          fontFamily: "Montserrat !important",
                          fontWeight: "600",
                          color: "#c31d23",
                        }
                      : {
                          fontFamily: "Montserrat !important",
                          fontWeight: "600",
                          color: "#c31d2388",
                        }
                  }
                >
                  {label}
                </p>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Box maxWidth="900px" margin="0 auto">
        <form
          onSubmit={handleSubmit(handleStep)}
          style={{
            marginTop: 20,
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          noValidate
        >
          <Grid container spacing={2} style={{ justifyContent: "left" }}>
            {/*  */}
            {oav.data.hasOwnProperty("reprise") && showChangeGuarantee && (
              <Grid item xs={12} md={6}>
                <Typography component="p" variant="h3">
                  Voulez-vous changer de garantie ?
                </Typography>
                {showChangeGuarantee && (
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="change_guarantee"
                    defaultValue={getValues("change_guarantee")}
                    render={(field) => (
                      <CommonSelectId
                        label="Changement de garantie"
                        field={field}
                        options={[
                          { id: 1, value: "Oui" },
                          { id: 0, value: "Non" },
                        ]}
                        error={errors.change_guarantee}
                        required={true}
                      />
                    )}
                  />
                )}
              </Grid>
            )}

            <Grid item xs={12} md={4} style={{ marginBottom: "1.5rem" }}>
              <Typography component="p" variant="h3">
                Date d'effet
              </Typography>
              <Controller
                name={"starting_date"}
                control={control}
                disabled={true}
                rules={{ required: true, validate: errorDateJPlus1 }}
                render={(field) => (
                  <CommonDateTimePicker
                    readOnly={true}
                    field={field}
                    label={"Date d'effet"}
                    error={errors.starting_date}
                    minDate={minDate}
                    disablePast={true}
                    renderInput={(params) => (
                      <TextField onKeyDown={onKeyDown} {...params} />
                    )}
                  />
                )}
              />
            </Grid>
            {/*  */}
            <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
              <Typography component="p" variant="h3">
                Votre profil
              </Typography>
              <Grid container spacing={2}>
                {!oav.data.hasOwnProperty("reprise") && <Grid item xs={12} md={4}>
                  <Controller
                    control={control}
                    name="zip_code"
                    rules={{ required: true }}
                    render={(field) => (
                      <ZipcodeAutocomplete
                        label="Code postal"
                        name="zip_code"
                        error={errors.zip_code}
                        TextFieldProps={{
                          error: Boolean(errors.zip_code),
                          helperText: errors.zip_code?.message,
                        }}
                        value={field.value}
                        onBlur={field.onBlur}
                        defaultValue={field.value}
                        onFocus={field.onFocus}
                        onChange={(e, value) => {
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                </Grid>}
                <Grid item xs={12} md={4}>
                  <Controller
                    name={"birth_date"}
                    control={control}
                    rules={{
                      required: text.General.required,
                      validate: formatErrorBeforeDate,
                    }}
                    defaultValue={null}
                    render={(field) => (
                      <CommonDateTimePicker
                        field={field}
                        disabled={oav.data.hasOwnProperty("reprise")}
                        label={"Date de naissance"}
                        error={errors.birth_date}
                        required={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="regime"
                    defaultValue={getValues("regime")}
                    render={(field) => (
                      <CommonSelectId
                        label="Régime"
                        field={field}
                        options={regimes}
                        error={errors.regime}
                        required={true}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Adding conjoint */}
            <Grid item xs={12} lg={10} style={{ marginBottom: "1.5rem" }}>
              <Typography
                component="p"
                variant="h3"
                style={{
                  display: "flex",
                  alignContent: "center",
                  marginBottom: "1rem",
                }}
              >
                Conjoint
                {!showconjoint ? (
                  <IoMdAddCircleOutline
                    style={{ marginLeft: ".8rem", cursor: "pointer" }}
                    onClick={() => setshowconjoint(!showconjoint)}
                  />
                ) : (
                  showconjoint &&
                  !initialJoint && (
                    <BsTrashFill
                      size={25}
                      fill={"#C31D23"}
                      style={{
                        marginLeft: "1rem",
                        cursor: "pointer",
                        alignSelf: "center",
                      }}
                      onClick={() => removeConjoint()}
                    />
                  )
                )}
              </Typography>
              {showconjoint && (
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <Controller
                      name={"joint_birth_date"}
                      control={control}
                     
                      rules={{
                        required: text.General.required,
                        validate: formatErrorBeforeDate,
                      }}
                      defaultValue={null}
                      render={(field) => (
                        <CommonDateTimePicker
                          field={field}
                          name={"joint_birth_date"}
                          disabled={oav.data.hasOwnProperty("reprise") && initialJoint}
                          label={"Date de naissance"}
                          error={errors.joint_birth_date}
                          required={true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Controller
                      control={control}
                      name="joint_regime"
                      rules={{ required: true }}
                     
                      defaultValue={getValues("joint_regime")}
                      render={(field) => (
                        <CommonSelectId
                          required={true}
                          label="Régime"
                          field={field}
                          error={errors.joint_regime}
                          options={regimes}
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="joint_first_name"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="Prénom"
                            error={errors.firt_name}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="joint_last_name"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="Nom"
                            error={errors.joint_last_name}
                          />
                        );
                      }}
                    />
                  </Grid> */}
                </Grid>
              )}
            </Grid>

            {/* Adding children */}
            {/* new */}
            <Grid item xs={12} lg={10} style={{ marginBottom: "1.5rem" }}>
              <Typography
                component="p"
                variant="h3"
                style={{
                  display: "flex",
                  alignContent: "center",
                  marginBottom: ".8rem",
                }}
              >
                Enfant.s
                <IoMdAddCircleOutline
                  style={{ marginLeft: ".8rem", cursor: "pointer" }}
                  onClick={() => append({})}
                />
              </Typography>
              {fields?.map((child, index) => {
                return (
                  <>
                    <Typography variant="h3" style={{ alignSelf: "center" }}>
                      Enfant {index + 1}
                      {"  "}{" "}
                    </Typography>
                    <Grid container spacing={2} key={child?.id}>
                      <Grid item xs={6} md={4}>
                        <Controller
                          name={`enfant[${index}].birth_date`}
                          defaultValue={
                            child?.birth_date ? child?.birth_date : null
                          }
                    
                          control={control}
                          rules={{ required: true }}
                          render={(field) => (
                            <CommonDateTimePicker
                            disabled={oav.data.hasOwnProperty("reprise") && child?.initial}
                              field={field}
                              // error={`errors.enfant[${index}].birth_date`} //TODO Display correctly an error
                              label={"Date de naissance"}
                              required={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Controller
                          control={control}
                          name={`enfant[${index}].regime`}
                          rules={{ required: true }}
                          render={(field) => (
                            <CommonSelectId
                              nullFirstOption
                              label="Régime"
                              // error={`errors.enfant[${index}].regime`} //TODO Display correctly an error
                              field={field}
                              options={regimes}
                            />
                          )}
                        />
                      </Grid>
                      <BsTrashFill
                        size={25}
                        fill={"#C31D23"}
                        style={{
                          marginLeft: "1rem",
                          cursor: "pointer",
                          alignSelf: "center",
                        }}
                        onClick={() => remove(index)}
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "3rem auto 0",
              alignSelf: "center",
              width: "100%",
            }}
          >
            <Button
              className={"buttonLightRedStyle"}
              onClick={() => history.push("/Tarificateur/Besoin")}
            >
              Précédent
            </Button>
            <Button className={"buttonGeneralStyle"} type="submit">
              Suivant
            </Button>
          </Box>
        </form>
      </Box>
    </OAVLayout>
  );
};

export default OAVProfil;
