import moment from 'moment';

function isDate(value) {
    return moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ', true).isValid();
}

function compareDates(a, b) {
    const dateA = moment(a, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ').valueOf();
    const dateB = moment(b, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ').valueOf();

    return dateA - dateB;
}

  

function descendingComparator(a, b, orderBy) {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (isDate(aValue) && isDate(bValue)) {
        return compareDates(aValue, bValue);
    }

    if (bValue < aValue) {
        return -1;
    }
    if (bValue > aValue) {
        return 1;
    }
    return 0;
}

export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};