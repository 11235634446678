import React, { useEffect, useState } from "react";
import { Box, Typography, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCommercials,
  getAllGuaranties,
  getAllProfessions,
  getQuote,
} from "../../../Axios/Call/ClientCredential";
import moment from "moment";
import { updateOav } from "../../../Store/action";
import { useHistory } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";

const OAVoav = () => {
  const oav = useSelector((state) => state.oav.data);
  const dispatch = useDispatch();
  const history = useHistory();
  const regimes = useSelector((state) => state.user.regimes);
  const [professions, setProfessions] = useState([]);
  const [guaranties, setguaranties] = useState([]);
  const [commercials, setCommercials] = useState([]);
  const width = useWindowWidth();

  useEffect(() => {
    getAllProfessions().then((res) => setProfessions(res?.data));
    getAllGuaranties().then((res) => setguaranties(res.data));
    getAllCommercials().then((res) => setCommercials(res.data));
  }, []);

  useEffect(() => {
    if (
      guaranties.length > 0 &&
      commercials.length > 0 &&
      professions.length > 0
    ) {
      if (history.location.search.length > 0) {
        const search = history.location.search;
        const number = search.match(/\d+/)[0] * 1;

        dispatch(
          updateOav("reprise", {
            value: true,
            quote: number,
            isDataLoaded: false,
          })
        );

        getQuote(number).then((res) => {
          console.log("sdfsd", res.data);
          dispatch(
            updateOav(
              "contrat",
              res.data.product.market === "company" ? "collectif" : "individuel"
            )
          );
          dispatch(updateOav("motif", "adhesion"));
          res.data.product.market !== "company" &&
            res.data.madelin &&
            dispatch(updateOav("madelin", res.data.madelin));

          res.data.starting_date &&
            dispatch(
              updateOav(
                "starting_date",
                moment(res.data.starting_date).format("YYYY-MM-DD")
              )
            );

          res.data.birth_date &&
            dispatch(
              updateOav(
                "birth_date",
                moment(res.data.birth_date).format("YYYY-MM-DD")
              )
            );
          res.data.joint_birth_date &&
            dispatch(
              updateOav(
                "joint_birth_date",
                moment(res.data.joint_birth_date).format("YYYY-MM-DD")
              )
            );
          res.data.joint_regime &&
            dispatch(updateOav("joint_regime", res.data.joint_regime));

          if (res.data.childrens.length > 0) {
            const children = [];
            res.data.childrens.forEach((child) =>
              children.push({
                birth_date: moment(child.birth_date).format("YYYY-MM-DD"),
                regime: child.regime,
              })
            );
            dispatch(updateOav("enfant", children));
          }

          if (res.data.commercial_id) {
            const commercialId = res.data.commercial_id;
            dispatch(updateOav("commercial_id", commercialId));
            dispatch(
              updateOav(
                "ccn",
                commercials.find((commercial) => commercial.id === commercialId)
                  ?.name
              )
            );
          }

          if (res.data.profession_id) {
            const professionId = res.data.profession_id;
            dispatch(updateOav("profession_id", professionId));
            dispatch(
              updateOav(
                "status",
                professions.find((profession) => profession.id === professionId)
                  ?.name
              )
            );
          }

          dispatch(updateOav("regime", res.data.regime));

          res.data.college && dispatch(updateOav("college", res.data.college));
          res.data.age_moyen &&
            dispatch(updateOav("age_moyen", res.data.age_moyen));
          res.data.effectif &&
            dispatch(updateOav("effectif", res.data.effectif));
          res.data.zip_code &&
            dispatch(updateOav("zip_code", res.data.zip_code));

          const prices = JSON.parse(res.data.price);
          dispatch(updateOav("selectedCommission", prices.commission));
          dispatch(updateOav("selectedStructure", prices.structure));

          dispatch(
            updateOav(
              "selectedBase",
              res.data.packages.find((warranty) => warranty.type === "base")
                ?.guarantee_id
            )
          );
          dispatch(
            updateOav(
              "selectedRenfortsOptions",
              res.data.packages
                .filter((warranty) => warranty.type !== "base")
                ?.map((warranty) => warranty.guarantee_id)
            )
          );
          dispatch(updateOav("modalite", res.data.modalite));

          console.log("res.data", res.data);

          const personnalData = {
            civility: res.data.civilite,
            lastname: res.data.last_name,
            firstname: res.data.firt_name,
            birthName: res.data.birth_name,
            address: res.data.address,
            zipCode: res.data.zip_code,
            city: res.data.city,
            email: res.data.email,
            phone: res.data.phone,
            job: res.data.job,
            ssn: res.data.ssn,
            id_number: res.data.id_number,
            id_date: res.data.delivery_date,
            id_place: res.data.delivery_city,
          };
          dispatch(updateOav("personnalData", personnalData));

          const companyData = {
            company: res.data.company,
            siren: res.data.siren,
            nic: res.data.nic,
            idcc: res.data.idcc,
          };
          dispatch(updateOav("companyData", companyData));

          // /!\ DISPATCH ONLY WHEN ALL DATA READY /!\
          dispatch(
            updateOav("reprise", {
              value: true,
              quote: number,
              isDataLoaded: true,
            })
          );
        });
      }
    }
  }, [guaranties, commercials, professions]);

  useEffect(() => {}, [oav]);

  return (
    <Box padding="1rem 2rem">
      <div id="oav">
        {" "}
        <p
          style={{
            textAlign: "left",
            fontSize: width > 1200 ? "25px" : "18px",
            fontFamily: "roboto, sans-serif",
            color: "white",
            marginBottom: ".5rem",
          }}
        >
          Ma synthèse
        </p>
        {
          <Paper
            elevation={0}
            style={{
              overflowY: "scroll",
              scrollbarWidth: "thin",
              maxHeight: "500px",
              padding: "1rem .5rem",
              textAlign: "left",
            }}
          >
            <Typography
              component="p"
              variant="h3"
              style={{
                fontSize: width > 1200 ? "25px" : "18px",
                textAlign: "left",
              }}
            >
              Besoin
            </Typography>
            <Box textAlign="left">
              {oav?.contrat !== undefined && (
                <Typography
                  style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                >
                  Contrat: {oav?.contrat}
                </Typography>
              )}
              {(oav.status !== undefined || oav.profession !== null) &&
                oav.contrat === "individuel" && (
                  <Typography
                    style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                  >
                    Statut pro:{" "}
                    {professions.find((p) => p.id === oav.profession_id)?.name}
                  </Typography>
                )}
              {oav.madelin !== null &&
                oav.contrat === "individuel" &&
                oav.madelin === "non_madelin" && (
                  <Typography
                    style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                  >
                    Non Madelin
                  </Typography>
                )}
              {oav.madelin !== null &&
                oav.contrat === "individuel" &&
                oav.madelin === "madelin" && (
                  <Typography
                    style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                  >
                    Madelin
                  </Typography>
                )}
              {oav.commercial_id && oav.contrat === "collectif" && (
                <Typography
                  style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                >
                  CCN: {oav.ccn}
                </Typography>
              )}
              {oav.motif !== undefined && (
                <Typography
                  style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                >
                  Motif: {oav.motif}
                </Typography>
              )}
              {oav.adherent !== undefined && oav.motif === "avenant" && (
                <Typography
                  style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                >
                  Adherent: {oav.adherent}
                </Typography>
              )}
            </Box>
            <Box className="spacer1" />
            <Typography
              component="p"
              variant="h3"
              style={{ fontSize: width > 1200 ? "25px" : "18px" }}
            >
              Profil
            </Typography>
            <Box textAlign="left">
              {oav.starting_date !== undefined && (
                <Typography
                  style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                >
                  Contrat à partir du{" "}
                  {moment(oav.starting_date).format("DD/MM/yyyy")}
                </Typography>
              )}
              {oav.birth_date !== undefined && oav.birth_date !== null && (
                <Typography
                  style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                >
                  Né(e) le {moment(oav.birth_date).format("DD/MM/yyyy")}
                </Typography>
              )}
              {oav.zip_code?.place?.zipcode && oav.zip_code?.place?.place && (
                <Typography
                  style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                >
                  Code postal: {oav.zip_code.place.zipcode} -{" "}
                  {oav.zip_code.place.place}
                </Typography>
              )}
              {oav.regime !== undefined && (
                <Typography
                  style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                >
                  Régime:{" "}
                  {
                    regimes.find((regime) => regime.id === parseInt(oav.regime))
                      ?.value
                  }
                </Typography>
              )}
              {/* TODO CONJOINT */}
              {/* TODO ENFANTS */}
            </Box>
            <Box className="spacer1" />

            <Typography
              component="p"
              variant="h3"
              style={{ fontSize: width > 1200 ? "25px" : "18px" }}
            >
              Garantie
            </Typography>
            <Box textAlign="left">
              {oav.base !== undefined && oav.base !== 0 && oav.base !== "0" && (
                <Typography
                  style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                >
                  Base:{" "}
                  {
                    guaranties?.filter((g) => g.id === oav.base * 1)[0]
                      ?.description
                  }
                </Typography>
              )}
              {oav.renfort !== undefined &&
                oav.renfort !== 0 &&
                oav.renfort !== "0" &&
                oav.renfort.map((renfort) => (
                  <Typography
                    style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                  >
                    Renfort:
                    {" " +
                      guaranties?.find((g) => g.id === renfort)?.description}
                  </Typography>
                ))}
              {oav.option !== undefined &&
                oav.option !== 0 &&
                oav.option !== "0" &&
                oav.option.map((option) => (
                  <Typography
                    style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                  >
                    Option:
                    {" " +
                      guaranties?.find((g) => g.id === option)?.description}
                  </Typography>
                ))}
              {oav.supp !== undefined &&
                oav.supp !== 0 &&
                oav.supp !== "0" &&
                oav.supp.map((supp) => (
                  <Typography
                    style={{ fontSize: width > 1200 ? "18px" : "12px" }}
                  >
                    Produit comp. :
                    {" " + guaranties?.find((g) => g.id === supp)?.description}
                  </Typography>
                ))}
            </Box>
          </Paper>
        }
      </div>
    </Box>
  );
};

export default OAVoav;
