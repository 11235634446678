import {Document, Page} from "react-pdf/dist/umd/entry.webpack";
import {Box, Button} from "@material-ui/core";
import React, {useState} from "react";

const PDFViewer = ({file}) => {
    const [numpage, setNumpage] = useState(null);
    const [pageNumber, setpageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumpage(numPages);
    };
    const goToPrevPage = () => setpageNumber(pageNumber <= 1 ? 1 : pageNumber - 1 );
    const goToNextPage = () => setpageNumber(pageNumber >= numpage ? numpage : pageNumber + 1);

    return(
        <Box margin="1em 0">
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                renderAnnotationLayer={false}
            >
                {file?.name}
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Page
                        pageNumber={pageNumber}
                        renderForms={false}
                        renderAnnotationLayer={false}
                        width={700} />
                </Box>
            </Document>
            <nav
                style={{
                    margin: "1em auto",
                    display: "flex",
                    justifyContent: "space-around",
                }}
            >
                <Button variant="outlined" onClick={() => goToPrevPage()}>
                    Précédent
                </Button>
                <Button className={"buttonGeneralStyleSmall"}>
                    <a style={{color: "white", textDecoration: "none"}} download="Document" href={file} title='Download pdf document'>{"Télécharger".toUpperCase()}</a>
                </Button>
                <Button variant="outlined" onClick={() => goToNextPage()}>
                    Suivant
                </Button>
            </nav>
            {numpage &&
            <p style={{ margin: "0 auto" }}>
                Page {pageNumber} of {numpage}
            </p>
            }
        </Box>
    )
};

export default PDFViewer;