import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

import App from "./Containers/App";

import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import userReducer from "./Store/reducer/user";
import dashboardReducer from "./Store/reducer/dashboard";
import oavReducer from "./Store/reducer/oav";
import thunk from "redux-thunk";

import { useWindowWidth } from '@react-hook/window-size';
import {initAxios} from "./Axios/AxiosInstance";
import {frFR} from "@mui/material/locale";

const rootReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
  oav: oavReducer,
});

initAxios();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#C31D23"
    },
    secondary: {
      main: "#ffa500"
    }
  },
  frFR
});

theme.typography.h1 = {
  fontSize: "50",
  fontFamily: ["roboto", "-apple-system"].join(","),
  color: "#C31D23",
  fontWeight: "800",
};


theme.typography.h2 = {
  fontSize: 34,
  fontFamily: ["roboto", "-apple-system"].join(","),
  color: "#000000",
  fontWeight: "500",
};

theme.typography.h3 = {
  fontSize: 20,
  fontFamily: ["roboto", "-apple-system"].join(","),
  color: "#C31D23",
  fontWeight: "500",  
};

theme.typography.h4 = {
  fontSize: 20,
  fontFamily: ["roboto", "-apple-system"].join(","),
  color: "#C31D23",
  fontWeight: "400",
  textAlign: "left",
};

theme.typography.h5 = {
  fontSize: 15,
  fontFamily: ["roboto", "-apple-system"].join(","),
  color: "#C31D23",
  fontWeight: "400",
  marginBottom: "1rem",
}

theme.typography.h6 = {
  fontSize: 15,
  color: "#333",
  fontWeight: "500",
  fontFamily: ["roboto", "-apple-system"].join(","),
}

theme.typography.body1 = {
  fontSize: 14,
  fontFamily: ["roboto", "-apple-system"].join(","),
  color: "#000000",
  fontWeight: "400",
  wordWrap: "break-word",
};

theme.typography.body3 = {
  fontSize: 14,
  fontFamily: ["roboto", "-apple-system"].join(","),
  color: "#C31D23",
  fontWeight: "400",
  wordWrap: "break-word",
};


theme.typography.body2 = {
  fontSize: 14,
  fontFamily: ["roboto", "-apple-system"].join(","),
  color: "#222",
  fontWeight: "400",
  wordWrap: "break-word",
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <App />
          </CssBaseline>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
