import { authGouvAxios } from "../AxiosInstance";

// COMMUNE

export const getCommunes = () => {
  return authGouvAxios.get("communes");
};

export const getSingleCommune = (code) => {
  return authGouvAxios.get(`commnunes/${code}`);
};

export const getCommunesPerDpt = (code) => {
  return authGouvAxios.get(`departements/${code}/communes`);
};

// DPT

export const getDpts = () => {
  return authGouvAxios.get("departements");
};

export const getSingleDpt = (code) => {
  return authGouvAxios.get(`departements/${code}`);
};

export const getDptsPerRegion = (code) => {
  return authGouvAxios.get(`regions/${code}/departements`);
};

// REGION

export const getRegions = () => {
  return authGouvAxios.get("regions");
};

export const getSingleRegion = (code) => {
  return authGouvAxios.get(`regions/${code}`);
};
