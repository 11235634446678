import React from "react";
import LoadingButton from "../Commons/LoadingButton/LoadingButton";
import Button from "@material-ui/core/Button";

const CommonButton = ({
  onClick,
  loading,
  text,
  disabled,
  isGreyStyle,
  type,
  isSmallButton,
  style,
  form,
}) => {
  return (
    <>
      {loading ? (
        <LoadingButton />
      ) : (
        <Button
          form={form}
          type={type ?? "submit"}
          variant="contained"
          color="primary"
          size="small"
          className={
            isGreyStyle
              ? "buttonGeneralStyleGrey"
              : isSmallButton
              ? "buttonSmallGeneralStyle"
              : "buttonGeneralStyle"
          }
          style={{ ...style }}
          onClick={onClick}
          disabled={disabled}
        >
          {text}
        </Button>
      )}
    </>
  );
};

export default CommonButton;
