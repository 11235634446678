import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { BsArrowRightShort } from "react-icons/bs";
import { BsArrowLeftShort } from "react-icons/bs";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { Link, useHistory, useParams } from "react-router-dom";
import { formatErrorEmail } from "../../../utils/formValidation";
import text from "../../../utils/text";
import { login } from "../../../Axios/Call/ClientCredential";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator from "../../../Store/action/index";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import AuthLayout from "../../../Components/Auth/Registration/AuthLayout";
import LoaderCircular from "../../../Components/Commons/Loading/LoaderCircular";
import Links from "../../../Components/Commons/Links/Links";

const Login = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [emailConfirmationError, setEmailConfirmationError] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const history = useHistory();
  const { email } = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const sendFormLogin = (data) => {
    setLoadingApi(true);
    login(data)
      .then((response) => {
        localStorage.setItem("token", "Bearer " + response.data.token);
        dispatch(actionCreator.populateUserData());
        
        setLoadingApi(false);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  useEffect(() => {

    if (Object.keys(userData)?.length) {
      if (userData?.status === "active") {
        // if (userData?.email_verified_at) {
          if (userData?.type === "broker" || userData?.type === "admin") {
            //console.log("CONNEXIONS : " + userData.connection_number);

            if (userData?.connection_number === 1) {
              history.push("/DonneesPersonnelles");
            } else {
              history.push("/Dashboard");
            }
          }
        // } else {
        //   dispatch(actionCreator.cleanUserData());
        // }
      } else {
        dispatch(actionCreator.cleanUserData());
      }
    }
  
  }, [userData]);

  useEffect(() => {
    if (
      /^[a-zA-Z0-9]([a-zA-Z0-9._-]+)@(([a-zA-Z0-9-]+\.)+)([a-zA-Z]{2,4}|[0-9]{1,3})$/.test(
        email
      )
    ) {
      setValue("email", email);
    }
  }, [email]);

  return (
    <AuthLayout>
      {emailConfirmationError && (
        <AlertGeneral
          color="danger"
          setTrigger={setEmailConfirmationError}
          description={text.Login.alertError}
        />
      )}
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.Login.alertAccount}
        />
      )}
      <Box style={{ textAlign: "left" }}>
        <Typography component="h2" variant="h1" style={{ fontSize: "33px" }}>
          {/* {text.Login.title} */}
          Espace Courtier
        </Typography>

        <Typography
          component="h2"
          variant="h3"
          style={{ fontSize: "14px", color: "#222" }}
        >
          Connectez-vous à votre espace Mba Mutuelle
        </Typography>
      </Box>
      <form
        onSubmit={handleSubmit(sendFormLogin)}
        style={{ marginTop: 25 }}
        noValidate
      >
        <Grid container>
          <Grid item xs={12}>
            <TextFieldCustom
              label="Email"
              type="email"
              ref={register({
                required: text.General.required,
                validate: {
                  formatErrorEmail,
                },
              })}
              defaultValue=""
              name="email"
              error={errors.email}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: 20 }}>
            <TextFieldCustom
              variant="outlined"
              label="Mot de passe"
              type="password"
              ref={register({ required: text.General.required })}
              defaultValue=""
              name={"password"}
              error={errors.password}
            />
          </Grid>

          {loadingApi ? (
            ""
          ) : (
            <Link
              to={"/recover-password"}
              style={{ textDecoration: "none", margin: "0.4rem auto" }}
            >
              <Typography
                component="span"
                variant="body1"
                className={"linkBottomGrey"}
              >
                {text.Login.forgotPassword}
              </Typography>
            </Link>
          )}

          <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
            {loadingApi ? (
              <LoaderCircular />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={"buttonGeneralStyle"}
                endIcon={<BsArrowRightShort />}
              >
                {text.General.connect}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>

      <Links />

      <div style={{ marginTop: 30 }}></div>
    </AuthLayout>
  );
};

export default Login;
