import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { cleanOavData } from "../../../Store/action/oav";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@material-ui/core";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CommonSelect from "../../../Containers/Components/FormComponents/CommonSelect";
import CommonSelectId from "../../../Components/FormComponents/CommonSelect";
import CommonTextField from "../../FormComponents/CommonTextField";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import OAVLayout from "../OAVComponents/OAVLayout";
import OAVSynthese from "../OAVComponents/OAVSynthese";
import LoaderGeneration from "../../Commons/Loading/LoaderGeneration";
import {
  formatErrorZip,
  formatErrorPhoneNumber,
  formatErrorEmail,
  errorDateJPlus1
} from "../../../utils/formValidation";
import text from "../../../utils/text";
import { useHistory } from "react-router-dom";
import { pingBIC, pingIBAN } from "../../../utils/iban";
import {addQuote, getQuote, patchQuote} from "../../../Axios/Call/ClientCredential";
import moment from "moment";
import "moment/locale/fr";
import CommonDateTimePicker from "../../FormComponents/CommonDateTimePicker";
moment.locale("fr");

const OAVSouscrire = () => {
  const [successapi, setsuccessapi] = useState(false);
  const [errorapi, seterrorapi] = useState(false);
  const [loading, setloading] = useState(false);
  const oav = useSelector((state) => state.oav.data);
  const broker_id = useSelector(
    (state) => state.user.userData.broker_id || state.user.userData.id
  );
  const colleges = useSelector((state) => state.user.colleges);
  const dispatch = useDispatch();
  const history = useHistory();
  const [ibancheck, setibancheck] = useState(false);
  const [biccheck, setbiccheck] = useState(false);
  const [typeQuote, setTypeQuote] = useState("subscription");
  console.log(oav, "oav");
  // Form steps and settings
  const ref_quote_id_const = oav?.reprise?.quote ? oav?.reprise?.quote : null;
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
    unregister,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "all",
    shouldDirty: true,
    defaultValues: {
      starting_date: moment(oav?.starting_date).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD") ? oav?.starting_date : new Date(moment().add(1, 'days')),
      company: oav?.companyData?.company,
      siren: oav?.companyData?.siren,
      nic: oav?.companyData?.nic,
      naf: oav?.naf,
      idcc: oav?.companyData?.idcc,
      address: oav?.personnalData?.address,
      zip_code:
        typeof oav?.zip_code === "string"
          ? oav.zip_code
          : oav?.zip_code?.place?.zipcode,
      city:
        typeof oav?.zip_code === "string"
          ? oav?.personnalData?.city
          : oav?.zip_code?.place?.place,
      address_correspondance: oav?.address_correspondance,
      zip_code_correspondance: oav?.zip_code_correspondance,
      city_correspondance: oav?.city_correspondance,
      firt_name: oav?.personnalData?.firstname,
      first_name_comptable: oav?.first_name_comptable,
      last_name: oav?.personnalData?.lastname,
      last_name_comptable: oav?.last_name_comptable,
      phone: oav?.personnalData?.phone,
      phone_comptable: oav?.phone_comptable,
      email: oav?.personnalData?.email,
      email_comptable: oav?.email_comptable,
      college: oav?.college,
      effectif: oav?.effectif,
      age_moyen: oav?.age_moyen,
      peridiocite_appel: oav?.peridiocite_appel,
      periodicite: oav?.periodicite,
      appel: oav?.appel,
      bank_account_owner: oav?.bank_account_owner,
      iban: oav?.iban_payment,
      bic: oav?.bic,
      job: oav?.personnalData?.job,
      correspondance: oav?.correspondance,
      ref_quote_id: ref_quote_id_const
    },
  });

  const createDataSub = (
    broker_id,
    product_id,
    profession_id,
    starting_date,
    madelin,
    firt_name,
    last_name,
    email,
    phone,
    role,
    company,
    siren,
    nic,
    naf,
    address,
    city,
    zip_code,
    address_correspondance,
    city_correspondance,
    zip_code_correspondance,
    first_name_comptable,
    last_name_comptable,
    phone_comptable,
    email_comptable,
    regime,
    college,
    effectif,
    age_moyen,
    structure_cotisation,
    appel,
    appel_renfort,
    periodicite,
    peridiocite_appel,
    taux,
    idcc,
    commercial_id,
    price,
    total_price,
    meta,
    iban,
    bic,
    bank_account_owner,
    iban_payment,
    bic_payment,
    bank_account_owner_payment,
    date_prelevement,
    info_contact,
    packages,
    ref_quote_id,
    date_status
  ) => {
    return {
      type: "subscription",
      signed: 0,
      status_signed: "waiting",
      status_not_signed: "sended",
      devis_status: "signed",
      contract_status: "sent",
      broker_id,
      product_id,
      profession_id,
      starting_date,
      madelin,
      firt_name,
      last_name,
      email,
      phone,
      role: role,
      company,
      siren,
      nic,
      naf,
      address,
      city,
      zip_code,
      address_correspondance,
      city_correspondance,
      zip_code_correspondance,
      first_name_comptable,
      last_name_comptable,
      phone_comptable,
      email_comptable,
      regime,
      college,
      effectif,
      age_moyen,
      structure_cotisation,
      appel,
      appel_renfort,
      periodicite,
      peridiocite_appel,
      taux,
      idcc,
      commercial_id,
      price,
      total_price,
      meta,
      iban,
      bic,
      bank_account_owner,
      iban_payment,
      bic_payment,
      bank_account_owner_payment,
      date_prelevement,
      info_contact,
      packages: packages,
      ref_quote_id,
      date_status,
    };
  };

  useEffect(() => {
    if(ref_quote_id_const) {
      getQuote(ref_quote_id_const).then((res) => {
        setTypeQuote(res.data.type);
        console.log(res.data.type,"typeee");
      });
    }
  }, []);

  const handleStep = (data) => {
    debugger;
    setloading(true);
    const QSP = [];
    for (const [key, value] of Object.entries(oav)) {
      if (key === "base") {
        if (value !== 0 && value !== "0") {
          QSP.push({ id: oav?.pack, guarantee_id: value * 1, type: "base" });
        }
      }
      if (key === "renfort") {
        oav.renfort.forEach((renfortId) => {
          if (value.length > 0) {
            QSP.push({
              id: oav?.pack,
              guarantee_id: renfortId,
              type: "renfort",
            });
          }
        });
      }
      if (key === "option") {
        oav.option.forEach((optionId) => {
          if (value.length > 0) {
            QSP.push({ id: oav?.pack, guarantee_id: optionId, type: "option" });
          }
        });
      }
    }

    const tmpdata = createDataSub(
      broker_id,
      oav.product, // TODO changer pour le bon produit
      oav.activity_id,
      data.starting_date,
      "non_madelin",
      data.firt_name,
      data.last_name,
      data.email,
      data.phone,
      data.job,
      data.company,
      data.siren,
      data.nic,
      data.naf,
      data.address,
      data.city,
      data.zip_code,
      data.address_correspondance,
      data.city_correspondance,
      data.zip_code_correspondance,
      data.first_name_comptable,
      data.last_name_comptable,
      data.phone_comptable,
      data.email_comptable,
      oav.regime,
      data.college,
      data.effectif,
      data.age_moyen,
      oav?.structure_cotisation,
      data.appel,
      data.appel_renfort,
      data.periodicite,
      data.peridiocite_appel,
      oav.selectedCommission,
      data.idcc,
      oav?.commercial_id,
      oav.price,
      oav.total_price,
      oav.meta,
      data.iban,
      data.bic,
      data.bank_account_owner,
      data.iban,
      data.bic,
      data.bank_account_owner,
      data.date_prelevement,
      data.info_contact,
      JSON.stringify(QSP),
        ref_quote_id_const,
      moment().format("YYYY-MM-DD")
    );

    if (oav.reprise?.value && typeQuote === 'quote') {
      patchQuote(oav.reprise?.quote, { ...tmpdata, signed: 1 })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setloading(false);
            setsuccessapi(true);
            dispatch(cleanOavData());
            history.push(`/Contrats/Collectifs/${oav.reprise?.quote}`);
          } else {
            seterrorapi(true);
            setloading(false);
          }
        })
        .catch((err) => console.warn(err));
    } else {
      addQuote(tmpdata)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setloading(false);
            setsuccessapi(true);
            dispatch(cleanOavData());

            history.push(`/Contrats/Collectifs/${res.data.id}`);
          } else {
            seterrorapi(true);
            setloading(false);
          }
        })
        .catch((err) => console.warn(err));
    }
  };

  useEffect(() => {
    setValue("correspondance", false);
  }, []);

  useEffect(() => {
    if (getValues("correspondance") === false) {
      unregister("address_correspondance");
      unregister("city_correspondance");
      unregister("zip_code_correspondance");
    }
  }, [watch("correspondance")]);

  // Function to test IBAN and set borders colors to green if right
  function checkIBAN(iban) {
    const result = pingIBAN(iban.value);

    if (iban != null !== iban != "") {
      if (result != true) {
        setibancheck(false);
      } else {
        setibancheck(true);
      }
    }

    return ibancheck;
  }

  // Function to test BIC and set borders colors to green if right
  function checkBIC(bic) {
    const result = pingBIC(bic.value);

    if (bic != null && bic != "") {
      if (result != true) {
        setbiccheck(false);
      } else {
        setbiccheck(true);
      }
    }

    return biccheck;
  }

    const handleCreatingSiren = () => {
        const currentValue = getValues("creatingSiren");
        setValue("creatingSiren", !currentValue);
        currentValue
            ? setValue('siren', '')
            : setValue('siren', 'En cours de création')
    };

  return (
    <OAVLayout extra={<OAVSynthese />}>
      {successapi && (
        <AlertGeneral
          setTrigger={setsuccessapi}
          color=""
          description="Contrat enregistré !"
        />
      )}
      {errorapi && (
        <AlertGeneral
          setTrigger={seterrorapi}
          color="danger"
          description="Une erreur est survenue"
        />
      )}
      {loading ? (
        <LoaderGeneration />
      ) : (
        <Box maxWidth="900px" margin="0 auto" style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius:"5px", padding:'20px 50px'}}>
          <form onSubmit={handleSubmit(handleStep)} style={{marginTop: 20, textAlign: "left", display: "flex", flexDirection: "column", justifyContent: "center",}} noValidate>
            <Grid container alignItems="center">
              <GroupAddIcon width="50px" fontSize="large" style={{ color: "#C31D23", marginRight: 10 }}/>
              <Typography variant="h1">
                Souscrire un contrat collectif
              </Typography>
            </Grid>
            <Divider style={{ marginTop: 10, marginBottom: 30, color: "#C31D2330" }}/>

            <Typography component="p" variant="h4">
              Date d'effet
            </Typography>
            <Controller
              name={"starting_date"}
              control={control}
              disabled={true}
              rules={{ required: true, validate: errorDateJPlus1 }}
              render={(field) => (
                <CommonDateTimePicker
                  readOnly={true}
                  field={field}
                  name={"starting_date"}
                  label={"Date d'effet"}
                  error={errors.starting_date}
                  minDate={new Date().getDate() > 14 ? moment().add(1, "days") : moment().subtract(new Date().getDate() - 1, "days")}
                  disablePast={new Date().getDate() > 14}
                  renderInput={(params) => (
                    <TextField onKeyDown={onKeyDown} {...params} />
                  )}
                />
              )}
            />

            <Box className="spacer1" />

            <Typography variant="h4">Votre entreprise</Typography>
            {/* entreprise */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="company"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Raison sociale"
                        error={errors.company}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                  <Box style={{ display: "flex", alignItems: 'center', gap: 10}}>
                      <FormControlLabel
                          style={{display: "block", textAlign: "center", margin: '0.8rem 0 0 0'}}
                          control={
                              <Checkbox
                                  value={getValues("creatingSiren")}
                                  style={{ color: "#C31D23" }}
                                  checked={getValues("creatingSiren")}
                                  onClick={handleCreatingSiren}
                                  defaultValue={false}
                                  defaultChecked={false}
                                  name="creatingSiren"
                              />
                          }
                          name="creatingSiren"
                          label={"En cours de création"}
                          ref={register("creatingSiren")}
                      />
                      <Controller
                          control={control}
                          rules={{ required: true }}
                          name="siren"
                          render={(field) => {
                              return (
                                  <CommonTextField
                                      readOnly={watch('creatingSiren')}
                                      field={field}
                                      label="Numéro de siren"
                                      error={errors.siren}
                                      InputProps={{
                                          shrink: watch('creatingSiren')
                                      }}
                                  />
                              );
                          }}
                      />
                  </Box>
              </Grid>

              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="nic"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Code nic"
                        error={errors.nic}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="naf"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Code naf"
                        error={errors.naf}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="idcc"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Numéro idcc"
                        error={errors.idcc}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Box className="spacer1" />

            <Typography variant="h4">Adresse de l'entreprise</Typography>
            {/* addresse */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{}}
                  name="address"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Adresse (numéro et libellé de voie)"
                        error={errors.address}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="zip_code"
                  rules={{ required: true, validate: formatErrorZip }}
                  render={(field) => (
                    <CommonTextField
                      field={field}
                      label="Code postal"
                      name="zip_code"
                      error={errors.zip_code}
                      InputProps={{ disabled: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  rules={{}}
                  name="city"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Ville"
                        error={errors.city}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item >
                <FormControlLabel
                  style={{
                    display: "block",
                    textAlign: "center",
                  }}
                  control={
                    <Checkbox
                      value={getValues("correspondance")}
                      style={{ color: "#C31D23" }}
                      checked={getValues("correspondance")}
                      onClick={() => {
                        return getValues("correspondance") === true
                          ? setValue("correspondance", false)
                          : setValue("correspondance", true);
                      }}
                      defaultValue={false}
                      defaultChecked={false}
                      name="correspondance"
                    />
                  }
                  name="correspondance"
                  label={"Adresse de correspondance identique"}
                  ref={register("correspondance")}
                />
              </Grid>
            </Grid>

            <Box className="spacer1" />

            {getValues("correspondance") === false && (
              <>
                <Typography variant="h4">Adresse de correspondance</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      rules={{}}
                      name="address_correspondance"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="Adresse (numéro et libellé de voie)"
                            error={errors.address_correspondance}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      control={control}
                      name="zip_code_correspondance"
                      rules={{ required: true, validate: formatErrorZip }}
                      render={(field) => (
                        <CommonTextField
                          field={field}
                          label="Code postal"
                          name="zip_code_correspondance"
                          error={errors.zip_code_correspondance}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      control={control}
                      rules={{}}
                      name="city_correspondance"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="Ville"
                            error={errors.city_correspondance}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Box className="spacer1" />

            {/* coordonnée interlocuteurs */}

            <Typography variant="h4">Interlocuteur</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="last_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Nom"
                        error={errors.last_name}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="firt_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Prénom"
                        error={errors.firt_name}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true, validate: formatErrorPhoneNumber }}
                  name="phone"
                  render={(field) => {
                    return (
                      <>
                      <CommonTextField
                        type={"tel"}
                        field={field}
                        label="Téléphone"
                        error={errors.phone}
                      />
                      <Typography variant="body" style={{color:"#222"}}>
                      Pour la signature du contrat par SMS, merci de renseigner le numéro de mobile (Ex : 0608090809)
                  </Typography>
                  </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ validate: formatErrorEmail }}
                  name="email"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"email"}
                        field={field}
                        label="Email"
                        error={errors.email}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="job"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Fonction"
                        error={errors.job}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Box className="spacer1" />
            <Typography variant="h4">Interlocuteur comptable</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  rules={{}}
                  name="last_name_comptable"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Nom"
                        error={errors.last_name_comptable}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  rules={{}}
                  name="first_name_comptable"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Prénom"
                        error={errors.first_name_comptable}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  rules={{ validate: formatErrorPhoneNumber }}
                  name="phone_comptable"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"tel"}
                        field={field}
                        label="Téléphone"
                        error={errors.phone_comptable}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  rules={{ validate: formatErrorEmail }}
                  name="email_comptable"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"email"}
                        field={field}
                        label="Email"
                        error={errors.email_comptable}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            {/* categories rappel */}
            <Box className="spacer1" />

            <Typography variant="h4">
              Catégorie de personnel couverte et structure de l'effectif
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  name="college"
                  rules={{ required: true }}
                  defaultValue={getValues("college")}
                  render={(field) => (
                    <CommonSelectId
                      label="Collège"
                      field={field}
                      error={errors.college}
                      options={colleges}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true, valueAsNumber: true }}
                  name="effectif"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"number"}
                        field={field}
                        label="Effectif assurable du collège"
                        error={errors.effectif}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true, valueAsNumber: true }}
                  name="age_moyen"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"number"}
                        field={field}
                        label="Âge moyen"
                        error={errors.age_moyen}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Box className="spacer1" />

            {/* cotisation */}
            <Typography variant="h4">
              Périodicité d'appel des cotisations
            </Typography>
            <Grid container spacing={2} style={{marginTop:5}}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="peridiocite_appel"
                  rules={{ required: true }}
                  defaultValue={getValues("peridiocite_appel")}
                  render={(field) => (
                    <CommonSelect
                      label="Périodicité d'appel"
                      field={field}
                      error={errors.peridiocite_appel}
                      options={[
                        { id: 1, value: "Mensuellement à terme échu" },
                        { id: 2, value: "Trimestriellement à terme échu" },
                      ]}
                   
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box className="spacer1" />

            <Grid container spacing={2} >
              <Grid item xs={12} md={6}>
                <Typography variant="h4" style={{marginBottom:5}}>Contrat base</Typography>
                <Controller
                  control={control}
                  name="appel"
                  rules={{ required: true }}
                  defaultValue={getValues("appel")}
                  render={(field) => (
                    <CommonSelect
                      label="Modalité d'appel pour les bénéficiaires autres que le salarié"
                      field={field}
                      error={errors.appel}
                      options={[
                        { id: 1, value: "Auprès de l'entreprise" },
                        { id: 2, value: "Auprès du salarié" },
                      ]}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h4" style={{marginBottom:5}}>Contrat Renfort(s)</Typography>
                <Controller
                  control={control}
                  name="appel_renfort"
                  rules={{ required: true }}
                  defaultValue={getValues("appel_renfort")}
                  render={(field) => (
                    <CommonSelect
                      label="Modalité d'appel de cotisation"
                      field={field}
                      error={errors.appel}
                      options={[
                        { id: 1, value: "Auprès de l'entreprise" },
                        { id: 2, value: "Auprès du salarié" },
                      ]}
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* coordonnées bancaires */}

            <Box className="spacer1" />
            <Typography variant="h4">Coordonnées bancaires</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="bank_account_owner"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Titulaire du compte"
                        error={errors.bank_account_owner}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* TODO: iban validation */}
                <Controller
                  control={control}
                  rules={{ required: true, validate: pingIBAN }}
                  name="iban"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label={
                          ibancheck ? (
                            <div>
                              <span>IBAN </span>
                              <BsCheck style={{ color: "green", size: "30" }} />
                            </div>
                          ) : (
                            <div>
                              <span>IBAN </span>
                              <AiFillCloseCircle style={{ color: "red" }} />
                            </div>
                          )
                        }
                        onChange={checkIBAN(field)}
                        error={errors.iban}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{marginTop:10}}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="periodicite"
                  render={(field) => {
                    return (
                      <CommonSelect
                        field={field}
                        label={"Périodicité de prélèvement"}
                        error={errors.periodicite}
                        options={[
                          { id: 1, value: "Mensuellement" },
                          { id: 2, value: "Trimestriellement" },
                        ]}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} >
                <Controller
                  control={control}
                  rules={{ required: text.General.required, validate: pingBIC }}
                  name="bic"
                  render={(field) => (
                    <CommonTextField
                      field={field}
                      label={
                        biccheck ? (
                          <div>
                            <span>BIC </span>
                            <BsCheck style={{ color: "green", size: "30" }} />
                          </div>
                        ) : (
                          <div>
                            <span>BIC </span>
                            <AiFillCloseCircle style={{ color: "red" }} />
                          </div>
                        )
                      }
                      error={errors.bic}
                      onChange={checkBIC(field)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{marginTop:10}}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="date_prelevement"
                  render={(field) => {
                    return (
                      <CommonSelect
                        field={field}
                        label={"Date de prélèvement"}
                        error={errors.date_prelevement}
                        options={[
                          { id: 1, value: 5 },
                          { id: 1, value: 10 },
                          { id: 1, value: 15 },
                        ]}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            {/* <Typography
              style={{
                fontSize: "12px",
                color: "#C31D23",
                fontStyle: "italic",
                marginTop: ".5rem",
              }}
            >
              Le prélèvement automatique des cotisations sera effectué le 08 du
              mois concerné.
            </Typography> */}

            <Box className="spacer2" />

            <FormControlLabel
              style={{
                display: "block",
                textAlign: "center",
              }}
              control={
                <Checkbox
                  value={getValues("info_contact")}
                  style={{ color: "#C31D23" }}
                  checked={getValues("info_contact")}
                  onClick={() => {
                    return getValues("info_contact") === true
                      ? setValue("info_contact", false)
                      : setValue("info_contact", true);
                  }}
                  defaultValue={false}
                  defaultChecked={false}
                  name="info_contact"
                />
              }
              name="info_contact"
              label={
                "J'accepte de recevoir les informations sur les contrats MBA Mutuelle"
              }
              ref={register("info_contact")}
            />

            <Box className="spacer2" />

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className="buttonLightRedStyle"
                  onClick={() => history.push("/Tarificateur/Garanties")}
                >
                  Précédent
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button type="submit" className="buttonGeneralStyle">
                  Générer le contrat
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </OAVLayout>
  );
};

export default OAVSouscrire;
