import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../utility";

const initializeState = {
  loginSuccess: false,
  userData: {},
  errorApi: false,
  contrat_status: [
    { id: "sent", name: "En attente signature", color: "#ff9f40" },
    { id: "signed", name: "Dossier incomplet", color: "#ff9f40" },
    { id: "waiting", name: "En attente validation", color: "#3E5D9A" },
    { id: "managment", name: "Adhésion validée", color: "#3E9A5D" },
    { id: "finished", name: "Résilié", color: "#9a3e3e" },
    { id: "no-answear", name: "Sans suite", color: "#535353" },
  ],
  editable_contrat_status: [
    { id: "sent", name: "En attente signature", color: "#ff9f40" },
    { id: "no-answear", name: "Sans suite", color: "#535353" },
  ],
  devis_status: [
    { id: "sent", name: "Envoyé", color: "#3E5D9A" },
    { id: "nouveau", name: "Nouveau", color: "#3E5D9A" },
    { id: "no-answear", name: "Sans suite", color: "#535353" },
    { id: "signed", name: "Transformé", color: "#0E6655" },
  ],
  editable_devis_status: [
    { id: "sent", name: "Envoyé", color: "#3E5D9A" },
    { id: "no-answear", name: "Sans suite", color: "#535353" },
  ],
  roles: [
    { id: 0, name: "admin" },
    { id: 1, name: "user" },
    { id: 2, name: "broker" },
  ],
  structures: [
    {
      id: "AE - Adulte",
      value: "AE - Adulte",
      code: "AE",
      label: "Adulte/Enfant",
    },
    {
      id: "AE - Enfant",
      value: "AE - Enfant",
      code: "AE",
      label: "Adulte/Enfant",
    },
    {
      id: "IDF - Isolé",
      value: "IDF - Isolé",
      code: "IDF",
      label: "Isolé/Duo/Famille",
    },
    {
      id: "IDF - Duo",
      value: "IDF - Duo",
      code: "IDF",
      label: "Isolé/Duo/Famille",
    },
    {
      id: "IDF - Famille",
      value: "IDF - Famille",
      code: "IDF",
      label: "Isolé/Duo/Famille",
    },
    {
      id: "IF - Isolé",
      value: "IF - Isolé",
      code: "IF",
      label: "Isolé/Famille",
    },
    {
      id: "IF - Famille",
      value: "IF - Famille",
      code: "IF",
      label: "Isolé/Famille",
    },
    { id: "U - Unique", value: "U - Unique", code: "U", label: "Unique" },
    {
      id: "SEC - Salarié + enfants",
      value: "SEC - Salarié + enfants",
      code: "SEC",
      label: "Salarié + enfants",
    },
    {
      id: "SEC - Conjoint",
      value: "SEC - Conjoint",
      code: "SEC",
      label: "Conjoint",
    },
    { id: "ICE - Isolé", value: "ICE - Isolé", code: "ICE", label: "Isolé" },
    {
      id: "ICE - Conjoint",
      value: "ICE - Conjoint",
      code: "ICE",
      label: "Conjoint",
    },
    { id: "ICE - Enfant", value: "ICE - Enfant", code: "ICE", label: "Enfant" },
  ],
  structures_individual: [
    {
      id: "AE - Adulte",
      value: "AE - Adulte",
      code: "AE",
      label: "Adulte/Enfant",
    },
    {
      id: "AE - Enfant",
      value: "AE - Enfant",
      code: "AE",
      label: "Adulte/Enfant",
    },
  ],
  structures_codes: [
    { id: "AE", value: "Adulte/Enfant" },
    { id: "IDF", value: "Isolé/Duo/Famille" },
    { id: "IF", value: "Isolé/Famille" },
    { id: "U", value: "Unique" },
    { id: "SEC", value: "Salarié + Enfants / Conjoint" },
    { id: "ICE", value: "Isolé/Conjoint/Enfant" },
  ],
  regimes: [
    { id: 1, value: "Régime général", market: 0 },
    { id: 2, value: "Régime général TNS", market: 0 },
    { id: 3, value: "Autres", market: 0 },
    { id: 4, value: "Régime général", market: 1 },
    { id: 5, value: "Autres", market: 1 },
  ],
  markets: [
    { id: "individual", value: "Individuel" },
    { id: "company", value: "Collectif" },
  ],
  commissions: [
    { id: 0.05, value: "5%" },
    { id: 0.07, value: "7%" },
    { id: 0.1, value: "10%" },
    { id: 0.12, value: "12%" },
    { id: 0.14, value: "14%" },
    { id: 0.15, value: "15%" },
    { id: 0.17, value: "17%" },
  ],
  commissions_indiv: [
    { id: 0.07, value: "7%" },
    { id: 0.1, value: "10%" },
    { id: 0.14, value: "14%" },
    { id: 0.17, value: "17%" },
  ],
  commissions_company: [
    { id: 0.05, value: "5%" },
    { id: 0.07, value: "7%" },
    { id: 0.12, value: "12%" },
    { id: 0.15, value: "15%" },
  ],
  modalites: [
    { id: 1, value: "Prélèvement" },
    { id: 2, value: "Chèque" },
  ],
  civilites: [
    { id: 1, value: "Mr" },
    { id: 2, value: "Mme" },
  ],
  id_type: [
    { id: 1, value: "Passeport" },
    { id: 2, value: "Carte d'identité" },
    { id: 3, value: "Titre de séjour" },
  ],
  periodicites: [
    { id: 1, value: "Mensuellement" },
    { id: 2, value: "Trimestriellement" },
  ],

  dates_prelevement: [
    { id: 1, value: 5 },
    { id: 2, value: 8 },
    { id: 3, value: 10 },
    { id: 4, value: 15 },
  ],
  colleges: [
    { id: 1, value: "Ensemble du personnel" },
    {
      id: 2,
      value:
        "Cadres (salariés relevant de l’article 2.1 de l’ANI Prévoyance du 17/11/17 dans les conditions de l’article 3 de cet ANI)",
    },
    {
      id: 3,
      value:
        "Cadres (salariés relevant des articles 2.1 et 2.2 de l’ANI Prévoyance du 17/11/17 dans les conditions de l’article 3 de cet ANI )",
    },
    {
      id: 4,
      value:
        "Non cadres (salariés ne relevant pas de l’article 2.1 de l’ANI Prévoyance du 17/11/17 dans les conditions de l’article 3 de cet ANI )",
    },
    {
      id: 5,
      value:
        "Non cadres (salariés ne relevant pas des articles 2.1 et 2.2 de l’ANI  Prévoyance du 17/11/17 dans les conditions de l’article 3 de cet ANI )",
    },
  ],
  priceTypes: [
    { id: 1, value: "€" },
    { id: 2, value: "% PMSS" },
  ],
  zones: [
    {
      id: 1,
      zone: "Zone MBA 1",
      caps: [
        "14",
        "16",
        "17",
        "22",
        "29",
        "35",
        "44",
        "49",
        "50",
        "53",
        "56",
        "61",
        "72",
        "79",
        "85",
      ],
    },
    {
      id: 2,
      zone: "Hors Zone MBA 2",
      caps: [
        "08",
        "09",
        "10",
        "11",
        "12",
        "18",
        "19",
        "20",
        "2A",
        "2B",
        "21",
        "23",
        "24",
        "25",
        "27",
        "28",
        "30",
        "31",
        "32",
        "33",
        "34",
        "36",
        "37",
        "39",
        "40",
        "41",
        "45",
        "46",
        "47",
        "48",
        "51",
        "52",
        "54",
        "55",
        "57",
        "58",
        "64",
        "65",
        "66",
        "67",
        "68",
        "70",
        "71",
        "76",
        "78",
        "81",
        "82",
        "86",
        "87",
        "88",
        "89",
        "90",
        "971",
        "972",
        "973",
        "974",
        "975",
        "976",
      ],
    },
    {
      id: 3,
      zone: "Hors Zone MBA 3",
      caps: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "13",
        "15",
        "26",
        "38",
        "42",
        "43",
        "59",
        "60",
        "62",
        "63",
        "69",
        "73",
        "74",
        "75",
        "77",
        "78",
        "80",
        "83",
        "84",
        "91",
        "92",
        "93",
        "94",
        "95",
      ],
    },
  ],
  status: [
    { id: 0, name: "Inactif", base: "inactive" },
    { id: 1, name: "Actif", base: "active" },
  ],
  market: [
    { id: 0, name: "Individuel" },
    { id: 1, name: "Collectif" },
  ],
  packs: [],
  products: [],
  garanties: [],
};

const reducer = (state = initializeState, action) => {
  switch (action.type) {
    case actionTypes.POPULATE_USER_DATA:
      return updateObject(state, { userData: action.userData });
    case actionTypes.SAVE_PACKS:
      return { ...state, packs: action.packs };
    case actionTypes.SAVE_PRODUCTS:
      return { ...state, products: action.products };
    case actionTypes.SAVE_GARANTIES:
      return { ...state, garanties: action.garanties };
    case actionTypes.SAVE_ASSOC:
      return { ...state, associations: action.associations };
    case actionTypes.SAVE_ACTIVITY:
      return { ...state, activities: action.activities };
    case actionTypes.SAVE_PROFESSIONS:
      return { ...state, professions: action.professions };
    case actionTypes.ERROR_ON_API:
      return updateObject(state, { errorApi: true });
    case actionTypes.CLEAN_USER_DATA:
      return updateObject(state, { userData: {}, loginSuccess: false });
    default:
      return state;
  }
};

export default reducer;
