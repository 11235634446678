import Box from '@mui/material/Box';
import {Divider, Typography} from "@mui/material";


const RendorEditorHtml = ({title,html_text}) => {
    return (
        <Box sx={{ bgcolor: 'background.paper', p: 2, mb: 4, mx: 'auto', textAlign: 'initial'}}>
            <Typography variant="h5" component="h5">
                {title}
            </Typography>
            <Divider sx={{ width: '100%', mb: 2 }} />
            <div dangerouslySetInnerHTML={{ __html: html_text }} />
        </Box>
    )
}

export default RendorEditorHtml
