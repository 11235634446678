import { authApiAxios } from "../AxiosInstance";

export const me = () => {
  return authApiAxios.get("me");
};

export const getmypicture = () => {
  return authApiAxios.get("me-picture");
};

export const checkPassword = (data) => {
  return authApiAxios.post("check-password", data)
}

export const changeProfile = (data) => {
  return authApiAxios.post("change-profile", data)
}

export const changePassword = (data) => {
  return authApiAxios.post("change-password", data)
}


