import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { Typography, TextField, Grid } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import zxcvbn from 'zxcvbn';



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));


const PasswordCheck = ({ setState }) => {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [color, setColor] = useState("success");
  const [confirmColor, setConfirmColor] = useState("error");
  const [passwordScore, setPasswordScore] = useState(0);
  const [complexity, setComplexity] = useState('Faible');
  const [identicalPassword, setIdenticalPassword] = useState('');
  const [identicalPasswordText, setIdenticalPasswordText] = useState('Les mots de passe sont différents');
  const passwordsScore = zxcvbn(password);



  useEffect(() => {
    const num = passwordsScore.score * 100 / 4;
    setPasswordScore(num);

    if (num < 25) {
      setComplexity('Faible');
      setColor('error');
    }
    if (num >= 25 && num < 50) {
      setComplexity('Moyenne');
      setColor('warning');
    }
    if (num >= 50 && num < 75) {
      setComplexity('Correcte');
      setColor('primary');
    }
    if (num >= 75 && num < 100) {
      setComplexity('Bonne');
      setColor('success');
    }
    if (num > 75) {
      setComplexity('Excellente');
      setColor('success');
    }

  }, [password])




  useEffect(() => {

    if (password === confirmPassword) {
      setIdenticalPassword(true);
      setConfirmColor('success');
      setIdenticalPasswordText('Les mots de passe sont identiques');
    }
    else {
      setIdenticalPassword(false);
      setConfirmColor('error');
      setIdenticalPasswordText('Les mots de passe sont différents');
    }

    setState({
      password: password,
      confirmPassword: confirmPassword,
      identical: identicalPassword,
      complexity: complexity,
    })
 
  }, [password, confirmPassword, identicalPassword])


  



  return (
    <>
      <Grid item xs={12}>
        <TextField
          label="Nouveau mot de passe"
          variant="outlined"
          name="password"
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
      </Grid>

      <Grid item xs={12} style={{ paddingBottom: 10, paddingTop: 5, margin: 1 }}>
        <BorderLinearProgress variant="determinate" value={passwordScore} color={color} style={{ marginBottom: '5px' }} />
        <Typography variant='body' color={color}>Sécurité du mot de passe : <bold >{complexity}</bold></Typography>
      </Grid>


      <Grid item xs={12}>
        <TextField
          label="Confirmer le mot de passe"
          variant="outlined"
          name="confirmPassword"
          fullWidth
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
        />
      </Grid>


      {confirmPassword !== '' &&
        <Grid item xs={12} style={{ paddingBottom: 10, paddingTop: 5, margin: 1 }}>
          <BorderLinearProgress variant="determinate" value={100} color={confirmColor} style={{ marginBottom: '5px' }} />
          <Typography variant='body' style={{ marginTop: '50px' }}>{identicalPasswordText}</Typography>
        </Grid>
      }
    </>
  )
}

export default PasswordCheck;