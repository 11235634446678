import {Box, Paper, Typography} from "@material-ui/core";
import React from "react";

const WarrantyCard = ({label, rates, checked, color, onClick, warranty}) => {



    console.log(warranty)

    return(
        <Paper
            onClick={onClick}
            elevation={3}
            style={{
                cursor: "pointer",
                margin: ".3rem",
                padding: ".8rem",
                border: "1px solid #FFFFFF30",
                backgroundColor: checked ? color : "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
            }}
        >
            <div
                style={{
                minWidth:"250px",
                maxWidth:"250px",
                border: "3px solid",
                borderRadius: "4px",
                borderColor: checked ? "#FFFFFF" : color,
                boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
            }}
            >
                <Box
                    style={{
                        background: checked ? color : "#FFFFFF",
                        color: "white",
                        minHeight: "130px",
                        padding: ".5rem",
                        borderRadius: "4px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        style={{
                            color: checked ? "#FFFFFF" : color,
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "1rem",
                            width: "100%",
                            maxWidth:"160px",
                            minWidth:"160px"
                        }}
                    >
                        {label}
                    </Typography>
                    {rates.map((rate, index) =>
                        <Typography
                            key={index}
                            style={{
                                color: checked ? "#FFFFFF" : color,
                                textAlign: "center",
                                fontSize: "14px",
                            }}
                        >
                            {rate}
                    </Typography>)}
                </Box>
            </div>
        </Paper>
    );
};

export default WarrantyCard