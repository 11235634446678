import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
} from "@material-ui/core";
import { BsArrowRightShort } from "react-icons/bs";
import { BsArrowLeftShort } from "react-icons/bs";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { Link, useHistory, useParams } from "react-router-dom";
import { formatErrorEmail } from "../../../utils/formValidation";
import text from "../../../utils/text";
import { login } from "../../../Axios/Call/ClientCredential";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator from "../../../Store/action/index";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import AuthLayout from "../../../Components/Auth/Registration/AuthLayout";
import LoaderCircular from "../../../Components/Commons/Loading/LoaderCircular";
import Links from "../../../Components/Commons/Links/Links";
import { resetPasswordApi } from "../../../Axios/Call/ClientCredential";
import {
  errorPasswordNotSame,
  formatErrorLetters,
  formatErrorPhoneNumber,
} from "../../../utils/formValidation";
import PasswordCheck from "../../../Components/Commons/PasswordCheck/PasswordCheck";

const ResetPassword = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const history = useHistory();
  const { token } = useParams();
  const { register, handleSubmit, errors, watch: watch2,register: register2, errors: errors2} = useForm({
    mode: "onBlur",
  }); // initialise the hook

 
  const [newPassword, setnewPassword] = useState("");
  const [newPasswordBis, setnewPasswordBis] = useState("");
  const [identical, setIdentical] = useState('');
  const [identicalAlert, setIdenticalAlert] = useState(false);
  const [complexity, setComplexity] = useState('');

  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    identical: identical,
    complexity:'',
  });

  useEffect(() => {
    setIdentical(state.identical);
    setnewPassword(state.password);
    setnewPasswordBis(state.confirmPassword);
    setComplexity(state.complexity);

    //console.log(identical)
  }, [state]);



  const sendFormLogin = (data) => {


    if (identical === true) {
      setLoadingApi(true);
      resetPasswordApi({ password:newPassword , reset_token: token })
        .then((response) => {
          setEmailSuccess(true);
          setLoadingApi(false);
          setTimeout(() => {
            history.push("/");
          }, 10);
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    }
  
    else
    setIdenticalAlert(true);
    

    
  };

  return (
    <AuthLayout>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.RecoverPassword.step2FooterText}
        />
      )}

      {emailSuccess && (
        <AlertGeneral
          color="success"
          setTrigger={setEmailSuccess}
          description={"Votre mot de passe a bien été mis à jour"}
        />
      )}
        {identicalAlert && (
        <AlertGeneral
          color="danger"
          setTrigger={setIdenticalAlert}
          description={"Les mots de passe doivent être identiques"}
        />
      )}

      <Box style={{ textAlign: "left" }}>
        <Typography component="h2" variant="h1" style={{ fontSize: "33px" }}>
          {/* {text.Login.title} */}
          Nouveau mot de passe
        </Typography>

        <Typography
          component="h2"
          variant="h3"
          style={{ fontSize: "14px", color: "#222" }}
        >
          Changement de mot de passe
        </Typography>
      </Box>
      <form
        onSubmit={handleSubmit(sendFormLogin)}
        style={{ marginTop: 25 }}
        noValidate
      >
        <Grid container>
        <Grid item xs={12}>
            <PasswordCheck setState={setState} />
          </Grid>

          <Link
            to={"/"}
            style={{ textDecoration: "none", margin: "0.4rem auto" }}
          >
            <Typography component="span" className={"linkBottomGrey"}>
              Retour à la connexion
            </Typography>
          </Link>

          <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
            {loadingApi ? (
              <LoaderCircular />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                width="auto"
                className={"buttonGeneralStyle"}
                endIcon={<BsArrowRightShort />}
              >
                Changer mon mot de passe
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      <Links />
    </AuthLayout>
  );
};

export default ResetPassword;
