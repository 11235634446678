import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { cleanOavData } from "../../../Store/action/oav";
import { useHistory } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";
import {
  Grid,
  Typography,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  Divider,
} from "@material-ui/core";
import CommonDateTimePicker from "../../FormComponents/CommonDateTimePicker";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import CommonTextField from "../../FormComponents/CommonTextField";
import OAVLayout from "../OAVComponents/OAVLayout";
import OAVSynthese from "../OAVComponents/OAVSynthese";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import LoaderGeneration from "../../Commons/Loading/LoaderGeneration";
import {
  formatErrorZip,
  formatErrorPhoneNumber,
  formatErrorSSN,
  formatErrorEmail,
  errorDateJPlus1,
} from "../../../utils/formValidation";
import { pingBIC, pingIBAN } from "../../../utils/iban";
import {
  addQuote,
  patchQuote,
  getQuote,
} from "../../../Axios/Call/ClientCredential";
import { getAllProfessions } from "../../../Axios/Call/ClientCredential";
import { formatErrorBeforeDate } from "../../../utils/formValidation";
import moment from "moment";
import "moment/locale/fr";
import CommonSelect from "../../FormComponents/CommonSelect";

moment.locale("fr");

const OAVSouscrire = () => {
  const [successapi, setsuccessapi] = useState(false);
  const [errorapi, seterrorapi] = useState(false);
  const [loading, setloading] = useState(false);

  const oav = useSelector((state) => state.oav.data);

  console.log("oav romain", oav);

  console.log(oav);
  const broker_id = useSelector(
    (state) => state.user.userData.broker_id || state.user.userData.id
  );
  const regimes = useSelector((state) => state.user.regimes);
  const modalites = useSelector((state) => state.user.modalites);
  const civilites = useSelector((state) => state.user.civilites);
  const id_type = useSelector((state) => state.user.id_type);
  const periodicites = useSelector((state) => state.user.periodicites);
  const dates_prelevement = useSelector(
    (state) => state.user.dates_prelevement
  );

  const ref_quote_id_const = oav?.reprise?.quote ? oav?.reprise?.quote : null;

  const [professions, setprofessions] = useState([]);
  const [ibancheck, setibancheck] = useState(false);
  const [biccheck, setbiccheck] = useState(false);
  const [ssncheck, setssncheck] = useState(false);
  const [typeQuote, setTypeQuote] = useState("subscription");

  const history = useHistory();
  const dispatch = useDispatch();
  console.log("Starting :" + moment(oav?.starting_date).format("YYYY-MM-DD"));
  console.log(moment().format("YYYY-MM-DD"));

  // Form steps and settings

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
    unregister,
    clearErrors,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "all",
    shouldDirty: true,
    defaultValues: {
      starting_date:
        moment(oav?.starting_date).format("YYYY-MM-DD") >
        moment().format("YYYY-MM-DD")
          ? oav?.starting_date
          : new Date(moment().add(1, "days")),
      bic: oav?.bic_payment,
      iban: oav?.iban_payment,

      id_type: oav?.id_type ? oav.id_type : 1,
      id_number: oav?.personnalData?.id_number,
      delivery_date: oav?.personnalData?.id_date,
      delivery_city: oav?.personnalData?.id_place,

      address: oav?.personnalData?.address,
      bank_account_owner: oav?.bank_account_owner,
      birth_name: oav?.personnalData?.birthName,
      city:
        typeof oav?.zip_code === "string"
          ? oav?.personnalData?.city
          : oav?.zip_code?.place?.place,
      civilite: oav?.personnalData?.civility ? oav?.personnalData?.civility : 1,
      datedeliver: oav?.datedeliver,
      deliverCity: oav?.deliverCity,
      email: oav?.personnalData?.email,
      firt_name: oav?.personnalData?.firstname,
      last_name: oav?.personnalData?.lastname,
      ssn: oav?.personnalData?.ssn,
      joint_birth_date: oav?.joint_birth_date,
      joint_regime: parseInt(oav?.joint_regime),
      joint_ssn: oav?.joint_ssn,
      joint_first_name: oav?.joint_first_name,
      joint_last_name: oav?.joint_last_name,
      periodicite: oav?.periodicite ? oav?.periodicite : 1,
      phone: oav?.personnalData?.phone,
      birth_date: oav?.birth_date,
      regime: oav?.regime ? oav?.regime : 1,
      zip_code:
        typeof oav?.zip_code === "string"
          ? oav.zip_code
          : oav?.zip_code?.place?.zipcode,
      enfant: oav?.enfant,
      modalite: oav?.modalite ? parseInt(oav?.modalite) : 1,
      date_prelevement: 1,
      ref_quote_id: ref_quote_id_const,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "enfant",
  });

  const createDataSub = (
    broker_id,
    product_id,
    profession_id,
    madelin,
    starting_date,
    birth_date,
    firt_name,
    last_name,
    birth_name,
    civilite,
    email,
    phone,
    zip_code,
    city,
    address,
    regime,
    ssn,
    structure_cotisation,
    id_number,
    id_type,
    delivery_date,
    delivery_city,
    joint_first_name,
    joint_last_name,
    joint_birth_date,
    joint_regime,
    joint_ssn,
    taux,
    price,
    total_price,
    iban,
    bic,
    bank_account_owner,
    iban_payment,
    bic_payment,
    bank_account_owner_payment,
    periodicite,
    modalite,
    date_prelevement,
    info_contact,
    childrens,
    packages,
    ref_quote_id,
    date_status
  ) => {
    const tmpchildren = JSON.stringify(childrens);
    return {
      type: "subscription",
      signed: 0,
      status_signed: "waiting",
      status_not_signed: "sended",
      devis_status: "signed",
      contract_status: "sent",
      broker_id,
      product_id,
      profession_id,
      madelin,
      starting_date,
      birth_date,
      firt_name,
      last_name,
      birth_name,
      civilite,
      email,
      phone,
      zip_code,
      city,
      address,
      regime,
      ssn,
      structure_cotisation,
      id_number,
      id_type,
      delivery_date,
      delivery_city,
      joint_first_name,
      joint_last_name,
      joint_birth_date,
      joint_regime,
      joint_ssn,
      taux,
      price,
      total_price,
      iban,
      bic,
      bank_account_owner,
      iban_payment,
      bic_payment,
      bank_account_owner_payment,
      periodicite,
      modalite,
      date_prelevement,
      info_contact,
      childrens: tmpchildren,
      packages: packages,
      ref_quote_id,
      date_status,
    };
  };

  useEffect(() => {
    if (ref_quote_id_const) {
      getQuote(ref_quote_id_const).then((res) => {
        setTypeQuote(res.data.type);
      });
    }
  }, []);

  const handleStep = (data) => {
    setloading(true);
    const QSP = [];
    for (const [key, value] of Object.entries(oav)) {
      if (key === "base") {
        if (value !== 0 && value !== "0") {
          QSP.push({ id: oav?.pack, guarantee_id: value * 1, type: "base" });
        }
      }
      if (key === "renfort") {
        oav.renfort.forEach((renfortId) => {
          if (value.length > 0) {
            QSP.push({
              id: oav?.pack,
              guarantee_id: renfortId,
              type: "renfort",
            });
          }
        });
      }
      if (key === "option") {
        oav.option.forEach((optionId) => {
          if (value.length > 0) {
            QSP.push({ id: oav?.pack, guarantee_id: optionId, type: "option" });
          }
        });
      }
      if (key === "supp") {
        oav.supp.forEach((suppId) => {
          if (value.length > 0) {
            QSP.push({ id: oav?.pack, guarantee_id: suppId, type: "supp" });
          }
        });
      }
    }
    //console.log("oav", oav);
    //console.log("data", data);
    const tmpdata = createDataSub(
      broker_id,
      oav.product,
      oav.profession_id,
      oav.madelin,
      data.starting_date,
      data.birth_date,
      data.firt_name,
      data.last_name,
      data.birth_name,
      data.civilite,
      data.email,
      data.phone,
      data.zip_code,
      data.city,
      data.address,
      oav.regime,
      data.ssn,
      oav?.structure_cotisation,
      data.id_number,
      data.id_type,
      data.delivery_date,
      data.delivery_city,
      data.joint_first_name,
      data.joint_last_name,
      data.joint_birth_date,
      oav?.joint_regime,
      data.joint_ssn,
      oav.selectedCommission,
      oav.price,
      oav.total_price,
      data.iban || oav.iban,
      data.bic || oav.bic,
      data.bank_account_owner || oav.bank_account_owner,
      data.iban || oav.iban,
      data.bic || oav.bic,
      data.bank_account_owner || oav.bank_account_owner,
      data.periodicite,
      data.modalite,
      data.date_prelevement,
      data.info_contact,
      data.enfant,
      JSON.stringify(QSP),
      ref_quote_id_const,
      moment().format("YYYY-MM-DD")
    );

    if (oav.reprise?.value && typeQuote === "quote") {
      patchQuote(oav.reprise?.quote, { ...tmpdata, signed: 1 })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setloading(false);
            setsuccessapi(true);
            dispatch(cleanOavData());
            history.push(`/Contrats/Individuels/${oav.reprise?.quote}`);
          } else {
            seterrorapi(true);
            setloading(false);
          }
        })
        .catch((err) => console.warn(err));
    } else {
      addQuote(tmpdata)
        .then((res) => {
          //console.log(res);
          if (res.status === 200 || res.status === 201) {
            setsuccessapi(true);
            history.push(`/Contrats/Individuels/${res.data.id}`);
            dispatch(cleanOavData());
          } else {
            seterrorapi(true);
          }
        })
        .catch((err) => console.warn(err))
        .finally(() => setloading(false));
    }
    // }
  };

  useEffect(() => {
    getAllProfessions().then((res) => {
      setprofessions(
        res?.data.map((p) => {
          return {
            id: p.id,
            value: p.name,
          };
        })
      );
    });
  }, []);

  useEffect(() => {}, [watch("civilite")]);

  useEffect(() => {
    if (parseInt(getValues("modalite")) !== 1) {
      unregister("iban");
      unregister("bic");
      unregister("periodicite");
      unregister("bank_account_owner");
    }
  }, [watch("modalite")]);

  // useEffect(() => {
  //   //console.log(getValues("joint_regime"))
  // },[watch("joint_regime")]);

  // Function to test IBAN and set borders colors to green if right
  function checkIBAN(iban) {
    const result = pingIBAN(iban.value);

    if (iban != null && iban != "") {
      if (result != true) {
        setibancheck(false);
      } else {
        setibancheck(true);
      }
    }

    return ibancheck;
  }

  // Function to test BIC and set borders colors to green if right
  function checkBIC(bic) {
    const result = pingBIC(bic.value);

    if (bic != null && bic != "") {
      if (result != true) {
        setbiccheck(false);
      } else {
        setbiccheck(true);
      }
    }
    return biccheck;
  }

  // Function to check SSN while typing
  function checkSsn(ssn) {
    const result = formatErrorSSN(ssn.value);

    if (result != null && result != "") {
      if (result != true) {
        setssncheck(false);
      } else {
        setssncheck(true);
      }
    }
  }

  return (
    <OAVLayout extra={<OAVSynthese />}>
      {successapi && (
        <AlertGeneral
          setTrigger={setsuccessapi}
          color=""
          description="Contrat enregistré !"
        />
      )}
      {errorapi && (
        <AlertGeneral
          setTrigger={seterrorapi}
          color="danger"
          description="Une erreur est survenue"
        />
      )}
      {loading ? (
        <LoaderGeneration />
      ) : (
        <>
          <Box
            maxWidth="900px"
            margin="0 auto"
            style={{
              backgroundColor: "#FFF",
              borderRadius: 5,
              padding: 40,
              marginBottom: 50,
            }}
          >
            <form
              onSubmit={handleSubmit(handleStep)}
              style={{ marginTop: 0, textAlign: "left" }}
              noValidate
            >
              <Grid container alignItems="top" justifyContent="center">
                <DirectionsRunIcon
                  width="50px"
                  fontSize="large"
                  style={{
                    color: "#C31D23",
                    marginRight: 10,
                    marginBottom: 20,
                  }}
                />
                <Typography variant="h1">
                  Souscrire un contrat individuel
                </Typography>
                <Divider
                  style={{
                    marginTop: 10,
                    marginBottom: 30,
                    color: "#C31D2330",
                  }}
                />
              </Grid>

              <Typography component="p" variant="h4">
                Date d'effet
              </Typography>
              <Divider
                style={{ marginTop: 10, marginBottom: 20, color: "#C31D2330" }}
              />
              <Controller
                name={"starting_date"}
                control={control}
                rules={{ required: true, validate: errorDateJPlus1 }}
                disabled={true}
                render={(field) => (
                  <CommonDateTimePicker
                    readOnly={true}
                    field={field}
                    name={"starting_date"}
                    label={"Date d'effet"}
                    error={errors.starting_date}
                    minDate={moment().add(1, "days")}
                    disablePast={true}
                    //errorText={"La date d'effet doit être au minimum à J+1"}
                    renderInput={(params) => (
                      <TextField onKeyDown={onKeyDown} {...params} />
                    )}
                  />
                )}
              />

              <Box className="spacer1" />

              <Typography variant="h4">Information personnelles</Typography>
              <Divider
                style={{ marginTop: 10, marginBottom: 15, color: "#C31D2330" }}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <Controller
                    control={control}
                    name="civilite"
                    rules={{ required: true }}
                    render={(field) => {
                      return (
                        <CommonSelect
                          field={field}
                          error={errors.civilite}
                          label={"Civilité"}
                          options={civilites}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="last_name"
                    render={(field) => {
                      return (
                        <CommonTextField
                          field={field}
                          label="Nom"
                          error={errors.last_name}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Controller
                    control={control}
                    rules={{}}
                    name="birth_name"
                    render={(field) => {
                      return (
                        <CommonTextField
                          field={field}
                          label="Nom de naissance"
                          error={errors.birth_name}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="firt_name"
                    render={(field) => {
                      return (
                        <CommonTextField
                          field={field}
                          label="Prénom"
                          error={errors.firt_name}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Controller
                    name={"birth_date"}
                    control={control}
                    render={(field) => (
                      <CommonDateTimePicker
                        field={field}
                        name={"birth_date"}
                        error={errors.birth_date}
                        label={"Date de naissance"}
                        disabled={true}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Controller
                    control={control}
                    name="regime"
                    defaultValue={getValues("regime")}
                    render={(field) => (
                      <CommonSelect
                        label="Régime"
                        field={field}
                        disabled={true}
                        options={regimes}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={5}>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      valueAsNumber: true,
                      validate: formatErrorSSN,
                    }}
                    name="ssn"
                    render={(field) => {
                      return (
                        <CommonTextField
                          type="number"
                          field={field}
                          label={
                            ssncheck ? (
                              <div>
                                <span style={{ fontSize: "12px" }}>
                                  N° de sécurité sociale (15 car.){" "}
                                </span>
                                <BsCheck style={{ color: "green" }} />
                              </div>
                            ) : (
                              <div>
                                <span style={{ fontSize: "12px" }}>
                                  N° de sécurité sociale (15 car.){" "}
                                </span>
                                <AiFillCloseCircle
                                  style={{ color: "#c31d23" }}
                                />
                              </div>
                            )
                          }
                          onChange={checkSsn(field)}
                          error={ssncheck ? errors.ssn : false}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>

              {oav?.joint_birth_date && (
                <>
                  <Box className="spacer1" />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h4" style={{ alignSelf: "center" }}>
                        Conjoint
                        {"  "}{" "}
                      </Typography>
                      <Divider style={{ marginTop: 10, color: "#C31D2330" }} />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="joint_first_name"
                        render={(field) => {
                          return (
                            <CommonTextField
                              field={field}
                              label="Prénom"
                              error={errors.firt_name}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="joint_last_name"
                        render={(field) => {
                          return (
                            <CommonTextField
                              field={field}
                              label="Nom"
                              error={errors.joint_last_name}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Controller
                        name={"joint_birth_date"}
                        control={control}
                        rules={{
                          required: "Champ requis",
                          validate: formatErrorBeforeDate,
                        }}
                        defaultValue={getValues("joint_birth_date")}
                        render={(field) => (
                          <CommonDateTimePicker
                            field={field}
                            name={"joint_birth_date"}
                            label={"Date de naissance"}
                            error={errors.joint_birth_date}
                            required={true}
                            disabled={true}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        control={control}
                        name="joint_regime"
                        defaultValue={getValues("joint_regime")}
                        render={(field) => (
                          <CommonSelect
                            label="Régime"
                            field={field}
                            disabled={true}
                            options={regimes}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                          valueAsNumber: true,
                          validate: formatErrorSSN,
                        }}
                        name="joint_ssn"
                        render={(field) => {
                          return (
                            <CommonTextField
                              type="number"
                              field={field}
                              label="Numéro de sécurité sociale"
                              error={errors.joint_ssn}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {fields.map(({ id }, index) => {
                return (
                  <>
                    <Box className="spacer1" />
                    <Grid
                      container
                      spacing={2}
                      key={id}
                      // style={{ margin: ".8rem 0 .8rem .8rem" }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          style={{ alignSelf: "center" }}
                        >
                          Enfant {index + 1}
                          {"  "}{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Controller
                          name={`enfant[${index}].birth_date`}
                          control={control}
                          render={() => (
                            <CommonDateTimePicker
                              field={{
                                value: fields[index]?.birth_date,
                                onChange: setValue,
                              }}
                              label={"Date de naissance"}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Controller
                          control={control}
                          name={`enfant[${index}].regime`}
                          render={(field) => (
                            <CommonSelect
                              label="Régime"
                              field={field}
                              disabled={true}
                              options={regimes}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Controller
                          control={control}
                          rules={{
                            required: true,
                            valueAsNumber: true,
                            validate: formatErrorSSN,
                          }}
                          name={`enfant[${index}].ssn`}
                          render={(field) => {
                            return (
                              <CommonTextField
                                type="number"
                                field={field}
                                label="Numéro de sécurité sociale"
                                error={errors.enfant?.[index].ssn}
                              />
                            );
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <Controller
                          name={`enfant[${index}].name`}
                          control={control}
                          rules={{ required: true }}
                          render={(field) => (
                            <CommonTextField
                              field={field}
                              label={"Prénom"}
                              error={errors.enfant?.[index].name}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Controller
                          name={`enfant[${index}].surname`}
                          control={control}
                          rules={{ required: true }}
                          render={(field) => (
                            <CommonTextField
                              field={field}
                              label={"Nom"}
                              error={errors.enfant?.[index].surname}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                );
              })}
              <Box className="spacer2" />
              <Typography variant="h4">Pièce d'identité</Typography>
              <Divider
                style={{ marginTop: 10, marginBottom: 15, color: "#C31D2330" }}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Controller
                    control={control}
                    name="id_type"
                    rules={{ required: true }}
                    defaultValue={1}
                    render={(field) => {
                      return (
                        <CommonSelect
                          field={field}
                          error={errors.id_type}
                          label={"Type de pièce"}
                          options={id_type}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="id_number"
                    render={(field) => {
                      return (
                        <CommonTextField
                          type={"text"}
                          field={field}
                          label="Numéro de pièce d'identité"
                          error={errors.id_number}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name={"delivery_date"}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={null}
                    render={(field) => (
                      <CommonDateTimePicker
                        field={field}
                        label={"Délivrée le"}
                        error={errors.delivery_date}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="delivery_city"
                    render={(field) => {
                      return (
                        <CommonTextField
                          field={field}
                          label="Délivré à"
                          error={errors.delivery_city}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Box className="spacer2" />
              <Typography variant="h4">Coordonnées</Typography>
              <Divider
                style={{ marginTop: 10, marginBottom: 15, color: "#C31D2330" }}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="address"
                    render={(field) => {
                      return (
                        <CommonTextField
                          field={field}
                          label="Adresse (numéro et libellé de voie)"
                          error={errors.address}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    control={control}
                    name="zip_code"
                    rules={{ required: true, validate: formatErrorZip }}
                    render={(field) => (
                      <CommonTextField
                        field={field}
                        label="Code postal"
                        name="zip_code"
                        error={errors.zip_code}
                        InputProps={{ disabled: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="city"
                    render={(field) => {
                      return (
                        <CommonTextField
                          field={field}
                          label="Ville"
                          error={errors.city}
                          //InputProps={{ disabled: true }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Controller
                    control={control}
                    rules={{ validate: formatErrorEmail }}
                    name="email"
                    render={(field) => {
                      return (
                        <CommonTextField
                          type={"email"}
                          field={field}
                          label="Email"
                          error={errors.email}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Controller
                    control={control}
                    rules={{ required: true, validate: formatErrorPhoneNumber }}
                    name="phone"
                    render={(field) => {
                      return (
                        <>
                          <CommonTextField
                            type={"tel"}
                            field={field}
                            label="Téléphone"
                            error={errors.phone}
                          />
                          <Typography variant="body" style={{ color: "#222" }}>
                            Pour la signature du contrat par SMS, merci de
                            renseigner le numéro de mobile (Ex : 0608090809)
                          </Typography>
                        </>
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Box className="spacer2" />

              <Typography variant="h4">Modalité de paiement</Typography>
              <Divider
                style={{ marginTop: 10, marginBottom: 15, color: "#C31D2330" }}
              />

              <Grid item xs={12} md={6}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="modalite"
                  render={(field) => {
                    return (
                      <CommonSelect
                        field={field}
                        label={"Modalité de paiement"}
                        error={errors.modalite}
                        options={modalites}
                      />
                    );
                  }}
                />
              </Grid>

              <Box className="spacer2" />

              {parseInt(getValues("modalite")) === 1 && (
                <Grid>
                  <Typography variant="h4">Coordonnées bancaires</Typography>
                  <Divider
                    style={{
                      marginTop: 10,
                      marginBottom: 15,
                      color: "#C31D2330",
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="bank_account_owner"
                        render={(field) => {
                          return (
                            <CommonTextField
                              field={field}
                              label="Titulaire du compte"
                              error={errors.bank_account_owner}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/* TODO: iban validation */}
                      <Controller
                        control={control}
                        rules={{ required: true, validate: pingIBAN }}
                        name="iban"
                        render={(field) => {
                          return (
                            <CommonTextField
                              field={field}
                              label={
                                ibancheck ? (
                                  <div>
                                    <span>IBAN </span>
                                    <BsCheck
                                      style={{ color: "green", size: "30" }}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <span>IBAN </span>
                                    <AiFillCloseCircle
                                      style={{ color: "#c31d23" }}
                                    />
                                  </div>
                                )
                              }
                              onChange={checkIBAN(field)}
                              error={errors.iban}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        rules={{ required: true }}
                        control={control}
                        name="periodicite"
                        render={(field) => {
                          return (
                            <CommonSelect
                              field={field}
                              label={"Périodicité de prélèvement"}
                              error={errors.periodicite}
                              options={periodicites}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={control}
                        rules={{ required: true, validate: pingBIC }}
                        name="bic"
                        render={(field) => {
                          return (
                            <CommonTextField
                              field={field}
                              label={
                                biccheck ? (
                                  <div>
                                    <span>BIC </span>
                                    <BsCheck
                                      style={{ color: "green", size: "30" }}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <span>BIC </span>
                                    <AiFillCloseCircle
                                      style={{ color: "#c31d23" }}
                                    />
                                  </div>
                                )
                              }
                              onChange={checkBIC(field)}
                              error={errors.bic}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        rules={{ required: true }}
                        control={control}
                        name="date_prelevement"
                        render={(field) => {
                          return (
                            <CommonSelect
                              field={field}
                              label={"Date de prélèvement"}
                              error={errors.date_prelevement}
                              options={dates_prelevement}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/* <Typography
                  style={{
                    fontSize: "12px",
                    color: "#C31D23",
                    fontStyle: "italic",
                    marginTop: ".5rem",
                  }}
                >
                  Le prélèvement automatique des cotisations sera effectué le 08
                  du mois concerné.
                </Typography> */}
                </Grid>
              )}
              <Box className="spacer2" />
              <FormControlLabel
                style={{
                  display: "block",
                  textAlign: "center",
                }}
                control={
                  <Checkbox
                    value={getValues("info_contact")}
                    style={{ color: "#C31D23" }}
                    checked={getValues("info_contact")}
                    onClick={(e) => {
                      return getValues("info_contact") === true
                        ? setValue("info_contact", false)
                        : setValue("info_contact", true);
                    }}
                    defaultValue={false}
                    defaultChecked={false}
                    name="info_contact"
                  />
                }
                name="info_contact"
                label={
                  "J'accepte de recevoir les informations sur les contrats MBA Mutuelle"
                }
                ref={register("info_contact")}
              />
              <Box className="spacer2" />
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    className="buttonLightRedStyle"
                    onClick={() => history.push("/Tarificateur/Garanties")}
                  >
                    Précédent
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    type="submit"
                    className="buttonGeneralStyle"
                    onClick={() => setValue("registerType", "online")}
                  >
                    Générer le contrat et signer
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </>
      )}
    </OAVLayout>
  );
};

export default OAVSouscrire;
