import moment from "moment";
import {
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Tooltip,
  IconButton,
  TableHead,
} from "@material-ui/core";
import {
  AiOutlineEye,
  AiOutlineCloudUpload,
  AiOutlineMail,
} from "react-icons/ai";
import { toBase64 } from "../../utils/utils";
import { useState, Fragment } from "react";
import { manuallyUploadDoc } from "../../Axios/Call/ClientCredential";
import { IoDocumentsOutline } from "react-icons/io5";
import AlertGeneral from "../Commons/AlertGeneral/AlertGeneral";
import { authApiAxios as axios } from "../../Axios/AxiosInstance";

function QuoteDocuments({ quote, onDocumentUploaded, refresh }) {
  const [viewDocument, setViewDocument] = useState("");
  const [uploadDocument, setUploadDocument] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleUploadDoc = async () => {
    await manuallyUploadDoc(quote.id, uploadDocument);
    setUploadDocument(null);
    if (typeof onDocumentUploaded === "function") {
      onDocumentUploaded();
    }
  };

  console.log(quote.docs, "docs")

  const hasAvenant = (doc, quote) => {
    if (doc.id === "devis" || (doc.id.includes("produit_supp_") && quote.type === 'quote')) {
      if (!quote.number_avenant) {
        if (quote.type === "subscription" && !quote.signed) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleSendEmail = async () => {
    await axios.post(`/quote/${quote.id}/send`);

    setSuccess(true);

    if (typeof refresh === "function") {
      refresh();
    }
  };

  return (
    <>
      {success && (
        <AlertGeneral
          setTrigger={setSuccess}
          color=""
          description="E-mail envoyé avec succès"
        />
      )}
      <Dialog
        maxWidth="md"
        fullWidth
        open={Boolean(viewDocument)}
        onClose={() => setViewDocument("")}
      >
        <DialogContent>
          {viewDocument && (
            <embed
              src={viewDocument}
              style={{ width: "100%", height: "80vh" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewDocument("")}>Fermer</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="md"
        fullWidth
        open={Boolean(uploadDocument)}
        onClose={() => setUploadDocument(null)}
      >
        <DialogContent>
          {uploadDocument && (
            <iframe
              title="pdf-doc"
              src={uploadDocument.file}
              style={{ width: "100%", height: "80vh" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDocument(null)}>Annuler</Button>
          <Button
            onClick={handleUploadDoc}
            startIcon={<AiOutlineCloudUpload size={24} />}
            variant="contained"
            color="primary"
          >
            Télécharger
          </Button>
        </DialogActions>
      </Dialog>

      <Box>
        <TableContainer
          style={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            padding: 30,
            borderRadius: 10,
          }}
        >
          <Typography
            variant="h1"
            style={{
              paddingBottom: 20,
              marginTop: 20,
              textAlign: "left",
              fontWeight: "500",
            }}
          >
            <IoDocumentsOutline
              size={44}
              style={{ marginRight: 8, paddingTop: 15, marginBottom: "-5px" }}
            />
            Documents
          </Typography>
             
          <Table>
            <TableHead>
              <TableRow>
                <Typography
                  variant="h4"
                  style={{ paddingLeft: 10, marginTop: 20 }}
                >
                  Documents liés au contrat
                </Typography>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(quote?.docs) &&
                quote.docs.map((doc) => (
                  <>
                  {(hasAvenant(doc,quote) && doc.url) && <TableRow key={doc.id}>
                    <TableCell style={{ fontWeight: "500" }}>
                      {doc.name}
                    </TableCell>
                    <TableCell>
                      {moment(doc.signed_at ?? quote.created_at).format(
                        "DD/MM/yyyy"
                      )}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          background: doc.signed_at
                            ? "rgb(62, 154, 93)"
                            : "rgb(62, 93, 154)",
                          color: "white",
                          borderRadius: 3,
                          padding: "0.3rem",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          textAlign: "center",
                        }}
                      >
                        {doc.status}
                      </div>
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {doc.url && (
                        <Tooltip title="Voir">
                          <IconButton
                            size="small"
                            edge="start"
                            onClick={() => setViewDocument(doc.url)}
                          >
                            <AiOutlineEye size={24} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!doc.signed_at && (doc.id !== "devis" && (!doc.id.includes("devis_produit_supp_"))) && (
                        <Tooltip title="Importer manuellement">
                          <IconButton component="label" size="small">
                            <AiOutlineCloudUpload size={24} />
                            <input
                              type="file"
                              accept="application/pdf"
                              hidden
                              onChange={async (e) => {
                                const file = await toBase64(e.target.files[0]);
                                setUploadDocument({ ...doc, file });
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {(!doc.signed_at && (doc.id === "devis" || (doc.id.includes("produit_supp_"))) && quote.type === 'quote' && quote.email) && (
                        <Tooltip title="Envoyer par email">
                          <IconButton size="small" onClick={handleSendEmail}>
                            <AiOutlineMail size={24} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>}
                  </>
                ))}
              {quote.personalDocs && Array.isArray(quote.personalDocs) && (
                <Fragment>
                  <TableRow>
                    <TableCell colspan={4}>
                      <Typography variant="h4">Documents personnels</Typography>
                    </TableCell>
                  </TableRow>
                  {quote.personalDocs.map((doc) => (
                    <TableRow key={doc.id}>
                      <TableCell style={{ fontWeight: "500" }}>
                        {doc.name}
                      </TableCell>
                      <TableCell>
                        {moment(doc.signed_at ?? quote.created_at).format(
                          "DD/MM/yyyy"
                        )}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            background: doc.signed_at
                              ? "rgb(62, 154, 93)"
                              : "rgb(62, 93, 154)",
                            color: "white",
                            borderRadius: 3,
                            padding: "0.3rem",
                            fontWeight: "600",
                            textTransform: "capitalize",
                            textAlign: "center",
                          }}
                        >
                          {doc.status}
                        </div>
                      </TableCell>
                      <TableCell padding="checkbox">
                        {doc.url && (
                          <Tooltip title="Voir">
                            <IconButton
                              size="small"
                              edge="start"
                              onClick={() => setViewDocument(doc.url)}
                            >
                              <AiOutlineEye size={24} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!doc.signed_at && (
                          <Tooltip title="Importer manuellement">
                            <IconButton component="label" size="small">
                              <AiOutlineCloudUpload size={24} />
                              <input
                                type="file"
                                accept="application/pdf"
                                hidden
                                onChange={async (e) => {
                                  const file = await toBase64(
                                    e.target.files[0]
                                  );
                                  setUploadDocument({ ...doc, file });
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              )}
              {quote.extraDocs &&
                Object.entries(quote.extraDocs)
                  .filter(
                    ([_, value]) =>
                      Boolean(value) && Array.isArray(value) && value.length
                  )
                  .map(([key, value]) => (
                    <Fragment key={key}>
                      <TableRow>
                        <TableCell colspan={4}>
                          <Typography color="primary" variant="h4">
                            {key}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {value.filter(document => document.ending_date ? document.ending_date >= quote.starting_date : document)?.map((doc) => (
                        <TableRow key={doc.name}>
                          <TableCell colspan={3} style={{ fontWeight: "500" }}>
                            {doc.name}
                          </TableCell>
                          <TableCell padding="checkbox">
                            {doc.url && (
                              <Tooltip title="Voir">
                                <IconButton
                                  size="small"
                                  edge="start"
                                  onClick={() => setViewDocument(doc.url)}
                                >
                                  <AiOutlineEye size={24} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </Fragment>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default QuoteDocuments;
