import React from 'react';
import { Typography } from '@mui/material';
import { BarChart, ResponsiveContainer, Bar, Tooltip, XAxis, YAxis } from 'recharts';

const VerticalBarChartCustom = ({ title, data }) => {
  return (
    <>
      <Typography variant="h4" m={3} style={{fontSize:25, fontWeight:500}}>
        {title}
      </Typography>
      <ResponsiveContainer height={350} >
        <BarChart
          margin={{ top: 20, right: 51, left: 50, bottom: 20 }}
          data={data}
          layout="vertical"
          style={{
            fontSize: 12,
            fontFamily: ['Roboto', '-apple-system'].join(','),
            color: '#28395A',
            fontWeight:500,
            padding:10,
      
          }}
        >
          <XAxis type={'number'} />
          <YAxis dataKey="name" type="category" />
          <Tooltip />

          <Bar dataKey="value" stackId="a" fill="#c31d23" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default VerticalBarChartCustom;
