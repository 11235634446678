import React, {useState, useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {Grid, Typography, Button, Box, Divider} from "@material-ui/core";
import {
    formatErrorZip,
    formatErrorPhoneNumber,
    formatErrorEmail,
} from "../../../utils/formValidation";
import OAVLayout from "../OAVComponents/OAVLayout";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import {useDispatch, useSelector} from "react-redux";
import {updateOav} from "../../../Store/action/oav";
import {useHistory} from "react-router-dom";
import OAVSynthese from "../OAVComponents/OAVSynthese";
import CommonTextField from "../../FormComponents/CommonTextField";
import {addQuote} from "../../../Axios/Call/ClientCredential";
import CommonSelect from "../../FormComponents/CommonSelect";
import {getAllProfessions} from "../../../Axios/Call/ClientCredential";
import LoaderGeneration from "../../Commons/Loading/LoaderGeneration";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";

const OAVDevis = () => {
    const [loading, setLoading] = useState(false);
    const [successapi, setsuccessapi] = useState(false);
    const [errorapi, seterrorapi] = useState(false);
    const [professions, setprofessions] = useState([]);
    const oav = useSelector(state => state.oav.data);
    const broker_id = useSelector(state => state.user.userData.broker_id || state.user.userData.id);
    const civilites = useSelector(state => state.user.civilites);
    const history = useHistory();
    const dispatch = useDispatch();

    // Form steps and settings

    const {handleSubmit, errors, control, getValues, setValue, register} =
        useForm({
            mode: "onBlur",
            reValidateMode: "all",
            defaultValues: {
                civilite: oav?.personnalData?.civilite
                    ? oav?.personnalData?.civilite
                    : 1,
                firt_name: oav?.personnalData?.firstname,
                last_name: oav?.personnalData?.lastname,
                address: oav?.personnalData?.address,
                zip_code: typeof oav?.zip_code === "string"
                    ? oav.zip_code
                    : oav?.zip_code?.place?.zipcode,
                city: typeof oav?.zip_code === "string"
                    ? oav?.personnalData?.city
                    : oav?.zip_code?.place?.place,
                phone: oav?.personnalData?.phone,
                email: oav?.personnalData?.email,
            },
        });

    const createDataSub = (
        broker_id,
        product_id,
        profession_id,
        madelin,
        starting_date,
        birth_date,
        firt_name,
        last_name,
        civilite,
        email,
        phone,
        zip_code,
        city,
        address,
        regime,
        ssn,
        structure_cotisation,
        id_number,
        id_type,
        delivery_date,
        delivery_city,
        joint_first_name,
        joint_last_name,
        joint_birth_date,
        joint_regime,
        joint_ssn,
        taux,
        price,
        total_price,
        meta,
        iban,
        bic,
        bank_account_owner,
        iban_payment,
        bic_payment,
        bank_account_owner_payment,
        periodicite,
        modalite,
        childrens,
        ref_quote_id,
        packages
    ) => {
        const tmpchildren = childrens?.map((c) => {
            return {
                regime: c.regime,
                name: c.name || "",
                surname: c.surname || "",
                birth_date: c.birth_date,
                ssn: c.ssn || "",
            };
        });
        return {
            type: "quote",
            signed: 0,
            status_signed: "waiting",
            status_not_signed: "sended",
            devis_status: "sent",
            contract_status: "sent",
            broker_id,
            product_id,
            profession_id,
            madelin,
            starting_date,
            birth_date,
            firt_name,
            last_name,
            civilite,
            email,
            phone,
            zip_code,
            city,
            address,
            regime,
            ssn,
            structure_cotisation,
            id_number,
            id_type,
            delivery_date,
            delivery_city,
            joint_first_name,
            joint_last_name,
            joint_birth_date,
            joint_regime,
            joint_ssn,
            taux,
            price,
            total_price,
            meta,
            iban,
            bic,
            bank_account_owner,
            iban_payment,
            bic_payment,
            bank_account_owner_payment,
            periodicite,
            modalite,
            childrens: JSON.stringify(tmpchildren),
            ref_quote_id,
            packages: packages,
        };
    };

    const handleStep = (data) => {
        setLoading(true);
        for (const [key, value] of Object.entries(data)) {
            dispatch(updateOav(key, value));
        }
        const QSP = [];
        //console.log(oav);
        for (const [key, value] of Object.entries(oav)) {
            if (key === "base") {
                if (value !== 0 && value !== "0") {
                    QSP.push({id: oav?.pack, guarantee_id: value * 1, type: "base"});
                }
            }
            if (key === "renfort") {
                oav.renfort.forEach((renfortId) => {
                    if (value.length > 0) {
                        QSP.push({
                            id: oav?.pack,
                            guarantee_id: renfortId,
                            type: "renfort",
                        });
                    }
                });
            }
            if (key === "option") {
                oav.option.forEach((optionId) => {
                    if (value.length > 0) {
                        QSP.push({id: oav?.pack, guarantee_id: optionId, type: "option"});
                    }
                });
            }
            if (key === "supp") {
                oav.supp.forEach((suppId) => {
                    if (value.length > 0) {
                        QSP.push({id: oav?.pack, guarantee_id: suppId, type: "supp"});
                    }
                });
            }
        }
        //console.log(oav, data);
        //console.log("QSP", QSP);
        const tmpdata = createDataSub(
            broker_id,
            oav.product,
            oav.profession_id,
            oav.madelin,
            oav.starting_date,
            oav.birth_date,
            data.firt_name,
            data.last_name,
            data.civilite,
            data.email,
            data.phone,
            data.zip_code,
            data.city,
            data.address,
            oav.regime,
            data.ssn,
            oav?.structure_cotisation,
            data.id_number,
            data.id_type,
            data.delivery_date,
            data.delivery_city,
            oav.joint_first_name,
            oav.joint_last_name,
            oav.joint_birth_date,
            oav.joint_regime,
            data.joint_ssn,
            oav.selectedCommission,
            oav.price,
            oav.total_price,
            oav.meta,
            data.iban,
            data.bic,
            data.bank_account_owner,
            data.iban,
            data.bic,
            data.bank_account_owner,
            data.periodicite,
            data.modalite,
            oav.enfant,
            data.ref_quote_id,
            JSON.stringify(QSP)
        );

        //console.log({ tmpdata });

        addQuote(tmpdata)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    setsuccessapi(true);
                    history.push(`/Devis/Individuels/${res.data.id}`);
                } else {
                    seterrorapi(true);
                }
            })
            .catch((err) => console.warn(err))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getAllProfessions().then((res) => {
            setprofessions(res?.data?.map((p) => {
                return {
                    id: p.id,
                    value: p.name,
                };
            }));
        });
    }, []);

    return (
        <OAVLayout extra={<OAVSynthese/>}>
            {successapi && (<AlertGeneral setTrigger={setsuccessapi} color="" description="Devis enregistré"/>)}
            {errorapi && (<AlertGeneral setTrigger={seterrorapi} color="danger" description="Une erreur est survenue"/>)}
            {loading ? (<LoaderGeneration/>) : (
                <Box maxWidth="900px" margin="0 auto">
                    <form onSubmit={handleSubmit(handleStep)} style={{marginTop: 20, textAlign: "left"}} noValidate>

                        <Grid container alignItems="center">
                            <DirectionsRunIcon width="50px" fontSize="large" style={{color: "#C31D23", marginRight: 10}}/>
                            <Typography variant="h1">Devis individuel</Typography>
                        </Grid>
                        <Divider style={{marginTop: 10, marginBottom: 30, color: "#C31D2330"}}/>

                        <Box className="spacer2"/>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="civilite"
                                    render={(field) => {
                                        return (
                                            <CommonSelect
                                                field={field}
                                                label="Civilité"
                                                error={errors.civilite}
                                                options={civilites}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="last_name"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Nom"
                                                error={errors.last_name}
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={5}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="firt_name"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Prénom"
                                                error={errors.firt_name}
                                            />
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="address"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Adresse (numéro et libellé de voie)"
                                                error={errors.address}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Controller
                                    control={control}
                                    name="zip_code"
                                    rules={{required: true, validate: formatErrorZip}}
                                    render={(field) => (
                                        <CommonTextField
                                            field={field}
                                            label="Code postal"
                                            name="zip_code"
                                            InputProps={{disabled: true}}
                                            error={errors.zip_code}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="city"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                field={field}
                                                label="Ville"
                                                error={errors.city}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    rules={{validate: formatErrorEmail}}
                                    name="email"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                type={"email"}
                                                field={field}
                                                label="Email"
                                                error={errors.email}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    rules={{required: true, validate: formatErrorPhoneNumber}}
                                    name="phone"
                                    render={(field) => {
                                        return (
                                            <CommonTextField
                                                type={"tel"}
                                                field={field}
                                                label="Téléphone"
                                                error={errors.phone}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box className="spacer2"/>

                        {/* <Grid container > */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Button className="buttonLightRedStyle" onClick={() => history.push("/Tarificateur/Garanties")}>
                                    Précédent
                                </Button>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Button type="submit" className="buttonGeneralStyle" onClick={() => setValue("quote_type", "download")}>
                                    Générer le devis
                                </Button>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Button className="buttonGreenStyle" onClick={() => history.push("/Tarificateur/Besoin")}>
                                    Nouveau devis
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            )}
        </OAVLayout>
    );
};

export default OAVDevis;
