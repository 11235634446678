import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";

const Back = () => {
  const history = useHistory();
  return (
    <div
      style={{
        borderRadius: "50%",
        background: "#C31D23",
        height: "35px",
        width: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: ".8rem",
        cursor: "pointer",
     
      }}
      onClick={() => history.goBack()}
    >
      <BiArrowBack fill="#ffffff" size={25} />
    </div>
  );
};

export default Back;
