import "./App.css";

/* Components */
import Login from "./AuthPages/Login/Login";
import RecoverPassword from "./AuthPages/Login/RecoverPassword";
// import Registration from "./AuthPages/Registration/Registration.js";
//----------- Error ------------
import Page404 from "./ErrorPage/Page404";
import Page403 from "./ErrorPage/Page403";

/* Route */
// eslint-disable-next-line no-unused-vars
import {
  Route,
  Router as BrowserRouter,
  Switch,
  useHistory,
  Redirect,
} from "react-router-dom";

import LayoutLogged from "../Hoc/LayoutLogged";
import NavLayout from "../Components/NavLayout/NavLayout";

import Profil from "./Profil/Profil";
import Documents from "./Documents/Documents";
import DocumentsGeneric from "./Documents/Sections/DocumentsGeneric";
import MesDocuments from "./Documents/Sections/MesDocuments";
import OAVBesoin from "../Components/OAV/OAVBesoin/OAVBesoin";
import OAVIndividuelProfil from "../Components/OAV/OAVProfil/OAVIndividuelProfil";
import OAVIndividuelDevis from "../Components/OAV/OAVDevis/OAVIndividuelDevis";
import OAVIndividuelSouscrire from "../Components/OAV/OAVSouscrire/OAVIndividuelSouscrire";
import OAVCollectifProfil from "../Components/OAV/OAVProfil/OAVCollectifProfil";
import OAVCollectifDevis from "../Components/OAV/OAVDevis/OAVCollectifDevis";
import OAVCollectifSouscrire from "../Components/OAV/OAVSouscrire/OAVCollectifSouscrire";
import Contrats from "./Contrats/Contrats";
import ContratsIndividuels from "./Contrats/Sections/Individuel";
import ContratsCollectifs from "./Contrats/Sections/Collectif";
import Devis from "./Devis/Devis";
import DevisIndividuels from "./Devis/Sections/Individuel";
import DevisCollectifs from "./Devis/Sections/Collectif";
import DevisCollectifSingle from "./Devis/Single/DevisCollectifSingle";
import AutoSub from "./AutoSub/AutoSub";
import AutoSubThanks from "./AutoSub/AutoSubThanks";
import DevisIndividualSingle from "./Devis/Single/DevisIndividualSingle";
import ContratIndividuelSingle from "./Contrats/Single/ContratIndividuelSingle";
import ContratCollectifSingle from "./Contrats/Single/ContratCollectifSingle";
import ResetPassword from "./AuthPages/Login/ResetPassword";
import PersonnalData from "./AuthPages/Login/PersonnalData";
import OAVWarranties from "../Components/OAV/OAVGaranties/OAVWarranties";
import Gestion from "./Gestion/Gestion";
import Contacts from "./Contacts/Contacts";

import Dashboard from "./Dashboard/Dashboard";
import Performances from "./Dashboard/Sections/Performances";
import Repartitions from "./Dashboard/Sections/Repartitions";
import Actu from "./Dashboard/Sections/Actu";
import Users from "./Users/Users";

const isAuthenticated = () => localStorage.getItem("token");
const PublicRoute = (props) => <Route {...props} />;
const PrivateRoute = (props) =>
  isAuthenticated() ? <Route {...props} /> : <Redirect to={"/"} />;

const App = () => {
  const history = useHistory();
  return (
    <div className="App">
      <BrowserRouter history={history} basename={"/"}>
        <Switch>
          {/*  */}
          <PublicRoute path="/" exact>
            <Login />
          </PublicRoute>

          <PublicRoute path="/recover-password" exact>
            <RecoverPassword />
          </PublicRoute>

          <PublicRoute path="/reset-password/:token" exact>
            <ResetPassword />
          </PublicRoute>

          <PublicRoute path="/Souscription/Pieces/:uid" exact>
            <AutoSub />
          </PublicRoute>

          <PublicRoute path="/Souscription/Thanks" exact>
            <AutoSubThanks />
          </PublicRoute>

          <LayoutLogged>
            <PrivateRoute path="/DonneesPersonnelles" exact>
              <PersonnalData />
            </PrivateRoute>

            <Route
              path="/privacy-policy"
              component={() => {
                window.open(
                  "https://www.mbamutuelle.com/reglement-general-de-protection-des-donnees.php",
                  "_blank"
                );
              }}
            />

            <PrivateRoute path="/Tarificateur/Besoin" exact>
              <OAVBesoin />
            </PrivateRoute>

            <PrivateRoute path="/Tarificateur/Individuel/Profil" exact>
              <OAVIndividuelProfil />
            </PrivateRoute>

            <PrivateRoute path="/Tarificateur/Collectif/Profil" exact>
              <OAVCollectifProfil />
            </PrivateRoute>

            <PrivateRoute path="/Tarificateur/Individuel/Devis" exact>
              <OAVIndividuelDevis />
            </PrivateRoute>

            <PrivateRoute path="/Tarificateur/Collectif/Devis" exact>
              <OAVCollectifDevis />
            </PrivateRoute>

            <PrivateRoute path="/Tarificateur/Individuel/Souscrire" exact>
              <OAVIndividuelSouscrire />
            </PrivateRoute>

            <PrivateRoute path="/Tarificateur/Collectif/Souscrire" exact>
              <OAVCollectifSouscrire />
            </PrivateRoute>

            <PrivateRoute path="/Tarificateur/Garanties" exact>
              <OAVWarranties />
            </PrivateRoute>

            <NavLayout>
              <PrivateRoute path="/Dashboard" exact>
                <Dashboard>
                  <Performances />
                </Dashboard>
              </PrivateRoute>

              <PrivateRoute path="/Dashboard/actu" exact>
                <Dashboard>
                  <Actu />
                </Dashboard>
              </PrivateRoute>

              <PrivateRoute path="/Dashboard/Repartitions" exact>
                <Dashboard>
                  <Repartitions />
                </Dashboard>
              </PrivateRoute>

              <PrivateRoute path="/Profil" exact>
                <Profil />
              </PrivateRoute>

              <PrivateRoute path="/Devis/Individuels" exact>
                <Devis>
                  <DevisIndividuels />
                </Devis>
              </PrivateRoute>

              <PrivateRoute path="/Devis/Individuels/:id" exact>
                <DevisIndividualSingle />
              </PrivateRoute>

              <PrivateRoute path="/Devis/Collectifs" exact>
                <Devis>
                  <DevisCollectifs />
                </Devis>
              </PrivateRoute>

              <PrivateRoute path="/Devis/Collectifs/:id" exact>
                <DevisCollectifSingle />
              </PrivateRoute>

              <PrivateRoute path="/Contrats/Individuels" exact>
                <Contrats>
                  <ContratsIndividuels />
                </Contrats>
              </PrivateRoute>

              <PrivateRoute path="/Contrats/Individuels/:id" exact>
                <ContratIndividuelSingle />
              </PrivateRoute>

              <PrivateRoute path="/Contrats/Collectifs" exact>
                <Contrats>
                  <ContratsCollectifs />
                </Contrats>
              </PrivateRoute>

              <PrivateRoute path="/Contrats/Collectifs/:id" exact>
                <ContratCollectifSingle />
              </PrivateRoute>

              <PrivateRoute path="/Utilisateurs" exact>
                <Users />
              </PrivateRoute>

              <PrivateRoute path="/Documents/Generiques" exact>
                <Documents>
                  <DocumentsGeneric />
                </Documents>
              </PrivateRoute>


              <PrivateRoute path="/contacts" exact>
                  <Contacts />
                </PrivateRoute>

                <PrivateRoute path="/gestion" exact>
                  <Gestion />
                </PrivateRoute>

              <PrivateRoute path="/Documents/Personnels" exact>
                <Documents>
                  <MesDocuments />
                </Documents>
              </PrivateRoute>
            </NavLayout>
          </LayoutLogged>

          {/*  */}

          <PublicRoute path="/reset-email/:token">
            <p>reset-email/:token</p>
          </PublicRoute>

          <PublicRoute path="/email-confirmation">
            <p>email-confirmation</p>
          </PublicRoute>

          <PublicRoute path="/403" exact component={Page403} />
          <Route component={Page404} />

          {/*  */}
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
