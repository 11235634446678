import React from "react";
import { Typography, Box } from "@material-ui/core";
import AccesProfile from "../NavLayout/AccesProfile";

const CustomHeader = ({
  icon,
  pageName,
  underline,
  childComponent,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        marginBottom: "1rem",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box style={{ display: "flex", alignItems: "center", paddingTop: 20 }}>
        {icon}
        {childComponent === undefined && (
          <Typography
            component="h2"
            variant="h2"
            style={
              underline === true
                ? {
                    color: "rgb(195, 29, 35)",
                    borderBottom: "2px solid rgb(195, 29, 35)",
                  }
                : { color: "rgb(195, 29, 35)" }
            }
          >
            {pageName}
          </Typography>
        )}
        {childComponent !== undefined && <>{childComponent}</>}
      </Box>

      {/*  */}
      <AccesProfile />
    </Box>
  );
};

export default CustomHeader;
