import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import MBA from '../../Assets/mbalogo.png';
import FileUploader from '../../Components/Commons/FileUploader/FileUploader';
import { useParams } from 'react-router-dom';
import { addDocumentToUpload, getDocumentToUpload } from '../../Axios/Call/ClientCredential';
import LoaderCircular from '../../Components/Commons/Loading/LoaderCircular';
import AlertGeneral from '../../Components/Commons/AlertGeneral/AlertGeneral';
import { toBase64 } from '../../utils/utils';
import _ from 'lodash';
import { Redirect } from 'react-router';

const AutoSub = () => {
  const [documents, setDocuments] = useState([]);
  const [toUpload, setToUpload] = useState([]);
  const [successapi, setsuccessapi] = useState(false);
  const [errorapi, seterrorapi] = useState(false);
  const [loading, setloading] = useState(false);

  const params = useParams();

  async function createDocument(document_id, base64, rawFile) {
    const fbase64 = await toBase64(base64);
    return {
      document_id,
      base64: fbase64,
      rawFile,
    };
  }

  async function createNewDoc(file, id) {
    let res = [...toUpload];
    let tmp = await createDocument(id, file, file);
    if (res.find((d) => d.document_id === id)) {
      _.remove(res, function (d) {
        return d.document_id === id;
      });
    }
    res.push(tmp);
    setToUpload(res);
  }

  async function handleUpload() {
    setloading(true);
    let final = toUpload?.map((d) => {
      return {
        id: d.document_id,
        base64: d.base64,
      };
    });
    addDocumentToUpload({ uidSubscription: params.uid, documents: JSON.stringify(final) })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setsuccessapi(true);
        }
      })
      .catch((err) => {
        seterrorapi(true);
        console.warn(err);
      })
      .finally(() => setloading(false));
  }

  useEffect(() => {
    getDocumentToUpload(params.uid).then((res) => {
      let tmp = res.data.map((p) => p.document_justifications)?.find((x) => x);
      setDocuments(tmp);
    });
  }, []);

  return (
    <div className={'containerStyle'}>
      {successapi && <Redirect to="/Souscription/Thanks" />}
      {errorapi && (
        <AlertGeneral
          setTrigger={seterrorapi}
          color="danger"
          description="Une erreur est survenue, veuillez réessayer plus tard."
        />
      )}
      <Grid
        container
        direction="row"
        // justifyContent="center"
        // alignItems="center"
      >
        <Grid item xs={12} md={4} style={{ background: '#C31D23', height: '100vh' }}>
          <Box>
            <img
              src={MBA}
              alt="MBA Mutuelle"
              style={{
                width: '60%',
                minWidth: '250px',
                maxWidth: '675px',
                marginTop: '18rem',
              }}
            />
          </Box>
        </Grid>
        {/* fields */}
        {loading ? (
          <LoaderCircular />
        ) : (
          <Grid item xs={12} md={8}>
            <Box style={{ padding: '24px' }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h1" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                    Pièces justificatives à votre souscription
                  </Typography>
                  <Typography variant="h3">
                    Afin de finaliser votre souscription, nous sommes en attente des pièces justificatives suivantes
                  </Typography>
                </Grid>
                <Box className="spacer2" />
                {/* item */}

                {documents?.map((d) => (
                  <Grid item xs={12}>
                    <Typography variant="h2" style={{ textAlign: 'left', fontWeight: 'bold' }}>
                      {d?.document.name} {d?.document?.description?.toLowerCase() === 'obligatoire' ? '*' : ''}
                    </Typography>
                    <Box
                      style={{
                        border: '3px dashed #C31D23',
                        borderRadius: '4px',
                        background: '#C31D2333',
                        padding: '1.5rem',
                      }}
                    >
                      <FileUploader
                        handleFile={(e) => createNewDoc(e, d?.document.id)}
                        file={toUpload.find((f) => f.document_id === d?.document.id)?.rawFile}
                        required={d?.document.description.toLowerCase() === 'obligatoire' ? true : false}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box className="spacer2" />
              <Typography
                variant="h3"
                style={{
                  textAlign: 'left',
                  marginTop: '1rem',
                  fontSize: '18px',
                }}
              >
                * les pièces notées d’un astérisque sont obligatoires{' '}
              </Typography>
              <Box className="spacer2" />
              <Button className="buttonGeneralStyle" onClick={() => handleUpload()}>
                J'AI AJOUTÉ TOUTES MES PIÈCES
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default AutoSub;
