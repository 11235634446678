import * as actionsTypes from "./actionTypes";
import * as authApi from "../../Axios/Call/AuthApi";
import * as clientApi from "../../Axios/Call/ClientCredential";
import {
  authApiAxios,
  clientCredentialAxios,
  initAxios,
} from "../../Axios/AxiosInstance";

export const populateUserData = () => {
  initAxios();

  return (dispatch, state) => {
    authApi
      .me()
      .then((response) => {
        const userData = response.data;

        dispatch(saveUserData(userData));
      })
      .catch((error) => {
        dispatch(errorApi());
      });
  };
};

const saveUserData = (userData) => {
  return {
    type: actionsTypes.POPULATE_USER_DATA,
    userData: userData,
  };
};

export const cleanUserData = () => {
  localStorage.removeItem("token");
  return {
    type: actionsTypes.CLEAN_USER_DATA,
  };
};

// --------------------------------------

export const populateAssoc = () => {
  return (dispatch, state) => {
    clientApi
      .getAllAssociation()
      .then((res) => {
        //console.log(res);
        const associations = res.data;
        dispatch(saveAssoc(associations));
      })
      .catch((err) => dispatch(errorApi()));
  };
};

const saveAssoc = (associations) => {
  return {
    type: actionsTypes.SAVE_ASSOC,
    associations: associations,
  };
};

// ----------------------------------

export const populateActivity = () => {
  return (dispatch, state) => {
    clientApi
      .getAllActivities()
      .then((res) => {
        const activities = res.data;
        dispatch(saveActivity(activities));
      })
      .catch((err) => dispatch(errorApi()));
  };
};

const saveActivity = (activities) => {
  return {
    type: actionsTypes.SAVE_ACTIVITY,
    activities: activities,
  };
};

// ------------------------------------------

export const populatePacks = () => {
  return (dispatch, state) => {
    clientApi
      .getAllPackages()
      .then((res) => {
        const packs = res.data;
        dispatch(savePacks(packs));
      })
      .catch((err) => dispatch(errorApi()));
  };
};

const savePacks = (packs) => {
  return {
    type: actionsTypes.SAVE_PACKS,
    packs: packs,
  };
};

// ------------------------------------------

export const populateGaranties = () => {
  return (dispatch, state) => {
    clientApi
      .getAllGuaranties()
      .then((res) => {
        const garanties = res.data;
        dispatch(saveGaranties(garanties));
      })
      .catch((err) => dispatch(errorApi()));
  };
};

const saveGaranties = (garanties) => {
  return {
    type: actionsTypes.SAVE_GARANTIES,
    garanties: garanties,
  };
};

// ------------------------------------------

export const populateProducts = () => {
  return (dispatch, state) => {
    clientApi
      .getAllProducts()
      .then((res) => {
        const products = res.data;
        dispatch(saveProducts(products));
      })
      .catch((err) => dispatch(errorApi()));
  };
};

const saveProducts = (products) => {
  return {
    type: actionsTypes.SAVE_PRODUCTS,
    products: products,
  };
};

// ------------------------------------------

export const populateProfessions = () => {
  return (dispatch, state) => {
    clientApi
      .getAllProfessions()
      .then((res) => {
        const professions = res.data;
        dispatch(saveProfessions(professions));
      })
      .catch((err) => dispatch(errorApi()));
  };
};

const saveProfessions = (professions) => {
  return {
    type: actionsTypes.SAVE_ACTIVITY,
    professions: professions,
  };
};

//---------------------------------- General function -------------------------------------------
const errorApi = () => {
  return {
    type: actionsTypes.ERROR_ON_API,
  };
};
