import React from "react";

const FileUploader = ({ handleFile, file, required }) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <>
      <button
        onClick={handleClick}
        style={{
          color: "#882D2D",
          textTransform: "uppercase",
          fontWeight: "bold",
          textAlign: "center",
          margin: "0",
          cursor: "pointer",
          border: "none",
          borderRadius: "4px",
          background: "none",
          fontSize: "20px",
        }}
      >
        déposer le fichier
      </button>
      {file && <p>{file.name}</p>}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        accept="image/png, image/jpeg, image/jpg"
        required={required}
      />
    </>
  );
};
export default FileUploader;
