import { Autocomplete } from "@material-ui/lab";
import { TextField, CircularProgress } from "@material-ui/core";
import { useState, useEffect } from "react";
import axios from "axios";

import useDebounce from "../../../utils/useDebounce";

const zipcodesUrl = "https://postalcode.vigee.fr/zipcodes/fr";

const renderOption = (option) => {
  const { place, indices } = option;
  const { place: city, zipcode } = place;

  return (
    <span key={`${place}${zipcode}`}>
      {zipcode
        .split("")
        .map((char, index) =>
          indices.includes(index) ? <b>{char}</b> : char
        )}{" "}
      - {city}
    </span>
  );
};

const ZipcodeAutocomplete = ({ TextFieldProps, ...props }) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const debouncedInputValue = useDebounce(inputValue, 500);

  useEffect(() => {
    let active = true;
    const cancelToken = axios.CancelToken.source();

    if (debouncedInputValue === "") {
      setOptions([]);
      return undefined;
    }

    (async () => {
      setLoading(true);
      const response = await axios.get(zipcodesUrl, {
        cancelToken: cancelToken.token,
        params: {
          search: debouncedInputValue,
        },
      });
      const { data } = response;

      if (active) {
        setOptions(data);
      }

      setLoading(false);
    })();
    return () => {
      cancelToken.cancel();
      active = false;
    };
  }, [debouncedInputValue]);

  return (
    <>
      <Autocomplete
        style={{ marginTop: 10 }}
        options={options}
        inputValue={inputValue}
        renderOption={renderOption}
        getOptionLabel={(option) =>
          `${option.place.zipcode} - ${option.place.place}`
        }
        getOptionSelected={(option, value) => option.zipcode === value.zipcode}
        filterOptions={(x) => x}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        noOptionsText={
          inputValue ? "Aucun résultat" : "Saisissez un code postal"
        }
        {...props}
        renderInput={(params) => (
          <TextField
            {...params}
            {...TextFieldProps}
            label="Code postal"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default ZipcodeAutocomplete;
