import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  MenuItem,
  Typography,
  InputAdornment,
  TextField,
  Box,
  TablePagination,
  Button,
  Grid,
} from "@material-ui/core";
import { AiOutlineEye } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import CommonDateTimePicker from "../../../Components/FormComponents/CommonDateTimePicker";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { changeQuote, getQuotes } from "../../../Axios/Call/ClientCredential";
import CommonSelect from "../../../Components/FormComponents/CommonSelect";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import _ from "lodash";
import "moment/locale/fr";
import { stableSort, getComparator } from "../../../utils/tableSort";
import EnhancedTableHead from "../../../Components/Table/EnhancedTableHead";
import exportToCsv from "../../../utils/covertToCsv";
import { defaultLabelDisplayedRows } from "../../../utils/utils";
moment.locale("fr");

const Individuel = () => {
  const [loadingApi, setloadingApi] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filterStatus, setfilterStatus] = useState("all");
  const [starting_date, setstarting_date] = useState(null);
  const [ending_date, setending_date] = useState(null);
  const contrat_status = useSelector((state) => state.user.contrat_status);
  const editable_contrat_status = useSelector(
    (state) => state.user.editable_contrat_status
  );
  const [rows, setrows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const broker = useSelector(
    (state) => state.user.userData.broker ?? state.user.userData
  );
  const [changeStatus, setChangeStatus] = useState(null);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const garanties = useSelector((state) => state.user.garanties);

  const products = useSelector((state) =>
    state.user.products.map((p) => {
      return { id: p.id, value: p.description };
    })
  );

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeStatusHandler = (status) => {
    changeQuote(changeStatus, {
      devis_status: undefined,
      contract_status: status,
    }).then((response) => {
      //console.log(response);
      setChangeStatus(null);
    });
  };

  const filterByDates = (data, start, end) => {
    return data.filter(
      (q) =>
        (starting_date !== null ? q.date >= start : true) &&
        (ending_date !== null ? q.date <= end : true)
    );
  };

  const createData = (
    number,
    numberQuote,
    contact,
    starting_date,
    zip,
    phone,
    email,
    broker,
    date,
    product,
    warranty,
    CA,
    nbPers,
    status,
    date_status,
    status_updated_by,
    index,
    numero_adherent,
    all
  ) => {
    return {
      ...all,
      number,
      numberQuote,
      contact,
      starting_date,
      zip,
      phone,
      email,
      broker,
      date,
      product,
      warranty,
      CA,
      nbPers,
      status,
      date_status,
      status_updated_by,
      index,
      numero_adherent,
      all,
    };
  };

  const handlesetsearchValue = (val) => {
    setsearchValue(val);
  };

  const calculateNumberOfPerson = (row) => {
    let value = 1;
    if (row?.joint_birth_date) {
      value++;
    }
    value += row?.childrens?.length || 0;
    return value;
  };

  const findProductName = (quote) => {
    let name = products.filter((p) => quote.product_id === p.id)[0]?.value;
    return name || `...`;
  };

  const findBaseGarantieName = (quote) => {
    let ids = []; // lsite des ID des garanties du client
    let name = "...";
    if (_.some(quote.documents, "package_id")) {
      ids = quote.documents?.map((p) => p.guarantee_id);
    } else if (_.some(quote.packages, "package_id")) {
      ids = quote.packages?.map((p) => p.guarantee_id);
    }
    garanties?.forEach((g) => {
      ids?.forEach((id) => {
        if (id === g.id && g.nature === "base") {
          name = g.name;
        }
      });
    });
    return name;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const headCells = [
    {
      id: "See",
      label: "Voir",
      isOrderable: false,
    },
    {
      id: "status",
      label: "État",
      isOrderable: true,
    },
    {
      id: "number",
      label: "N°",
      isOrderable: true,
    },
    {
      id: "contact",
      label: "Personne",
      isOrderable: true,
    },
    {
      id: "starting_date",
      label: "Date effet",
      isOrderable: true,
    },
    {
      id: "zip",
      label: "Code Postal",
      isOrderable: true,
    },
    {
      id: "phone",
      label: "Téléphone",
      isOrderable: true,
    },
    {
      id: "email",
      label: "Email",
      isOrderable: true,
    },
    {
      id: "broker",
      label: "Courtier",
      isOrderable: true,
    },

    {
      id: "product",
      label: "Produit",
      isOrderable: true,
    },
    {
      id: "warranty",
      label: "Garantie",
      isOrderable: true,
    },
    {
      id: "taux",
      label: "Commission",
      isOrderable: true,
    },
    {
      id: "CA",
      label: "CA",
      isOrderable: true,
    },
    {
      id: "nbPers",
      label: "PP",
      isOrderable: true,
    },
  ];

  const formatName = (data) => {
    let firstname = data.substring(0, data.indexOf(" "));
    let lastname = data.substring(data.indexOf(" ") + 1).toUpperCase();

    let firstNameCapitalized =
      firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase();

    let completeName = firstNameCapitalized + " " + lastname;

    return completeName;
  };

  const exportCsv = useCallback(() => {
    const headerFormatter = () => [
      "N°",
      "Contact",
      "Code Postal",
      "Téléphone",
      "Email",
      "Courtier",
      "Date du devis",
      "Produit",
      "Garantie",
      "CA",
      "PP",
    ];
    const formatter = (quote) => [
      quote.number,
      quote.numberQuote,
      quote.contact,
      quote.zip,
      quote.phone,
      quote.email,
      quote.broker,
      moment(quote.date).format("DD/MM/yyyy"),
      quote.product,
      quote.warranty,
      quote.CA,
      quote.nbPers,
    ];
    exportToCsv({
      formatter,
      headerFormatter,
      data: filteredRows,
      filename: "Contrats Individuels.csv",
    });
  }, [filteredRows]);

  useEffect(() => {
    if (Object.keys(broker).length) {
      setloadingApi(true);
      getQuotes()
        .then((res) => {
          const data = res.data
            ?.filter((q) => q.broker_id === broker.id)
            ?.filter((q) => q.type === "subscription")
            ?.filter((q) => q.company === null);

          const tmp = data.map((q) =>
            createData(
              q.id,
              q.number_avenant ? q.numero_devis + '.' + q.number_avenant : q.numero_devis,
              `${q.firt_name} ${q.last_name.toUpperCase()}`,
              q.starting_date,
              q.zip_code,
              q.phone,
              q.email,
              `${broker?.company}`,
              q.created_at,
              findProductName(q),
              findBaseGarantieName(q),
              (q.total_price * 12).toFixed(2),
              calculateNumberOfPerson(q),
              q.contract_status,
              q.date_status_contract,
              q.status_updated_by,
              q.index,
              q.numero_adherent,
              q
            )
          );

          setrows(tmp);
        })
        .finally(() => setloadingApi(false));
    }
  }, [changeStatus, broker]);

  useEffect(() => {
    let tmp = filterByDates(rows, starting_date, ending_date);
    setFilteredRows(
      tmp?.filter(
        (row) =>
          (row.contact.toLowerCase().match(searchValue.toLowerCase().trim()) ||
            row.numberQuote
              .toString()
              .toLowerCase()
              .match(searchValue.toLowerCase().trim()) ||
            row.zip
              .toString()
              .toLowerCase()
              .match(searchValue.toLowerCase().trim()) ||
            row.phone
              .toString()
              .toLowerCase()
              .match(searchValue.toLowerCase().trim()) ||
            row.email?.toLowerCase().match(searchValue.toLowerCase().trim()) ||
            row.broker.toLowerCase().match(searchValue.toLowerCase().trim())) &&
          (filterStatus && filterStatus !== "all"
            ? row.status === filterStatus
            : true)
      )
    );
    setPage(0);
  }, [rows, searchValue, filterStatus, starting_date, ending_date]);

  return (
    <>
      <Grid container spacing={1} style={{ margin: "1.5rem 0 1rem" }}>
        <Grid
          item
          xs={12}
          md={3}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <TextField
            variant="outlined"
            size="medium"
            fullWidth
            placeholder="Rechercher par nom, date..."
            style={{ marginTop: 10 }}
            value={searchValue}
            onChange={(e) => handlesetsearchValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid
          item
          xs={4}
          md={1}
          style={{
            display: "flex",
            justifyContent: "start",
            textAlign: "left",
          }}
        >
          <TextField
            select
            variant="outlined"
            size="medium"
            fullWidth
            label="État"
            style={{ marginTop: 10 }}
            value={filterStatus}
            onChange={(e) => setfilterStatus(e.target.value)}
          >
            <MenuItem key={"all"} value={"all"}>
              <Typography component="p" variant="body2">
                {"Tous"}
              </Typography>
            </MenuItem>
            {contrat_status?.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                <Typography component="p" variant="body2">
                  {s.name}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid
          item
          xs={6}
          md={2}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <CommonDateTimePicker
            field={{ value: starting_date, onChange: setstarting_date }}
            label={"Date de début"}
            name={"starting_date"}
            
          />
        </Grid>

        <Grid
          item
          xs={6}
          md={2}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <CommonDateTimePicker
            field={{ value: ending_date, onChange: setending_date }}
            label={"Date de fin"}
            name={"starting_date"}
          />
        </Grid>

        <Grid
          item
          xs={6}
          md={2}
          style={{ display: "flex", justifyItems: "flex-end" }}
        >
          <Button
            className="buttonGeneralStyle"
            fullWidth
            style={{ height: "53.5", maxHeight: "none", marginTop: 10 }}
            onClick={exportCsv}
          >
            Exporter en CSV
          </Button>
        </Grid>
      </Grid>

      <TableContainer>
        {loadingApi ? (
          <LoadingButton />
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(filteredRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => (
                  <TableRow
                    key={row.number}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">
                      <AiOutlineEye
                        fill="rgba(0,0,0,0.72"
                        size={25}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(`/Contrats/Individuels/${row.number}`)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      {changeStatus === row.number ? (
                        <CommonSelect
                          field={{
                            value: row.status,
                            onChange: (status) => onChangeStatusHandler(status),
                          }}
                          options={editable_contrat_status.map((state) => {
                            return { id: state.id, value: state.name };
                          })}
                        />
                      ) : (
                        <Typography variant="body2">
                          <Box
                            style={{
                              background: contrat_status?.find(
                                (s) => s.id === row.status
                              )?.color,
                              color: "white",
                              borderRadius: "3px",
                              padding: "0.3rem",
                              fontWeight: "600",
                            }}
                            onClick={() =>
                              row.status !== "waiting" &&
                              row.status !== "managment" &&
                              row.status !== "finished" &&
                              row.status !== "signed" &&
                              setChangeStatus(row.number)
                            }
                          >
                            {
                              contrat_status?.find((s) => s.id === row.status)
                                ?.name
                            }
                            <Typography
                              style={{ color: "#DDD", fontSize: "13px" }}
                            >
                              {row.date_status &&
                                "le " +
                                  moment(row.date_status).format("DD/MM/yyyy")}
                            </Typography>
                            <Typography
                              style={{ color: "#DDD", fontSize: "13px" }}
                            >
                              {row.status_updated_by &&
                                "par " + row.status_updated_by.toUpperCase()}
                            </Typography>
                          </Box>
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      <Typography variant="body2" style={{ fontWeight: "400" }}>
                        {row.numberQuote}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">
                        {formatName(row.contact)}{" "}
                        {row.numero_adherent && `(${row.numero_adherent})`}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" style={{ fontWeight: 800 }}>
                        {row.starting_date !== null
                          ? moment(row.starting_date).format("DD/MM/yyyy")
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">{row.zip}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">{row.phone}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">{row.email}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">{row.broker}</Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography variant="body2">{row.product}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2">{row.warranty}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2">{row.taux}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">{row.CA}€</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2">{row.nbPers}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        component="div"
        size="medium"
        variant="outlined"
        count={filteredRows?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={() =>
          defaultLabelDisplayedRows({
            from: rowsPerPage * page + 1,
            to:
              rowsPerPage * page + rowsPerPage > filteredRows.length
                ? filteredRows.length
                : rowsPerPage * page + rowsPerPage,
            count: filteredRows?.length,
          })
        }
        labelRowsPerPage={"Lignes par page :"}
      />
    </>
  );
};

export default Individuel;
