import React from "react";
import { CircularProgress, withStyles, Box, Typography } from "@material-ui/core";
import classes from "./LoaderCircular.module.css";

const LoaderGeneration = () => {
  const StyledLoader = withStyles({
    root: {
        zIndex: 100,
        display: "block",
        margin: "0 auto"
    },
    colorPrimary: {
      color: "#C31D23",
    },
  })((props) => <CircularProgress {...props} />);
  return (
    <Box display={"flex"} flexDirection={"column"} style={{paddingTop:100}}>
  <StyledLoader />
  <Typography className={classes.clignote}>Génération en cours...</Typography> 
  </Box>
  );
};

export default LoaderGeneration;
