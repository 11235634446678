import React from "react";
import MBA from "../../../Assets/mbalogo.png";
import { Grid, Box } from "@material-ui/core";

const AuthLayout = ({ children, extra }) => {
  return (
    <div className={"containerStyle"}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} md={7}>
          <Box style={{ padding: "24px", maxWidth: "425px", margin: "auto" }}>
            {children}
            {extra}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          style={{ background: "#C31D23", height: "100vh" }}
        >
          <Box>
          <img src={MBA} 
          alt="MBA Mutuelle" 
            style={{ width: "60%", minWidth: "250px", maxWidth: "675px", marginTop: "18rem" }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthLayout;
