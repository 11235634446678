import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreator from "../../Store/action/index";
import { useWindowWidth } from "@react-hook/window-size";
import classes from "./NavLayout.module.css";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PlaceIcon from '@mui/icons-material/Place';

import {
  Box,
  Typography,
  Tabs,
  Tab,
  withStyles,
  Button,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { RiDashboardFill, RiLogoutCircleLine } from "react-icons/ri";
import { GoFile } from "react-icons/go";
import { BsFileCheck } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import MBA from "../../Assets/mbalogo.png";
import { IoDocumentsOutline } from "react-icons/io5";
import { FaUserFriends } from "react-icons/fa";

const StyledTabs = withStyles({
  root: {
    color: "white",
  },
  indicator: {
    backgroundColor: "white",
    width: "5px",
    borderTopRightRadius: "2px",
    borderBottomRightRadius: "2px",
    left: 0,
  },
  scrollButtons: {
    backgroundColor: "lightgrey",
  },
})((props) => <Tabs {...props} />);

const NavLayout = ({ children, md, lg }) => {
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const [checked, setchecked] = useState(true);
  const [hide, setHide] = useState(true);
  const width = useWindowWidth();
  const dispatch = useDispatch();

  const broker_type = userData?.broker_type;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    return history.push(newValue);
  };

  const logout = () => {
    dispatch(actionCreator.cleanUserData());
    history.push("/");
  };

  const StyledTab = withStyles({
    root: {
      minHeight: "22px",
      margin: "8px 0",
      padding: "0",
      textTransform: "none",
      fontSize: "18px",
      minWidth: 0,
      width: !hide ? "100px" : "100%",
    },
    textColorInherit: {
      color: "#FFFFFF60",
      opacity: 1,
    },
    wrapper: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      paddingLeft: "35px",
    },
    selected: {
      opacity: 1,
      color: "#FFFFFF",
    },
  })((props) => <Tab {...props} />);

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData?.status === "active") {
        // if (userData?.email_verified_at) {
        if (userData.type === "admin" || userData.type === "broker") {
          setValue(location.pathname);
        }
        // } else {
        //   dispatch(actionCreator.cleanUserData());
        // }
      } else {
        dispatch(actionCreator.cleanUserData());
      }
    }
  }, [userData, dispatch, location.pathname]);

  // handle the css property display depending on the location pathname
  function handleDisplay(location) {
    if (location.includes("/Tarificateur/")) {
      return "none";
    } else {
      return "block";
    }
  }

  const handleResetOAV = () => {
    dispatch(actionCreator.cleanOavData());
    history.push("/Tarificateur/Besoin");
  };

  return (
    <Box display={handleDisplay(location.pathname)}>
      {/*  */}

      <Box
        style={{
          position: "fixed",
          zIndex: 1002,
          borderRight: "1px solid #353f6a42",
          minHeight: "100%",
          backgroundColor: "#C31D23",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          transition: "all .4s .1s cubic-bezier(0.63, 0.32, 0.27, 0.93)",
          paddingTop: "5px",
          width:
            hide === false
              ? "100px"
              : "280px" && width < 600
              ? "100%"
              : "280px",
        }}
        className={
          checked && width < 600
            ? classes.sideMenuWrapperMobile
            : classes.sideMenuWrapper
        }
      >
        <Box
          style={{ width: "100%" }}
          xs={12}
          align="right"
          justifyContent="right"
        >
          <div
            className={`${
              !checked && width < 600 ? classes.display : classes.none
            }`}
            onClick={() => setchecked(!checked)}
          >
            <AiOutlineMenu size="30" color="white" />
          </div>
        </Box>

        <img
          src={MBA}
          alt="MBA Mutuelle"
          style={{
            margin: "0 auto",
            width: hide === true ? "160px" : "80px",
            minWidth: "15px",
            maxWidth: "160px",
            cursor: "pointer",
          }}
          onClick={() => history.push("/")}
        />

        <Box style={{ width: "100%" }}>
          <Button
            className={`${
              hide ? classes.buttonTarificateurStyle : classes.none
            }`}
            onClick={() => handleResetOAV()}
            style={{ maxWidth: "250px" }}
          >
            Tarificateur
          </Button>
        </Box>

        <StyledTabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
        >
          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Tableau de bord
              </div>
            }
            icon={
              <RiDashboardFill
                size={30}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/Dashboard"
          />
          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Devis
              </div>
            }
            icon={
              <GoFile
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/Devis/Individuels"
          />
          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Contrats
              </div>
            }
            icon={
              <BsFileCheck
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/Contrats/Individuels"
          />

          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Documents
              </div>
            }
            icon={
              <IoDocumentsOutline
                size={30}
                style={{ marginRight: ".5rem", opacity: 1 }}
              />
            }
            disableRipple
            value="/Documents/Generiques"
          />

          {broker_type === "broker-admin" && (
            <StyledTab
              label={
                <div
                  className={`${hide ? classes.display : classes.label_none}`}
                >
                  Courtiers
                </div>
              }
              icon={
                <FaUserFriends
                  size={25}
                  style={{ marginRight: "1rem", marginTop: "4px" }}
                />
              }
              disableRipple
              value="/Utilisateurs"
            />
          )}

          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Contacts
              </div>
            }
            icon={
              <ContactPhoneIcon
                size={30}
                style={{ marginRight: "1rem", opacity: 1, marginTop: 3 }}
              />
            }
            disableRipple
            value="/contacts"
          />

          {/*
          {broker_type === "broker-admin" && (
            <StyledTab
              label={
                <div
                  className={`${hide ? classes.display : classes.label_none}`}
                >
                  Gestion
                </div>
              }
              
              icon={
                <SettingsSuggestIcon
                  size={30}
                  style={{ marginRight: ".5rem", opacity: 1 }}
                />
              }
              disableRipple
              value="/gestion"
            />
          )}*/}
        </StyledTabs>

        <Box
          style={{
            display: "flex",
            alignItems: "center",
            position: "fixed",
            bottom: "4rem",
            left: "2.7rem",
          }}
        >
          <BsArrowBarRight
            size={30}
            style={{ fill: "#FFFFFF60", left: "2rem" }}
            onClick={() => setHide(!hide)}
            className={`${!hide ? classes.display : classes.none}`}
          />
        </Box>

        <Box
          style={{
            display: "flex",
            alignItems: "center",
            position: "fixed",
            bottom: "1rem",
            left: "2.5rem",
          }}
        >
          <RiLogoutCircleLine size={30} style={{ fill: "#FFFFFF60" }} />
          <Typography
            component="p"
            variant="h6"
            style={{ color: "#FFFFFF60", paddingLeft: 8 }}
            onClick={() => logout()}
            className={`${hide ? classes.display : classes.label_none}`}
          >
            Déconnexion
          </Typography>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              position: "fixed",
              marginTop: "5rem",
              left: "250px",
              bottom: "1rem",
            }}
          >
            <BsArrowBarLeft
              size={30}
              style={{ fill: "#FFFFFF60" }}
              onClick={() => setHide(!hide)}
              className={`${
                hide && width > 600 ? classes.display : classes.label_none
              }`}
            />
          </Box>
        </Box>
      </Box>

      {checked && width < 600 && (
        <Box
          xs={6}
          className={classes.box}
          style={{ float: "left", padding: 3 }}
        >
          <AiOutlineMenu
            size="30"
            color="black"
            onClick={() => setchecked(!checked)}
          />
        </Box>
      )}

      <div
        style={{
          backgroundColor: "#F8F9FA",
          minHeight: "calc(100vh)",
          paddingLeft:
            width > 600 && hide === true
              ? "290px"
              : "1%" && width > 600 && hide === false
              ? "110px"
              : "1%",
          paddingRight: "10px",
        }}
      >
        {children}
      </div>
      {/*  */}
    </Box>
  );
};

export default NavLayout;
