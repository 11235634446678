import React, { useCallback, useEffect, useState } from "react";
import CustomHeader from "../../Components/Header/CustomHeader";
import { useParams, useHistory } from "react-router-dom";
import { getQuote } from "../../../Axios/Call/ClientCredential";
import { useForm, Controller } from "react-hook-form";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import * as actionCreator from "../../../Store/action/index";
import { useDispatch, useSelector } from "react-redux";
import CommonTextField from "../../../Components/FormComponents/CommonTextField";
import CommonSelect from "../../../Components/FormComponents/CommonSelect";
import Back from "../../../Components/Commons/Back/Back";
import { upperCaseFirst } from "../../../utils/utils";
import _ from "lodash";
import { AiOutlineEuroCircle } from "react-icons/ai";
import { MdHealthAndSafety } from "react-icons/md";
import { GiHealthIncrease } from "react-icons/gi";
import QuoteDocuments from "../../../Components/QuoteDocuments/QuoteDocuments";
import { MdPersonOutline } from "react-icons/md";
import moment from "moment";

const DevisCollectifSingle = () => {
  const [taux, setTaux] = useState(0);
  const params = useParams();
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [names, setNames] = useState({
    product: "Inconnu",
    pack: "Inconnu",
    garanties: [],
  });
  const dispatch = useDispatch();
  const [basePrices, setBasePrices] = useState([]);

  const packs = useSelector((state) => state.user.packs);
  const garanties = useSelector((state) => state.user.garanties);
  const produits = useSelector((state) => state.user.products);
  const colleges = useSelector((state) => state.user.colleges);
  const priceTypes = useSelector((state) => state.user.priceTypes);

  const reset = () => {
    dispatch(actionCreator.cleanOavData());
    history.push(`/Tarificateur/Garanties?current=${params.id}`);
  };

  useEffect(() => {
    let nameProduct = "...";
    let idPro = data?.product_id;
    nameProduct = produits?.find((p) => idPro === p.id)?.description;
    // -------------
    let namePack = "...";
    if (_.some(data.documents, "package_id")) {
      namePack = packs?.find(
        (p) => p.id === data.documents?.find((e) => e)?.package_id
      )?.name;
    } else if (_.some(data.packages, "package_id")) {
      namePack = packs?.find(
        (p) => p.id === data.packages.find((e) => e)?.package_id
      )?.name;
    }
    // -----------
    let ids = []; // lsite des ID des garanties du client
    let listGaranties = [];
    if (_.some(data.documents, "package_id")) {
      ids = data.documents?.map((p) => p.guarantee_id);
    } else if (_.some(data.packages, "package_id")) {
      ids = data.packages?.map((p) => p.guarantee_id);
    }
    garanties?.forEach((g) => {
      ids?.forEach((id) => {
        if (id === g.id) {
          listGaranties.push({
            id: g.id,
            name: g.name,
            nature: g.nature,
            description: g.description,
          });
        }
      });
    });
    setNames({
      product: nameProduct,
      pack: namePack,
      garanties: listGaranties,
    });
  }, [produits, data, packs, garanties]);

  const { register, errors, control, getValues, setValue, watch } = useForm({
    mode: "onBlur",
    reValidateMode: "all",
    shouldDirty: true,
    defaultValues: {
      company: "",
      siren: "",
      nic: "",
      naf: "",
      idcc: "",
      address: "",
      zip_code: "",
      city: "",
      firt_name: "",
      first_name_comptable: "",
      last_name: "",
      last_name_comptable: "",
      phone: "",
      phone_comptable: "",
      email: "",
      email_comptable: "",
      college: "",
      effectif: "",
      age_moyen: "",
      appel: "",
      peridiocite_appel: "",
      periodicite: "",
      bank_account_owner: "",
      iban: "",
      bic: "",
    },
  });

  const fetchQuote = useCallback(() => {
    getQuote(params.id).then((res) => {
      const tmp = res.data;
      setdata(tmp);

      setBasePrices(JSON.parse(tmp.price).base);
      setValue("company", res.data.company);
      setValue("siren", res.data.siren);
      setValue("nic", res.data.nic);
      setValue("naf", res.data.naf);
      setValue("idcc", res.data.idcc);
      setValue("address", res.data.address);
      setValue("zip_code", res.data.zip_code);
      setValue("city", res.data.city);
      setValue("firt_name", res.data.firt_name);
      setValue("last_name", res.data.last_name);
      setValue("phone", res.data.phone);
      setValue("email", res.data.email);
      setValue("first_name_comptable", res.data.first_name_comptable);
      setValue("last_name_comptable", res.data.last_name_comptable);
      setValue("email_comptable", res.data.email_comptable);
      setValue("phone_comptable", res.data.phone_comptable);
      setValue("college", res.data.college);
      setValue("effectif", res.data.effectif);
      setValue("age_moyen", res.data.age_moyen);
      setValue("peridiocite_appel", res.data.peridiocite_appel);
      setValue("periodicite", res.data.periodicite);
      setValue("appel", res.data.appel);
      setValue("bank_account_owner", res.data.bank_account_owner_payment);
      setValue("iban", res.data.iban_payment);
      setValue("bic", res.data.bic_payment);
      setTaux(tmp.taux);
      // setdata(res.data);
    });
  }, [params, setValue]);

  useEffect(() => {
    fetchQuote();
  }, [fetchQuote]);

  useEffect(() => {}, [watch]);

  return (
    <div>
      <CustomHeader
        icon={<Back />}
        pageName={`Devis ${data.numero_devis}`}
        userName={"Username here"}
      />

      <Box className="spacer2" />

      {/* HERE */}
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={4}
          className="containerSingle"
          style={{ maxHeight: "150px" }}
        >
          {" "}
          <MdHealthAndSafety
            size={30}
            style={{ fill: "#FFF", marginRight: ".5rem", marginTop: 12 }}
          />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
          >
            Produit
            <Typography
              variant="h6"
              style={{ color: "white", textAlign: "center" }}
            >
              <strong>{names.product}</strong>
            </Typography>{" "}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          className="containerSingle"
          
        >
          <GiHealthIncrease
            size={30}
            style={{ fill: "#FFF", marginRight: ".5rem" }}
          />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
          >
            Garanties
            <br />
            {names.garanties?.map((g) => {
              switch (g.nature) {
                case "base":
                  return (
                    <Typography
                      variant="h6"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      {upperCaseFirst(g.nature)} :{" "}
                      <strong>{g.description}</strong>
                    </Typography>
                  );
                case "renfort":
                  return (
                    <Typography
                      variant="h6"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      {upperCaseFirst(g.nature)} :{" "}
                      <strong>{g.description}</strong>
                    </Typography>
                  );
                case "option":
                  return (
                    <Typography
                      variant="h6"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      {upperCaseFirst(g.nature)} :{" "}
                      <strong>{g.description}</strong>
                    </Typography>
                  );
                  case "supp":
                    return (
                      <Typography
                        variant="h6"
                        style={{ color: "white", textAlign: "center" }}
                      >
                        {upperCaseFirst('Produit complémentaire')} :{" "}
                        <strong>{g.description}</strong>
                      </Typography>
                    );  

                default:
                  return (
                    <Typography
                      variant="h3"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      ...
                    </Typography>
                  );
              }
            })}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          className="containerSingle"
          style={{ backgroundColor: "#1278B2", maxHeight: "150px" }}
        >
          <AiOutlineEuroCircle
            size={30}
            style={{ fill: "#FFF", marginRight: ".5rem" }}
          />
          <Typography
            variant="h6"
            style={{ color: "white", textAlign: "center" }}
            direction="column"
            alignItems="center"
            justify="center"
          >
            Cotisations base
            {basePrices?.map((basePrice) => (
              <Typography
                variant="h6"
                style={{ color: "white", textAlign: "center" }}
              >
                {`${basePrice.label}: `} <strong>{basePrice.price}</strong>{" "}
                {
                  priceTypes.find(
                    (priceType) => priceType.id === basePrice.priceType
                  )?.value
                }
              </Typography>
            ))}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={1}
          className="containerSingle"
          style={{ backgroundColor: "#000", maxHeight: "120px" }}
        >
          <AiOutlineEuroCircle size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
            direction="column"
            alignItems="center"
            justify="center"
          >
            Taux
            <br />
            <strong>{taux}</strong>

          </Typography>
        </Grid>



        {/* HERE WE ADD DOCUMENTS */}
        <Grid item xs={12} md={6} xl={5}>
          {data && <QuoteDocuments quote={data} refresh={fetchQuote} />}
          {!data.signed &&  moment().format('YYYY-MM-DD') <= moment(data.starting_date).endOf('year').format('YYYY-MM-DD') && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 100,
                  }}
                >
                  <Button
                    className="buttonGeneralStyle"
                    onClick={() => reset()}
                  >
                    Modifier ou transformer en contrat
                  </Button>
                </Grid>
              </Grid>
            )}
        </Grid>

        <Grid item xs={12} md={6} lg={6} className="box-shadow" >
          <form
            style={{
              marginTop: "20px",
              gap: "10px",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            noValidate
          >
            <Typography
              variant="h1"
              style={{ marginTop: 20, textAlign: "left" }}
            >
              <MdPersonOutline
                size={30}
                style={{ marginRight: 10, marginBottom: "-5px" }}
              />
              Informations
            </Typography>

            <Typography variant="h4">Entreprise</Typography>
            {/* entreprise */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="company"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Raison sociale"
                        error={errors.company}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="siren"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Numéro de siren"
                        error={errors.siren}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={6} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="nic"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Code nic"
                        error={errors.nic}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            {/* <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="naf"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Code naf"
                        error={errors.naf}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="idcc"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Numéro idcc"
                        error={errors.idcc}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid> */}

            <Box className="spacer1" />

            <Typography variant="h4">Adresse de l'entreprise</Typography>
            {/* addresse */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{}}
                  name="address"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Adresse (numéro et libellé de voie)"
                        error={errors.address}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  name="zip_code"
                  rules={{ required: true }}
                  render={(field) => (
                    <CommonTextField
                      type="number"
                      field={field}
                      label="Code postal"
                      name="zip_code"
                      error={errors.zip_code}
                      InputProps={{ disabled: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{}}
                  name="city"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Ville"
                        error={errors.city}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Box className="spacer1" />

            {/* coordonnée interlocuteurs */}

            <Typography variant="h4">Interlocuteur</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="last_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Nom"
                        error={errors.last_name}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="firt_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Prénom"
                        error={errors.firt_name}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="phone"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"tel"}
                        field={field}
                        label="Téléphone"
                        error={errors.phone}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="email"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"email"}
                        field={field}
                        label="Email"
                        error={errors.email}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            {/* categories rappel */}
            <Box className="spacer1" />
            <Typography variant="h4">
              Catégorie de personnel couverte et structure de l'effectif
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="college"
                  rules={{ required: true }}
                  render={(field) => (
                    <CommonSelect
                      label="Collège"
                      field={field}
                      error={errors.college}
                      disabled={true}
                      options={colleges}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true, valueAsNumber: true }}
                  name="effectif"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"number"}
                        field={field}
                        label="Effectif assurable du collège"
                        error={errors.effectif}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true, valueAsNumber: true }}
                  name="age_moyen"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"number"}
                        field={field}
                        label="Âge moyen"
                        error={errors.age_moyen}
                        InputProps={{ disabled: true }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default DevisCollectifSingle;
