import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const UserComponentLayout = ({ children }) => {
  const [DISPLAY, SETDISPLAY] = useState("none")
  const user = useSelector((state) => state.user)

  useEffect(() => {
    if (Object.keys(user.userData).length) {
      if (user.userData?.status === "active") {
        if (user.userData.type === "user") {
          SETDISPLAY("none")
        } else {
          SETDISPLAY("flex")
        }
      }
    }
  }, [user])

  return <div style={{ display: DISPLAY }}>{children}</div>
}

export default UserComponentLayout
