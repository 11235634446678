import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  MenuItem,
  Typography,
  InputAdornment,
  TextField,
  Box,
  TablePagination,
  Button,
  Grid,
} from "@material-ui/core";
import { AiOutlineEye } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonDateTimePicker from "../../../Components/FormComponents/CommonDateTimePicker";
import moment from "moment";
import "moment/locale/fr";
import { changeQuote, getQuotes } from "../../../Axios/Call/ClientCredential";
import CommonSelect from "../../../Components/FormComponents/CommonSelect";
import _ from "lodash";
import EnhancedTableHead from "../../../Components/Table/EnhancedTableHead";
import { stableSort, getComparator } from "../../../utils/tableSort";
import exportToCsv from "../../../utils/covertToCsv";
import { defaultLabelDisplayedRows } from "../../../utils/utils";
moment.locale("fr");

const Collectif = () => {
  const [searchValue, setsearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filterStatus, setfilterStatus] = useState("all");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [starting_date, setstarting_date] = useState(null);
  const [ending_date, setending_date] = useState(null);
  const contrat_status = useSelector((state) => state.user.contrat_status);
  const editable_contrat_status = useSelector(
    (state) => state.user.editable_contrat_status
  );
  const broker = useSelector(
    (state) => state.user.userData.broker ?? state.user.userData
  );
  const [changeStatus, setChangeStatus] = useState(null);
  const history = useHistory();
  const products = useSelector((state) =>
    state.user.products.map((p) => {
      return { id: p.id, value: p.description };
    })
  );

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const garanties = useSelector((state) => state.user.garanties);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterByDates = (data, start, end) => {
    return data.filter(
      (q) =>
        (starting_date !== null ? q.date >= start : true) &&
        (ending_date !== null ? q.date <= end : true)
    );
  };

  const createData = (
    number,
    numeroQuote,
    entreprise,
    starting_date,
    zip,
    phone,
    email,
    broker,
    date,
    product,
    warranty,
    taux,
    CA,
    nbPers,
    status,
    date_status,
    status_updated_by,
    index,
    numero_adherent,
    all
  ) => {
    return {
      number,
      numeroQuote,
      entreprise,
      starting_date,
      zip,
      phone,
      email,
      broker,
      date,
      product,
      warranty,
      taux,
      CA,
      nbPers,
      status,
      date_status,
      status_updated_by,
      index,
      numero_adherent,
      all,
    };
  };

  const onChangeStatusHandler = (status) => {
    changeQuote(changeStatus, {
      devis_status: undefined,
      contract_status: status,
    }).then(() => {
      setChangeStatus(null);
    });
  };

  const findProductName = (quote) => {
    //console.log(products);
    let name = products.filter((p) => quote.product_id === p.id)[0]?.value;
    return name || `...`;
  };

  const calculateTotal = (data, PMSS, effectif) => {
    let total = 0;
    if (!!data && data !== "000") {
      let formated = data;
      for (const [key, value] of Object?.entries(formated)) {
        total += value;
      }
    }
    return Math.round((total / 100) * PMSS * 12 * (effectif * 1)).toFixed(0);
  };

  const findBaseGarantieName = (quote) => {
    let ids = []; // lsite des ID des garanties du client
    let name = "...";
    if (_.some(quote.documents, "package_id")) {
      ids = quote.documents?.map((p) => p.guarantee_id);
    } else if (_.some(quote.packages, "package_id")) {
      ids = quote.packages?.map((p) => p.guarantee_id);
    }
    garanties?.forEach((g) => {
      ids?.forEach((id) => {
        if (id === g.id && g.nature === "base") {
          name = g.name;
        }
      });
    });
    return name;
  };

  const exportCsv = useCallback(() => {
    const headerFormatter = () => [
      "N°",
      "Entreprise",
      "Date d'effet",
      "Code Postal",
      "Téléphone",
      "Email",
      "Courtier",
      "Date du devis",
      "Produit",
      "Garantie",
      "Salarié(s)",
    ];
    const formatter = (quote) => [
      quote.number,
      quote.numeroQuote,
      quote.entreprise,
      moment(quote.starting_date).format("DD/MM/yyyy"),
      quote.zip,
      quote.phone,
      quote.email,
      quote.broker,
      moment(quote.date).format("DD/MM/yyyy"),
      quote.product,
      quote.warranty,
      Math.round(quote.CA * 12),
      quote.nbPers,
    ];
    exportToCsv({
      formatter,
      headerFormatter,
      data: filteredRows,
      filename: "Contrats collectifs.csv",
    });
  }, [filteredRows]);

  useEffect(() => {
    if (Object.keys(broker).length) {
      getQuotes().then((res) => {
        //console.log(res.data);
        const data = res.data
          ?.filter((q) => q.type === "subscription")
          ?.filter((q) => q.company !== null)
          ?.filter((q) => q.broker_id === broker.id);
        const tmp = data.map((q) =>
          createData(
            q.id,
            q.number_avenant ? q.numero_devis + '.' + q.number_avenant : q.numero_devis,
            `${q.company} ${q.numero_adherent ? `(${q.numero_adherent})` : ''}`,
            q.starting_date,
            parseInt(q.zip_code),
            q.phone,
            q.email,
            `${broker?.company}`,
            q.created_at,
            findProductName(q),
            findBaseGarantieName(q),
            q.taux,
            parseInt(calculateTotal(q.meta, 3428, q.effectif)),
            q.effectif,
            q.contract_status,
            q.date_status_contract,
            q.status_updated_by,
            q.index,

            q
          )
        );
        setRows(tmp);
      });
    }
  }, [changeStatus, broker]);

  useEffect(() => {
    let tmp = filterByDates(rows, starting_date, ending_date);
    setFilteredRows(
      tmp?.filter(
        (row) =>
          (row.entreprise
            .toLowerCase()
            .match(searchValue.toLowerCase().trim()) ||
            row.numeroQuote
              .toString()
              .toLowerCase()
              .match(searchValue.toLowerCase().trim()) ||
            row.zip
              .toString()
              .toLowerCase()
              .match(searchValue.toLowerCase().trim()) ||
            row.phone
              .toString()
              .toLowerCase()
              .match(searchValue.toLowerCase().trim()) ||
            row.email?.toLowerCase().match(searchValue.toLowerCase().trim()) ||
            row.broker.toLowerCase().match(searchValue.toLowerCase().trim())) &&
          (filterStatus && filterStatus !== "all"
            ? row.status === filterStatus
            : true)
      )
    );
    setPage(0);
  }, [rows, searchValue, filterStatus, starting_date, ending_date]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const headCells = [
    {
      id: "See",
      label: "Voir",
      isOrderable: false,
    },
    {
      id: "status",
      label: "État",
      isOrderable: true,
    },
    {
      id: "number",
      label: "N°",
      isOrderable: true,
    },
    {
      id: "entreprise",
      label: "Entreprise",
      isOrderable: true,
    },
    {
      id: "starting_date",
      label: "Date effet",
      isOrderable: true,
    },
 
    {
      id: "zip",
      label: "Code Postal",
      isOrderable: true,
    },
    {
      id: "phone",
      label: "Téléphone",
      isOrderable: true,
    },
    {
      id: "email",
      label: "Email",
      isOrderable: true,
    },
    {
      id: "broker",
      label: "Courtier",
      isOrderable: true,
    },

    {
      id: "product",
      label: "Produit",
      isOrderable: true,
    },
    {
      id: "warranty",
      label: "Garantie",
      isOrderable: true,
    },
    {
      id: "taux",
      label: "Commission",
      isOrderable: true,
    },
    {
      id: "nbPers",
      label: "Salarié(s)",
      isOrderable: true,
    },
  ];

  return (
    <>
      <Grid container spacing={1} style={{ margin: "1.5rem 0 1rem" }}>
        <Grid
          item
          xs={12}
          md={3}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <TextField
            variant="outlined"
            size="medium"
            fullWidth
            placeholder="Rechercher par entreprise, code postal..."
            style={{marginTop:10}}
            value={searchValue}
            onChange={(e) => setsearchValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid
          item
          xs={4}
          md={1}
          style={{
            display: "flex",
            justifyContent: "start",
            textAlign: "left",
          }}
        >
          <TextField
            select
            variant="outlined"
            size="medium"
            fullWidth
            style={{marginTop:10}}
            label="État"
            value={filterStatus}
            onChange={(e) => setfilterStatus(e.target.value)}
          >
            <MenuItem key={"all"} value={"all"}>
              <Typography component="p" variant="body2">
                {"Tous"}
              </Typography>
            </MenuItem>
            {contrat_status?.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                <Typography component="p" variant="body2">
                  {s.name}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid
          item
          xs={6}
          md={2}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <CommonDateTimePicker
            field={{ value: starting_date, onChange: setstarting_date }}
            label={"Date de début"}
            name={"starting_date"}
          />
        </Grid>

        <Grid
          item
          xs={6}
          md={2}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <CommonDateTimePicker
            field={{ value: ending_date, onChange: setending_date }}
            label={"Date de fin"}
            name={"starting_date"}
          />
        </Grid>

        <Grid
          item
          xs={6}
          md={2}
          style={{ display: "flex", justifyContent: "start" }}
        >
          <Button
            className="buttonGeneralStyle"
            fullWidth
            style={{ height: "53.5", maxHeight: "none", marginTop: 10 }}
            onClick={exportCsv}
          >
            Exporter en CSV
          </Button>
        </Grid>
      </Grid>
      {/* Tableau */}

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(filteredRows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => (
                <TableRow
                  key={row.number}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    <AiOutlineEye
                      fill="rgba(0,0,0,0.72"
                      size={25}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(`/Contrats/Collectifs/${row.number}`)
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    {changeStatus === row.number ? (
                      <CommonSelect
                        field={{
                          value: row.status,
                          onChange: (status) =>
                            onChangeStatusHandler(status, row.signed),
                        }}
                        options={editable_contrat_status.map((state) => {
                          return { id: state.id, value: state.name };
                        })}
                      />
                    ) : (
                      <Typography variant="body2">
                        <Box
                          style={{
                            background: contrat_status?.find(
                              (s) => s.id === row.status
                            )?.color,
                            color: "white",
                            borderRadius: "3px",
                            padding: "0.3rem",
                            fontWeight: "600",
                          }}
                          onClick={() =>
                            row.status !== "waiting" &&
                            row.status !== "managment" &&
                            row.status !== "finished" &&
                            row.status !== "signed" &&
                            setChangeStatus(row.number)
                          }
                        >
                          {
                            contrat_status?.find((s) => s.id === row.status)
                              ?.name
                          }
                          <Typography
                            style={{ color: "#DDD", fontSize: "13px" }}
                          >
                            {row.date_status &&
                              "le " +
                                moment(row.date_status).format("DD/MM/yyyy")}
                          </Typography>
                          <Typography
                            style={{ color: "#DDD", fontSize: "13px" }}
                          >
                            {row.status_updated_by &&
                              "par " + row.status_updated_by.toUpperCase()}
                          </Typography>
                        </Box>
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                    <Typography variant="body2">{row.numeroQuote}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.entreprise}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" style={{fontWeight:800}}>
                      {row.starting_date !== null ? moment(row.starting_date).format("DD/MM/yyyy") : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.zip}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.phone}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.email}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.broker}</Typography>
                  </TableCell>
             
                  <TableCell align="center">
                    <Typography variant="body2">{row.product}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{row.warranty}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{row.taux}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{row.nbPers}</Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        size="medium"
        variant="outlined"
        count={filteredRows?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={() =>
          defaultLabelDisplayedRows({
            from: rowsPerPage * page + 1,
            to:
              rowsPerPage * page + rowsPerPage > filteredRows.length
                ? filteredRows.length
                : rowsPerPage * page + rowsPerPage,
            count: filteredRows?.length,
          })
        }
        labelRowsPerPage={"Lignes par page :"}
      />
    </>
  );
};

export default Collectif;
