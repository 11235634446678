import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../utility";

const initializeState = {
  dashboard: {},
};

const reducer = (state = initializeState, action) => {
  switch (action.type) {
    case actionTypes.POPULATE_DASHBOARD:
      return updateObject(state, { dashboard: action.dashboard });
    default:
      return state;
  }
};

export default reducer;
