const iban = require("iban");
const bic = require("bic");

export const pingIBAN = (x) => {
  //console.log(iban)
  return iban.isValid(x) || "IBAN erroné.";
};

export const pingBIC = (x) => {
  //console.log(bic)
  return bic.isValid(x) || "BIC erroné.";
};
