import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Typography, Button, Avatar, Box } from '@material-ui/core';
import { FaUserCircle } from 'react-icons/fa';
import TextFieldCustom from '../../Components/FormComponents/TextFieldCustom';
import CustomHeader from '../../Components/Header/CustomHeader';
import { useDispatch, useSelector } from 'react-redux';
import { formatErrorEmail, formatErrorPhoneNumber, errorPasswordNotSame } from '../../utils/formValidation';
import CustomModal from '../../Components/Commons/Modal/CustomModal';
import { changePassword, getmypicture, changeProfile } from '../../Axios/Call/AuthApi';
import AlertGeneral from '../../Components/Commons/AlertGeneral/AlertGeneral';
import { populateUserData } from '../../Store/action';
import CommonButton from '../../Components/FormComponents/CommonButton';

const Profil = () => {
  const user = useSelector((state) => state.user?.userData);
  const [loadingApiPassword, setLoadingApiPassword] = useState(false);
  const [openPassword, setopenPassword] = useState(false);
  const [actualPicture, setactualPicture] = useState('');
  const [errorApi, seterrorApi] = useState(false);
  const [successApi, setsuccesApi] = useState(false);
  const inputFileRef = useRef(null);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  const [newPassword, setnewPassword] = useState("");
  const [newPasswordBis, setnewPasswordBis] = useState("");

  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    identical: false,
  });

  useEffect(() => {
    setnewPassword(state.password);
    setnewPasswordBis(state.confirmPassword);
  }, [state]);

  // FORM
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: 'all',
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    errors: errors2,
    watch: watch2,
  } = useForm({
    mode: 'onBlur',
  }); // initialise the hook

  useEffect(() => {
    if (Object.keys(user).length) {
      getmypicture().then((res) => setactualPicture(res.data));
      setValue('name', user?.name);
      setValue('surname', user?.surname);
      setValue('email', user?.email);
      setValue('mobile', user?.mobile);
      setValue('address', user?.address);
      setValue('code_postal', user?.code_postal);
      setValue('city', user?.city);
    }
  }, [user]);

  useEffect(() => {
    if (file) {
      changeProfile({
        name: user?.name,
        surname: user?.surname,
        email: user?.email,
        mobile: user?.mobile,
        address: user?.address,
        code_postal: user?.code_postal,
        city: user?.city,
        file: { name: 'Picture', base64: file },
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            setsuccesApi(true);
          }
        })
        .catch(error => {
          seterrorApi(true);
        });
    }
  }, [file]);

  function fileToBase64(file) {
    return new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        res(fileReader.result);
      };
      fileReader.onerror = () => rej();
    });
  }

  const send = (data) => {
    changeProfile({
      name: data.name,
      surname: data.surname,
      email: data.email,
      mobile: data.mobile,
      address: data.address,
      code_postal: data.code_postal,
      city: data.city,
      status: user.status,
      type: user.type,
    })
      .then((res) => {
        //console.log(res);
        if (res.status === 200 || res.status === 201) {
          setsuccesApi(true);
          dispatch(populateUserData());
        } else {
          seterrorApi(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const changePasswordHandler = (data) => {
    setLoadingApiPassword(true);
    changePassword(data)
      .then((response) => {
        setLoadingApiPassword(false);
        setsuccesApi(true);
        setopenPassword(null);
      })
      .catch((error) => {
        console.log(error);
        seterrorApi(true);
        setLoadingApiPassword(false);
      });
  };

  const handleClosePassWord = () => {
    setopenPassword(null);
  };

  return (
    <>
      {successApi && <AlertGeneral color="" description="Enregistré !" setTrigger={setsuccesApi} />}
      {errorApi && (
        <AlertGeneral
          color="danger"
          description="Une errreur est survenue lors de la connexion à l'API"
          setTrigger={seterrorApi}
        />
      )}
      <CustomHeader
        icon={<FaUserCircle size={40} style={{ fill: 'rgb(195, 29, 35)', marginRight: '.5rem' }} />}
        pageName="Mon Profil"
      />

      <Grid container spacing={1} style={{ paddingLeft: '4rem' }}>
        <Grid item xs={12} lg={8}>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <Avatar src={file || actualPicture} style={{width: '125px', height: '125px', boxShadow: '0 1px 4px 2px lightgrey',}} sizes/>
            </Grid>
            <Grid item xs={9} style={{ textAlign: 'left' }}>
              <Button
                type="button"
                size="large"
                className={'buttonGeneralStyle'}
                style={{maxWidth: '275px',}}
                onClick={() => inputFileRef?.current?.click()}
              >
                Modifier la photo de profil
              </Button>
              <Typography component="p" variant="body1">
                Maximum 1Mo. Formats acceptés: JPG, PNG.
              </Typography>
            </Grid>
            <input
              type="file"
              accept="image/png"
              style={{ display: 'none' }}
              ref={inputFileRef}
              onChange={async (e) => {
                const base64 = await fileToBase64(e.target.files[0]);
                setFile(base64);
              }}
            />
          </Grid>
        </Grid>
        {/*  */}
        {Object.keys(user).length && (
          <form onSubmit={handleSubmit(send)}>
            <Grid item xs={12} lg={10} style={{ marginTop: '3rem' }}>
              <Typography variant="h3" style={{ marginBottom: '1rem', textAlign:'left' }}>
                Informations personnelles
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldCustom
                        label="Prénom"
                        type="text"
                        ref={register({ required: 'Champ requis' })}
                        name="name"
                        defaultValue={user?.name}
                        error={errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldCustom
                        label="Nom"
                        type="text"
                        ref={register({ required: 'Champ requis' })}
                        name="surname"
                        error={errors.surname}
                        defaultValue={user?.surname}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextFieldCustom
                        label="Téléphone portable"
                        type="tel"
                        name="mobile"
                        ref={register({
                          validate: {formatErrorPhoneNumber}
                        })}
                        error={errors.mobile}
                        defaultValue={user?.mobile}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextFieldCustom
                        label="Email / Identifiant"
                        type="email"
                        name="email"
                        ref={register({
                          required: 'Champ requis',
                          validate: {formatErrorEmail},
                        })}
                        defaultValue={user?.email}
                        error={errors.email}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={7} style={{ marginTop: '1rem' }}>
                  <Typography variant="h3" style={{ marginBottom: '1rem', textAlign:'left' }}>
                    Adresse
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldCustom
                        label="Adresse"
                        type="text"
                        ref={register({})}
                        name="address"
                        defaultValue={user?.address}
                        error={errors.address}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextFieldCustom
                        label="Code Postal"
                        type="text"
                        ref={register({})}
                        name="code_postal"
                        defaultValue={user?.code_postal}
                        error={errors.code_postal}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextFieldCustom
                        label="Ville"
                        type="text"
                        ref={register({ required: '' })}
                        name="city"
                        defaultValue={user?.city}
                        error={errors.city}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/*  */}
            <Grid item xs={12} lg={7} style={{ marginTop: '3rem' }}>
              
              <Grid item xs={12} lg={10} style={{ marginTop: '2rem' }}>
                <Box style={{width: '100%', marginTop: '10%',}}>
                  <Button className={'buttonGeneralStyle'} style={{ maxWidth: '250px' }} type="submit">
                    ENREGISTRER
                  </Button>
                </Box>
              </Grid>
            </Grid>
            {/*  */}
          </form>
        )}
      </Grid>

      <CustomModal open={openPassword} handleClose={handleClosePassWord} size={400}>
        <form key={2} onSubmit={handleSubmit2(changePasswordHandler)} style={{ marginTop: 20 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid align="left" item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
              <Typography variant="h3">Changer mon mot de passe</Typography>
            </Grid>
            <Grid xs={12} md={12}>
              <TextFieldCustom
                label={'Entrer votre nouveau mot de passe'}
                type="password"
                ref={register2({
                  required: 'Champ requis',
                  validate: {
                    errorPasswordNotSame: (value) => errorPasswordNotSame(value, watch2('confirm_password')),
                  },
                })}
                defaultValue=""
                name="password"
                error={errors2.password}
              />
            </Grid>
            <Grid xs={12} md={12} style={{ marginTop: 20 }}>
              <TextFieldCustom
                label={'Confirmer votre nouveau mot de passe'}
                type="password"
                ref={register2({
                  required: 'Champ requis',
                  validate: {
                    errorPasswordNotSame: (value) => errorPasswordNotSame(value, watch2('password')),
                  },
                })}
                defaultValue=""
                name="confirm_password"
                error={errors2.confirm_password}
              />
            </Grid>
            <Grid xs={12} style={{ marginTop: 20, paddingBottom: 40 }}>
              <CommonButton text={'CHANGER MON MOT DE PASSE'} loading={loadingApiPassword} style={{ width: 300 }} />
            </Grid>
          </Grid>
        </form>
      </CustomModal>
    </>
  );
};

export default Profil;
