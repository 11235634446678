import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  InputAdornment,
  TextField,
  Box,
} from "@material-ui/core";

import { BsSearch } from "react-icons/bs";
import {
  getAllBrokerDocs,
  showDocument,
  getBrokerDoc,
} from "../../../Axios/Call/ClientCredential";
import { useSelector } from "react-redux";
import CustomModal from "../../../Components/Commons/Modal/CustomModal";
import PDFViewer from "../../Components/Commons/FileHandler/PDFViewer";

const MesDocuments = () => {
  const [searchValue, setsearchValue] = useState("");
  const [rows, setrows] = useState([]);
  const [open, setOpen] = useState(false);
  const [file, setfile] = useState(null);
  const broker = useSelector(
    (state) => state.user.userData.broker ?? state.user.userData
  );

  const handleSearchValue = (val) => {
    setsearchValue(val);
  };

  const watchFile = (path) => {
    setOpen(true);
    showDocument(path)
      .then((res) => {
        setfile(res.data);
      })
      .catch((error) => console.warn(error));
  };

  const getFile = (id) => {
    getBrokerDoc(id)
      .then((res) => {
        setfile(res.data);
      })
      .catch((error) => console.warn(error));
  };

  const createData = (name, description, file, id) => {
    return { name, description, file, id };
  };

  useEffect(() => {
    getAllBrokerDocs().then((res) => {
      console.log(res);
      setrows(
        res.data
          .filter((d) => d.broker_id === broker.id)
          ?.map((d) => createData(d.name, d.description, d.file_path, d.id))
      );
    });
  }, [broker, file]);

  return (
    <>
      <Box style={{ display: "flex" }}>
        <TextField
          variant="outlined"
          size="small"
          style={{ width: "300px", margin: "1rem 0" }}
          placeholder="Rechercher par nom, date..."
          value={searchValue}
          onChange={(e) => handleSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsSearch size={20} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="h6">Nom du document</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">Description</Typography>
              </TableCell>
              <TableCell align="left" />
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.filter(
                (row) =>
                  row.name?.toLowerCase().match(searchValue.toLowerCase().trim()) ||
                  row.description?.toLowerCase().match(searchValue.toLowerCase().trim())
              )
              ?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="left">
                    <Typography variant="body3">{row.name}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body4">{row.description}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      className={"buttonGeneralStyleSmall"}
                      onClick={() => watchFile(row.id)}
                      style={{ paddingLeft: 10 }}
                    >
                      VOIR
                    </Button>

                    {/*<Button onClick={() => getFile(row.id)}>*/}
                    {/*    Télécharger*/}
                    {/*</Button>*/}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomModal open={open} handleClose={() => setOpen(false)}>
        <PDFViewer file={file} />
      </CustomModal>
    </>
  );
};

export default MesDocuments;
