import React, { useState, useEffect } from "react";
import CustomHeader from "../../Components/Header/CustomHeader";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular";
import HeaderSections from "../../Components/Commons/HeaderSections/HeaderSections";
import { AiOutlineFileText, AiOutlineEye } from "react-icons/ai";
import { useDispatch } from "react-redux";
import * as actionCreator from "../../Store/action/index";

import { useHistory, useLocation } from "react-router-dom";
import {
  addCommercial,
  getAllBrokers,
  getAllBusinesses,
  getAllCommercials,
  getAllSubscriptions,
  getCommercial,
  patchCommercial,
} from "../../Axios/Call/ClientCredential";

const Devis = ({ children }) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [section, setSection] = useState("individuels");

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const sections = [
    {
      action: "individuels",
      name: "Devis individuels",
      redirect: `/Devis/Individuels`,
    },
    {
      action: "collectifs",
      name: "Devis collectifs",
      redirect: `/Devis/Collectifs`,
    },
  ];

  useEffect(() => {
    
    if (location.pathname) {
      if (location.pathname.includes("Individuels")) {
        setSection("individuels");
      }
      if (location.pathname.includes("Collectifs")) {
        setSection("collectifs");
      }
    }
  }, [location.pathname]);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={
            "Une erreur est survenue lors du chargement des données"
          }
        />
      )}
      <CustomHeader
        icon={
          <AiOutlineFileText
            size={40}
            style={{ fill: "rgb(195, 29, 35)", marginRight: ".5rem" }}
          />
        }
        pageName={`Devis ${section}`}
        childComponent={
          <HeaderSections
            setSection={setSection}
            section={section}
            sections={sections}
          />
        }
      />
      {loadingApi ? <LoaderCircular /> : <>{React.cloneElement(children)}</>}
    </>
  );
};

export default Devis;
