
import { Grid, Typography, Button, Box } from "@material-ui/core";
import MBA from "../../Assets/mbalogo.png";
import _ from "lodash";



import React from 'react';

const AutoSubThanks = () => {
    return (
        <div className={"containerStyle"}>
        
          <Grid
            container
            direction="row"
            // justifyContent="center"
            // alignItems="center"
          >
            <Grid
              item
              xs={12}
              md={4}
              style={{ background: "#C31D23", height: "100vh" }}
            >
              <Box>
                <img
                  src={MBA}
                  alt="MBA Mutuelle"
                  style={{
                    width: "60%",
                    minWidth: "250px",
                    maxWidth: "675px",
                    marginTop: "18rem",
                  }}
                />
              </Box>
            </Grid>
            {/* fields */}
       
           

              <Grid item xs={12} md={8} style={{float:"center"}}>
                <Box style={{ padding: "24px" }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} >
                      <Typography
                        variant="h1"
                        style={{ textAlign: "center", fontWeight: "bold", marginTop:"300px" }}
                      >
                              Merci et à bientôt ! 
                      </Typography>
                    
                    </Grid>
                    <Box className="spacer2" />
                    {/* item */}
    
               
                  </Grid>
                  <Box className="spacer2" />
                 
                  <Box className="spacer2" />
                  <Button
                    className="buttonGeneralStyle"
                    onClick={() => window.open("https://www.mbamutuelle.com", "_blank")}
                  >
                    En savoir plus sur MBA Mutuelle
                  </Button>
                </Box>
              </Grid>
            
          </Grid>
        </div>
    );
};

export default AutoSubThanks;