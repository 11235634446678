import React from 'react'
import { Typography, Grid, Tooltip } from '@material-ui/core'

const ContactBox = ({ text, tooltip, href, icon }) => {

    return (
        <>
        <Tooltip title={tooltip}>
            <a href={href} style={{ textDecoration: 'none' }}>
                <Grid container alignItems={'center'} justifyitems={'flex-start'}>

                <Grid item md={2} xs={12} style={{textAlign:'center', backgroundColor: "#FFF", border:"solid 1px rgb(195, 29, 35)", padding: '5px 5px', marginRight:10, maxWidth:"220px", borderRadius: 5, cursor: 'pointer', boxShadow: "rgba(100, 100, 111, 0.1) 0px 2px 2px 0px"}}>
                        {icon}
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <Typography variant="h6" style={{ color: '#c31d23' }}> {text} </Typography>
                    </Grid>
                   
                </Grid>
            </a>
        </Tooltip>
        </>
    )
}

export default ContactBox