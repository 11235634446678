import React, { useState } from "react";
import MBA from "../../../Assets/mbalogo.png";
import { useHistory } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import { useWindowWidth } from '@react-hook/window-size';


const OAVLayout = ({ children, extra }) => {
  const history = useHistory();
  const width = useWindowWidth();
  const redBoxSize = width > 1200 ? "350px": "180px";


  return (



    <Box position="relative">
      {/* <Grid container direction="row" justifyContent="flex-start" > */}

      <Box
        style={{
          background: "#C31D23",
          height: "100vh",
          width: redBoxSize,
          position: "fixed",
          top: 0
        }}
      >

        <Box>
          <img
            src={MBA}
            alt="MBA Mutuelle"
            style={{
              width: width > 1200 ? "160px": "100px",
              minWidth: "125px",
              maxWidth: "160px",
              cursor:"pointer",
            }}
            onClick={() => history.push("/Dashboard")}
          />
        </Box>
        <Button
          className="buttonTarificateurStyle"
          onClick={() => history.push("/Dashboard")}
          style={{
              width: width > 1200 ? "160px": "100px",
              minWidth: width > 1200 ? "220px": "120px",
              fontSize:width > 1200 ? "16px": "12px",
              bottom:0,
          }}
        >
          Tableau de bord
        </Button>
        <Box>{extra}</Box>
      </Box>


      <Box style={{ marginLeft: redBoxSize, padding: "24px", height: "100vh", flexDirection: "column"}}>{children}</Box>

    </Box>
  
  
  );
};

export default OAVLayout;
