import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const HeaderSections = ({
  section,
  setSection,
  sections,
}) => {
  const history = useHistory();
  return (
    <>
      {sections.map((sectionItem, index) => {
        return (
          <React.Fragment key={index}>
            <Grid style={{ paddingLeft: index ? 20 : 0 }}>
              <Typography
                component="h3"
                variant="h3"
                style={{
                  cursor: "pointer",
                  borderBottom:
                    section === sectionItem.action
                      ? "2px solid rgb(195, 29, 35)"
                      : "none",
                  color: section === sectionItem.action ? "rgb(195, 29, 35)" : "#AFAEAE",
                }}
                onClick={() => {
                  if (sectionItem.redirect) {
                    history.push(sectionItem.redirect);
                  }
                  setSection(sectionItem.action);
                }}
              >
                {sectionItem.name}
              </Typography>
            </Grid>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default HeaderSections;
