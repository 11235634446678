import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
} from "@material-ui/core";
import OAVLayout from "../OAVComponents/OAVLayout";
import {
  errorDateJPlus1,
  errorEffectif50,
} from "../../../utils/formValidation";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateOav } from "../../../Store/action/oav";
import OAVSynthese from "../OAVComponents/OAVSynthese";
import CommonDateTimePicker from "../../FormComponents/CommonDateTimePicker";
import CommonSelectId from "../../../Components/FormComponents/CommonSelect";
import CommonTextField from "../../FormComponents/CommonTextField";
import moment from "moment";
import "moment/locale/fr";
import ZipcodeAutocomplete from "../../Commons/ZipcodeAutocomplete/ZipcodeAutocomplete";
import { getQuote } from "../../../Axios/Call/ClientCredential";
import { SquaresPlusIcon } from "@heroicons/react/24/solid";
moment.locale("fr");

const OAVProfil = () => {
  const oav = useSelector((state) => state.oav.data);

  const history = useHistory();
  const dispatch = useDispatch();

  const regimes = useSelector((state) =>
    state.user.regimes.filter((regime) => regime.market === 1)
  );
  const colleges = useSelector((state) => state.user.colleges);

  const steps = ["Besoin", "Profil", "Choix de la garantie"];
  const [showChangeGuarantee, setShowChangeGuarantee] = useState(false);
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const [minDate, setMinDate] = useState(moment().add(1, "days"));
  const { handleSubmit, errors, control, getValues, setValue, reset } = useForm(
    {
      mode: "onBlur",
      reValidateMode: "all",
      defaultValues: {
        starting_date: oav?.starting_date
          ? oav?.starting_date
          : moment().add(1, "days").format("YYYY-MM-DD"),
        idcc: oav?.idcc,
        change_guarantee: 1,
        regime: oav?.regime ? oav?.regime : 4,
        college: oav?.college ? oav?.college : 1,
        effectif: oav?.effectif,
        age_moyen: oav?.age_moyen,
        zip_code: oav?.zip_code, //TODO get correct zipcode object with the zipcode
        //DEBUG Default values for the step for testing
        // regime: "Général",
        // college: "Cadre",
        // effectif:10,
        // age_moyen:42,
        // zip_code: "35400",
      },
    }
  );

  useEffect(() => {
    if (oav) {
      reset({
        idcc: oav?.idcc,
        regime: oav?.regime ? oav?.regime : 4,
        college: oav?.college ? oav?.college : 1,
        effectif: oav?.effectif,
        age_moyen: oav?.age_moyen,
        change_guarantee: 1,
        zip_code: oav?.zip_code, //TODO get correct zipcode object with the zipcode
      });
    }
  }, [oav, reset]);

  useEffect(() => {
    if (oav.hasOwnProperty("reprise")) {
      getQuote(oav.reprise.quote).then((res) => {
        const startingDate = moment(res.data.starting_date).format(
          "YYYY-MM-DD"
        );
        const oneYearAgo = moment().subtract(12, "months").format("YYYY-MM-DD");
        if (moment(startingDate).isBefore(oneYearAgo)) {
          setShowChangeGuarantee(true);
          setValue("zip_code", res?.data?.zip_code);
          setMinDate(moment().add(2, "months").startOf("month"));
          setValue(
            "starting_date",
            moment().add(2, "months").startOf("month").format("YYYY-MM-DD")
          );
        }
      });
    }
  }, [oav]);

  const handleStep = (data) => {
    //console.log(data);
    for (const [key, value] of Object.entries(data)) {
      //console.log(key, value);
      dispatch(updateOav(key, value));
    }
    // history.push("/Tarificateur/Collectif/Garanties");
    history.push("/Tarificateur/Garanties");
  };

  return (
    <OAVLayout extra={<OAVSynthese />}>
      <Stepper
        alternativeLabel
        activeStep={1}
        style={{ background: "#fafafa" }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <p
                  style={
                    1 === index
                      ? {
                          fontFamily: "Montserrat !important",
                          fontWeight: "600",
                          color: "#c31d23",
                        }
                      : {
                          fontFamily: "Montserrat !important",
                          fontWeight: "600",
                          color: "#c31d2388",
                        }
                  }
                >
                  {label}
                </p>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {showChangeGuarantee !== "" ? (
        <Box maxWidth="900px" margin="0 auto">
          <form
            onSubmit={handleSubmit(handleStep)}
            style={{
              marginTop: 20,
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            noValidate
          >
            <Grid container spacing={2} style={{ justifyContent: "left" }}>
              {oav.hasOwnProperty("reprise") && (
                <Grid item xs={12} md={6}>
                  <Typography component="p" variant="h3">
                    Voulez-vous changer de garantie ?
                  </Typography>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="change_guarantee"
                    defaultValue={getValues("change_guarantee")}
                    render={(field) => (
                      <CommonSelectId
                        label="Changement de garantie"
                        field={field}
                        options={[
                          { id: 1, value: "Oui" },
                          { id: 0, value: "Non" },
                        ]}
                        error={errors.change_guarantee}
                        required={true}
                      />
                    )}
                  />
                </Grid>
              )}
              {/*  */}
              <Grid item xs={12} md={4} style={{ marginBottom: "1.5rem" }}>
                <Typography component="p" variant="h3">
                  Date d'effet
                </Typography>
                <Controller
                  name={"starting_date"}
                  control={control}
                  disabled={true}
                  rules={{ required: true, validate: errorDateJPlus1 }}
                  render={(field) => (
                    <CommonDateTimePicker
                      readOnly={true}
                      field={field}
                      disabled={oav.hasOwnProperty("reprise")}
                      name={"starting_date"}
                      label={"Date d'effet"}
                      error={errors.starting_date}
                      minDate={minDate}
                      disablePast={new Date().getDate() > 14}
                      renderInput={(params) => (
                        <TextField onKeyDown={onKeyDown} {...params} />
                      )}
                    />
                  )}
                />
              </Grid>
              {/*  */}
              <Grid item xs={12} lg={10} style={{ marginBottom: "1.5rem" }}>
                <Typography component="p" variant="h3">
                  Votre profil
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="regime"
                      rules={{ required: true }}
                      defaultValue={getValues("regime")}
                      render={(field) => (
                        <CommonSelectId
                          label="Régime"
                          disabled={oav.hasOwnProperty("reprise")}
                          field={field}
                          error={errors.regime}
                          options={regimes}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="college"
                      rules={{ required: true }}
                      defaultValue={getValues("college")}
                      render={(field) => (
                        <CommonSelectId
                          label="Collège"
                          disabled={oav.hasOwnProperty("reprise")}
                          field={field}
                          error={errors.college}
                          options={colleges}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        valueAsNumber: true,
                        validate: errorEffectif50,
                      }}
                      name="effectif"
                      render={(field) => {
                        return (
                          <CommonTextField
                            type={"number"}
                            field={field}
                            readOnly={oav.hasOwnProperty("reprise")}
                            label="Effectif assurable du collège (maximum : 50)"
                            error={errors.effectif}
                            InputProps={{ inputProps: { min: 0, max: 50 } }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      rules={{ required: true, valueAsNumber: true }}
                      name="age_moyen"
                      render={(field) => {
                        return (
                          <CommonTextField
                            type={"number"}
                            field={field}
                            readOnly={oav.hasOwnProperty("reprise")}
                            label="Âge moyen"
                            error={errors.age_moyen}
                          />
                        );
                      }}
                    />
                  </Grid>
                  {!oav.hasOwnProperty("reprise") && <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="zip_code"
                      rules={{ required: true }}
                      render={(field) => (
                        <ZipcodeAutocomplete
                          label="Code postal"
                          name="zip_code"
                          disabled={oav.hasOwnProperty("reprise")}
                          error={errors.zip_code}
                          TextFieldProps={{
                            error: Boolean(errors.zip_code),
                            helperText: errors.zip_code?.message,
                          }}
                          value={field.value}
                          onBlur={field.onBlur}
                          onFocus={field.onFocus}
                          onChange={(e, value) => {
                            field.onChange(value);
                          }}
                        />
                      )}
                    />
                  </Grid>}
                </Grid>
              </Grid>
            </Grid>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "3rem auto 0",
                alignSelf: "center",
                width: "100%",
              }}
            >
              <Button
                className={"buttonLightRedStyle"}
                onClick={() => history.push("/Tarificateur/Besoin")}
              >
                Précédent
              </Button>
              <Button className={"buttonGeneralStyle"} type="submit">
                Suivant
              </Button>
            </Box>
          </form>
        </Box>
      ) : (
        <Box maxWidth="900px" margin="0 auto">
          <SquaresPlusIcon
            style={{ width: "100px", height: "100px", color: "#C31D2340" }}
          />
          <Typography component="p" variant="h3">
            {
              "Votre contrat a moins d'un an, vous ne pouvez pas le modifier pour le moment"
            }
          </Typography>
        </Box>
      )}
    </OAVLayout>
  );
};

export default OAVProfil;
